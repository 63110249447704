import Linear, { initLinear } from 'vts/models/core/Linear';

export const PLATFORM_ADM = 20; // 管理后台
export const PLATFORM_WFG = 30; // WEB前台
export const PLATFORM_MNA = 40; // 小程序
export const PLATFORM_APP = 50; // APP

export const GENRE_ISSUE = 10; // 问题
export const GENRE_SUGGEST = 20; // 献策
export const GENRE_REPORT = 30; // 举报
export const GENRE_COMPLAIN = 40; // 投诉
export const GENRE_OTHER = 50; // 其他

/// 意见反馈
export default interface Feedbacks extends Linear {
  /// 使用平台
  platform: number;

  /// 类型
  genre: number;

  /// 您的联系方式
  contact: string;

  /// 反馈内容
  content: string;
}

export const newFeedbacks = {
  ...initLinear,
  platform: 50,
  genre: 10,
  contact: '',
  content: '',
};
