import Tree, { initTree } from 'vts/models/core/Tree';

export const GENRE_DEPT = 10; // 部门
export const GENRE_CORP = 20; // 单位
export const GENRE_VIRTUAL = 30; // 虚拟

/// 部门
export default interface Dept extends Tree {
  /// 配置
  confId: number;

  /// 类型
  genre: number;

  /// 过滤
  filters: string;
}

export const newDept = {
  ...initTree,
  confId: 0,
  genre: 10,
  filters: '',
};
