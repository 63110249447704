import MenuRes from '../../sys/models/MenuRes';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 菜单资源 状态
export interface State extends Linear {
  /// 菜单
  menuId: FormField<number | null>;

  /// 资源
  resId: FormField<number | null>;

  /// 分页
  page: Page<MenuRes>;
}

/// 菜单资源 初始状态
const initState: State = {
  ...initLinear,
  menuId: initField(0),
  resId: initField(0),
  page: initPage<MenuRes>(),
};

export default initState;
