import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required()],
  username: [validators.required(), validators.username()],
  passwd: [validators.required(), validators.passwd()],
  nickname: [validators.required()],
  platform: [validators.gt(0)],
  genre: [validators.gt(0)],
  email: [validators.email()],
  phone: [validators.phone()],
});

export default validator;
