import { State } from '../../sys/states/res';
import Action from '../../sys/actions/res';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        parentId: {
          val: action.payload.parentId,
          err: false,
          hlp: '',
        },
        ordinal: {
          val: action.payload.ordinal,
          err: false,
          hlp: '',
        },
        href: {
          val: action.payload.href,
          err: false,
          hlp: '',
        },
        target: {
          val: action.payload.target,
          err: false,
          hlp: '',
        },
        icon: {
          val: action.payload.icon,
          err: false,
          hlp: '',
        },
        hidden: {
          val: action.payload.hidden,
          err: false,
          hlp: '',
        },
        permission: {
          val: action.payload.permission,
          err: false,
          hlp: '',
        },
        grp: {
          val: action.payload.grp,
          err: false,
          hlp: '',
        },
        genre: {
          val: action.payload.genre,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 名称 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 父标识 值
    case 'setParentId':
      return {
        ...state,
        parentId: {
          ...state.parentId,
          val: action.payload,
        },
      };
    /// 设置 排序 值
    case 'setOrdinal':
      return {
        ...state,
        ordinal: {
          ...state.ordinal,
          val: action.payload,
        },
      };
    /// 设置 链接 值
    case 'setHref':
      return {
        ...state,
        href: {
          ...state.href,
          val: action.payload,
        },
      };
    /// 设置 目标 值
    case 'setTarget':
      return {
        ...state,
        target: {
          ...state.target,
          val: action.payload,
        },
      };
    /// 设置 图标 值
    case 'setIcon':
      return {
        ...state,
        icon: {
          ...state.icon,
          val: action.payload,
        },
      };
    /// 设置 隐藏 值
    case 'setHidden':
      return {
        ...state,
        hidden: {
          ...state.hidden,
          val: action.payload,
        },
      };
    /// 设置 权限 值
    case 'setPermission':
      return {
        ...state,
        permission: {
          ...state.permission,
          val: action.payload,
        },
      };
    /// 设置 分组 值
    case 'setGrp':
      return {
        ...state,
        grp: {
          ...state.grp,
          val: action.payload,
        },
      };
    /// 设置 资源类型 值
    case 'setGenre':
      return {
        ...state,
        genre: {
          ...state.genre,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        parentId: {
          ...state.parentId,
          err: hasError(action.payload.parentId),
          hlp: getFirstErrMsg(action.payload.parentId),
        },
        ordinal: {
          ...state.ordinal,
          err: hasError(action.payload.ordinal),
          hlp: getFirstErrMsg(action.payload.ordinal),
        },
        href: {
          ...state.href,
          err: hasError(action.payload.href),
          hlp: getFirstErrMsg(action.payload.href),
        },
        target: {
          ...state.target,
          err: hasError(action.payload.target),
          hlp: getFirstErrMsg(action.payload.target),
        },
        icon: {
          ...state.icon,
          err: hasError(action.payload.icon),
          hlp: getFirstErrMsg(action.payload.icon),
        },
        hidden: {
          ...state.hidden,
          err: hasError(action.payload.hidden),
          hlp: getFirstErrMsg(action.payload.hidden),
        },
        permission: {
          ...state.permission,
          err: hasError(action.payload.permission),
          hlp: getFirstErrMsg(action.payload.permission),
        },
        grp: {
          ...state.grp,
          err: hasError(action.payload.grp),
          hlp: getFirstErrMsg(action.payload.grp),
        },
        genre: {
          ...state.genre,
          err: hasError(action.payload.genre),
          hlp: getFirstErrMsg(action.payload.genre),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        name: initField(state.name.val),
        parentId: initField(state.parentId.val),
        ordinal: initField(state.ordinal.val),
        href: initField(state.href.val),
        target: initField(state.target.val),
        icon: initField(state.icon.val),
        hidden: initField(state.hidden.val),
        permission: initField(state.permission.val),
        grp: initField(state.grp.val),
        genre: initField(state.genre.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
