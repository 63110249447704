import Recharge from '../../gsb/models/Recharge';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 充值 状态
export interface State extends Linear {
  /// 账户
  accountId: FormField<number | null>;

  /// 金额
  amount: FormField<number | null>;

  /// 充值日期
  rechargeAt: FormField<string | null>;

  /// 分页
  page: Page<Recharge>;
}

/// 充值 初始状态
const initState: State = {
  ...initLinear,
  accountId: initField(0),
  amount: initField(0),
  rechargeAt: initField(''),
  page: initPage<Recharge>(),
};

export default initState;
