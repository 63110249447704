import Linear, { initLinear } from 'vts/models/core/Linear';

/// 部门考核
export default interface Grade extends Linear {
  /// 流程
  processId: number;

  /// 评分对象
  toId: number;

  /// 扣分分数
  score: number;

  /// 扣分原因
  cause: string;

  /// 平均分除数
  divisor: number;

  /// 平均分
  average: number;
}

export const newGrade = {
  ...initLinear,
  processId: 0,
  toId: 0,
  score: 0.0,
  cause: '',
  divisor: 1,
  average: 0.0,
};
