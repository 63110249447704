import { State } from '../../sys/states/menu_res';
import Action from '../../sys/actions/menu_res';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        menuId: {
          val: action.payload.menuId,
          err: false,
          hlp: '',
        },
        resId: {
          val: action.payload.resId,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 菜单 值
    case 'setMenuId':
      return {
        ...state,
        menuId: {
          ...state.menuId,
          val: action.payload,
        },
      };
    /// 设置 资源 值
    case 'setResId':
      return {
        ...state,
        resId: {
          ...state.resId,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        menuId: {
          ...state.menuId,
          err: hasError(action.payload.menuId),
          hlp: getFirstErrMsg(action.payload.menuId),
        },
        resId: {
          ...state.resId,
          err: hasError(action.payload.resId),
          hlp: getFirstErrMsg(action.payload.resId),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        menuId: initField(state.menuId.val),
        resId: initField(state.resId.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
