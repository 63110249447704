import { State } from '../../mcb/states/activity';
import Action from '../../mcb/actions/activity';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        descr: {
          val: action.payload.descr,
          err: false,
          hlp: '',
        },
        beginAt: {
          val: action.payload.beginAt,
          err: false,
          hlp: '',
        },
        endAt: {
          val: action.payload.endAt,
          err: false,
          hlp: '',
        },
        status: {
          val: action.payload.status,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 名称 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 描述 值
    case 'setDescr':
      return {
        ...state,
        descr: {
          ...state.descr,
          val: action.payload,
        },
      };
    /// 设置 起始时间 值
    case 'setBeginAt':
      return {
        ...state,
        beginAt: {
          ...state.beginAt,
          val: action.payload,
        },
      };
    /// 设置 截止时间 值
    case 'setEndAt':
      return {
        ...state,
        endAt: {
          ...state.endAt,
          val: action.payload,
        },
      };
    /// 设置 状态 值
    case 'setStatus':
      return {
        ...state,
        status: {
          ...state.status,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        descr: {
          ...state.descr,
          err: hasError(action.payload.descr),
          hlp: getFirstErrMsg(action.payload.descr),
        },
        beginAt: {
          ...state.beginAt,
          err: hasError(action.payload.beginAt),
          hlp: getFirstErrMsg(action.payload.beginAt),
        },
        endAt: {
          ...state.endAt,
          err: hasError(action.payload.endAt),
          hlp: getFirstErrMsg(action.payload.endAt),
        },
        status: {
          ...state.status,
          err: hasError(action.payload.status),
          hlp: getFirstErrMsg(action.payload.status),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        name: initField(state.name.val),
        descr: initField(state.descr.val),
        beginAt: initField(state.beginAt.val),
        endAt: initField(state.endAt.val),
        status: initField(state.status.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
