import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required()],
  telephone: [validators.phone()],
  email: [validators.email()],
  qq: [validators.digit()],
});

export default validator;
