import Dict from '../../sys/models/Dict';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 字典 状态
export interface State extends Linear {
  /// 类型
  genre: FormField<string | null>;

  /// 描述
  descr: FormField<string | null>;

  /// 键
  mkey: FormField<number | null>;

  /// 值
  mval: FormField<string | null>;

  /// 过滤
  filters: FormField<string | null>;

  /// 排序
  ordinal: FormField<number | null>;

  /// 分页
  page: Page<Dict>;
}

/// 字典 初始状态
const initState: State = {
  ...initLinear,
  genre: initField(''),
  descr: initField(''),
  mkey: initField(0),
  mval: initField(''),
  filters: initField(''),
  ordinal: initField(0),
  page: initPage<Dict>(),
};

export default initState;
