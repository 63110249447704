import { Routes, Route } from 'react-router-dom';
import GpcCarXzdw from './gpc/screens/CarXzdw';
import GpcCarSydw from './gpc/screens/CarSydw';
import GpcCarQwzs from './gpc/screens/CarQwzs';
import GpcCarZfzs from './gpc/screens/CarZfzs';

function App() {
  return (
    <Routes>
      <Route path="/gpc/car/xzdw" element={<GpcCarXzdw />} />
      <Route path="/gpc/car/sydw" element={<GpcCarSydw />} />
      <Route path="/gpc/car/qwzs" element={<GpcCarQwzs />} />
      <Route path="/gpc/car/zfzs" element={<GpcCarZfzs />} />
    </Routes>
  );
}

export default App;
