import Genre from '../../gsb/models/Genre';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Tree, { initTree } from 'vts/states/core/tree';

/// 商品分类 状态
export interface State extends Tree {
  /// 账户
  accountId: FormField<number | null>;

  /// 分页
  page: Page<Genre>;
}

/// 商品分类 初始状态
const initState: State = {
  ...initTree,
  accountId: initField(0),
  page: initPage<Genre>(),
};

export default initState;
