import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  accountId: [validators.gt(0)],
  amount: [validators.required()],
  rechargeAt: [validators.required()],
});

export default validator;
