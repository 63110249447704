import PostMenu from '../../sys/models/PostMenu';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 岗位菜单 状态
export interface State extends Linear {
  /// 岗位
  postId: FormField<number | null>;

  /// 菜单
  menuId: FormField<number | null>;

  /// 分页
  page: Page<PostMenu>;
}

/// 岗位菜单 初始状态
const initState: State = {
  ...initLinear,
  postId: initField(0),
  menuId: initField(0),
  page: initPage<PostMenu>(),
};

export default initState;
