import Linear, { initLinear } from 'vts/models/core/Linear';

export const PLATFORM_ADM = 20; // 管理后台
export const PLATFORM_WFG = 30; // WEB前台
export const PLATFORM_MNA = 40; // 小程序
export const PLATFORM_APP = 50; // APP

export const GENRE_SA = 10; // 超级管理员
export const GENRE_ADMIN = 20; // 管理员
export const GENRE_STAFF = 30; // 职工
export const GENRE_BASIC = 40; // 一般用户

export const SEX_MALE = 10; // 男
export const SEX_FEMALE = 20; // 女

/// 用户
export default interface User extends Linear {
  /// 区域名称
  areaName: string;

  /// 第三方用户标识
  openId: string;

  /// 是否启用OpenID
  isOpenId: boolean;

  /// 姓名
  name: string;

  /// 用户名
  username: string;

  /// 密码
  passwd: string;

  /// 昵称
  nickname: string;

  /// 使用平台
  platform: number;

  /// 类型
  genre: number;

  /// 邮件
  email: string;

  /// 手机
  phone: string;

  /// 头像
  avatar: string;

  /// 性别
  sex: number;

  /// QQ
  qq: string;

  /// 二维码
  qrcode: string;

  /// 是否冻结
  froze: boolean;

  /// 冻结时间
  frozeAt: string;

  /// 是否登录过
  isLogin: boolean;

  /// 首次登录时间
  loginAt: string;

  /// 来源
  sourceId: number;

  /// 历史
  history: boolean;
}

export const newUser = {
  ...initLinear,
  areaName: '',
  openId: '',
  isOpenId: false,
  name: '',
  username: '',
  passwd: '',
  nickname: '',
  platform: 20,
  genre: 40,
  email: '',
  phone: '',
  avatar: '',
  sex: 10,
  qq: '',
  qrcode: '',
  froze: false,
  frozeAt: '',
  isLogin: false,
  loginAt: '',
  sourceId: 0,
  history: false,
};
