import Linear, { initLinear } from 'vts/models/core/Linear';

export const NATURE_XZDW = 10; // 行政单位
export const NATURE_SYDW = 20; // 事业单位
export const NATURE_QWZS = 30; // 旗委直属
export const NATURE_ZFZS = 40; // 政府直属

/// 单位
export default interface Gov extends Linear {
  /// 单位名称
  name: string;

  /// 单位性质
  nature: number;

  /// 排序
  ordinal: number;
}

export const newGov = {
  ...initLinear,
  name: '',
  nature: 0,
  ordinal: 0,
};
