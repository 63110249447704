import Linear, { initLinear } from 'vts/models/core/Linear';

/// 编制类型
export default interface Uses extends Linear {
  /// 名称
  name: string;

  /// 简称
  nickname: string;

  /// 排序
  ordinal: number;
}

export const newUses = {
  ...initLinear,
  name: '',
  nickname: '',
  ordinal: 0,
};
