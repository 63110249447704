import Item from '../../kpi/models/Item';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 考核项目 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 权重
  weight: FormField<number | null>;

  /// 分页
  page: Page<Item>;
}

/// 考核项目 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  weight: initField(0),
  page: initPage<Item>(),
};

export default initState;
