import { State } from '../../cmn/states/flw';
import Action from '../../cmn/actions/flw';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        srcId: {
          val: action.payload.srcId,
          err: false,
          hlp: '',
        },
        preStatus: {
          val: action.payload.preStatus,
          err: false,
          hlp: '',
        },
        postStatus: {
          val: action.payload.postStatus,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 来源ID 值
    case 'setSrcId':
      return {
        ...state,
        srcId: {
          ...state.srcId,
          val: action.payload,
        },
      };
    /// 设置 之前状态 值
    case 'setPreStatus':
      return {
        ...state,
        preStatus: {
          ...state.preStatus,
          val: action.payload,
        },
      };
    /// 设置 之后状态 值
    case 'setPostStatus':
      return {
        ...state,
        postStatus: {
          ...state.postStatus,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        srcId: {
          ...state.srcId,
          err: hasError(action.payload.srcId),
          hlp: getFirstErrMsg(action.payload.srcId),
        },
        preStatus: {
          ...state.preStatus,
          err: hasError(action.payload.preStatus),
          hlp: getFirstErrMsg(action.payload.preStatus),
        },
        postStatus: {
          ...state.postStatus,
          err: hasError(action.payload.postStatus),
          hlp: getFirstErrMsg(action.payload.postStatus),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        srcId: initField(state.srcId.val),
        preStatus: initField(state.preStatus.val),
        postStatus: initField(state.postStatus.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
