import Feedbacks from '../../sys/models/Feedbacks';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 意见反馈 状态
export interface State extends Linear {
  /// 使用平台
  platform: FormField<number | null>;

  /// 类型
  genre: FormField<number | null>;

  /// 您的联系方式
  contact: FormField<string | null>;

  /// 反馈内容
  content: FormField<string | null>;

  /// 分页
  page: Page<Feedbacks>;
}

/// 意见反馈 初始状态
const initState: State = {
  ...initLinear,
  platform: initField(50),
  genre: initField(10),
  contact: initField(''),
  content: initField(''),
  page: initPage<Feedbacks>(),
};

export default initState;
