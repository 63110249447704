import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  fromId: [validators.gt(0)],
  fromDeptId: [validators.gt(0)],
  parentId: [validators.gt(0)],
  taskId: [validators.gt(0)],
  deptId: [validators.gt(0)],
  status: [validators.gt(0)],
});

export default validator;
