import { State } from '../../gsb/states/logistics';
import Action from '../../gsb/actions/logistics';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        orderId: {
          val: action.payload.orderId,
          err: false,
          hlp: '',
        },
        customerId: {
          val: action.payload.customerId,
          err: false,
          hlp: '',
        },
        accountId: {
          val: action.payload.accountId,
          err: false,
          hlp: '',
        },
        addressId: {
          val: action.payload.addressId,
          err: false,
          hlp: '',
        },
        logistics: {
          val: action.payload.logistics,
          err: false,
          hlp: '',
        },
        waybill: {
          val: action.payload.waybill,
          err: false,
          hlp: '',
        },
        freight: {
          val: action.payload.freight,
          err: false,
          hlp: '',
        },
        isBalance: {
          val: action.payload.isBalance,
          err: false,
          hlp: '',
        },
        status: {
          val: action.payload.status,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 订单号 值
    case 'setOrderId':
      return {
        ...state,
        orderId: {
          ...state.orderId,
          val: action.payload,
        },
      };
    /// 设置 客户 值
    case 'setCustomerId':
      return {
        ...state,
        customerId: {
          ...state.customerId,
          val: action.payload,
        },
      };
    /// 设置 账户 值
    case 'setAccountId':
      return {
        ...state,
        accountId: {
          ...state.accountId,
          val: action.payload,
        },
      };
    /// 设置 地址 值
    case 'setAddressId':
      return {
        ...state,
        addressId: {
          ...state.addressId,
          val: action.payload,
        },
      };
    /// 设置 物流 值
    case 'setLogistics':
      return {
        ...state,
        logistics: {
          ...state.logistics,
          val: action.payload,
        },
      };
    /// 设置 运单号 值
    case 'setWaybill':
      return {
        ...state,
        waybill: {
          ...state.waybill,
          val: action.payload,
        },
      };
    /// 设置 运费 值
    case 'setFreight':
      return {
        ...state,
        freight: {
          ...state.freight,
          val: action.payload,
        },
      };
    /// 设置 是否结算 值
    case 'setIsBalance':
      return {
        ...state,
        isBalance: {
          ...state.isBalance,
          val: action.payload,
        },
      };
    /// 设置 状态 值
    case 'setStatus':
      return {
        ...state,
        status: {
          ...state.status,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        orderId: {
          ...state.orderId,
          err: hasError(action.payload.orderId),
          hlp: getFirstErrMsg(action.payload.orderId),
        },
        customerId: {
          ...state.customerId,
          err: hasError(action.payload.customerId),
          hlp: getFirstErrMsg(action.payload.customerId),
        },
        accountId: {
          ...state.accountId,
          err: hasError(action.payload.accountId),
          hlp: getFirstErrMsg(action.payload.accountId),
        },
        addressId: {
          ...state.addressId,
          err: hasError(action.payload.addressId),
          hlp: getFirstErrMsg(action.payload.addressId),
        },
        logistics: {
          ...state.logistics,
          err: hasError(action.payload.logistics),
          hlp: getFirstErrMsg(action.payload.logistics),
        },
        waybill: {
          ...state.waybill,
          err: hasError(action.payload.waybill),
          hlp: getFirstErrMsg(action.payload.waybill),
        },
        freight: {
          ...state.freight,
          err: hasError(action.payload.freight),
          hlp: getFirstErrMsg(action.payload.freight),
        },
        isBalance: {
          ...state.isBalance,
          err: hasError(action.payload.isBalance),
          hlp: getFirstErrMsg(action.payload.isBalance),
        },
        status: {
          ...state.status,
          err: hasError(action.payload.status),
          hlp: getFirstErrMsg(action.payload.status),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        orderId: initField(state.orderId.val),
        customerId: initField(state.customerId.val),
        accountId: initField(state.accountId.val),
        addressId: initField(state.addressId.val),
        logistics: initField(state.logistics.val),
        waybill: initField(state.waybill.val),
        freight: initField(state.freight.val),
        isBalance: initField(state.isBalance.val),
        status: initField(state.status.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
