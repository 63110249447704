import Apply from '../../ses/models/Apply';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 我的申请 状态
export interface State extends Linear {
  /// 学生
  studentId: FormField<number | null>;

  /// 申请描述
  remark: FormField<string | null>;

  /// 申请状态
  status: FormField<number | null>;

  /// 分页
  page: Page<Apply>;
}

/// 我的申请 初始状态
const initState: State = {
  ...initLinear,
  studentId: initField(0),
  remark: initField(''),
  status: initField(0),
  page: initPage<Apply>(),
};

export default initState;
