import { ROOT } from 'vts/consts/id';
import Linear, { initLinear } from 'vts/models/core/Linear';

/// 任务
export default interface Task extends Linear {
  /// 流程
  processId: number;

  /// 上一步任务ID
  fromId: number;

  /// 上一步任务定义ID
  fromDefId: string;

  /// 上一步办理意见
  fromOpinion: string;

  /// 上上一步办理意见
  from2Opinion: string;

  /// 任务定义ID
  defId: string;

  /// 任务定义排序
  ordinal: number;

  /// 名称
  name: string;

  /// 待办人岗位
  postId: number;
}

export const newTask = {
  ...initLinear,
  processId: 0,
  fromId: ROOT,
  fromDefId: 'root',
  fromOpinion: '',
  from2Opinion: '',
  defId: '',
  ordinal: 0,
  name: '',
  postId: 0,
};
