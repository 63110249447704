import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required()],
  profit: [validators.required()],
  balance: [validators.required()],
  recharge: [validators.required()],
  stock: [validators.required()],
});

export default validator;
