import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required()],
  nickname: [validators.required(), validators.eqlen(2)],
  ordinal: [validators.gt(0)],
});

export default validator;
