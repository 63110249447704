import Linear, { initLinear } from 'vts/models/core/Linear';

/// 考核项目
export default interface Item extends Linear {
  /// 名称
  name: string;

  /// 权重
  weight: number;
}

export const newItem = {
  ...initLinear,
  name: '',
  weight: 0,
};
