import Area from '../../sys/models/Area';
import Page, { initPage } from 'vts/models/core/Page';
import Tree, { initTree } from 'vts/states/core/tree';

/// 区域 状态
export interface State extends Tree {

  /// 分页
  page: Page<Area>;
}

/// 区域 初始状态
const initState: State = {
  ...initTree,
  page: initPage<Area>(),
};

export default initState;
