import Blacklist from '../../sys/models/Blacklist';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 黑名单 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 类型
  genre: FormField<number | null>;

  /// 可用
  usable: FormField<boolean | null>;

  /// 分页
  page: Page<Blacklist>;
}

/// 黑名单 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  genre: initField(0),
  usable: initField(false),
  page: initPage<Blacklist>(),
};

export default initState;
