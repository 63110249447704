import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  orderId: [validators.gt(0)],
  customerId: [validators.gt(0)],
  accountId: [validators.gt(0)],
  productId: [validators.gt(0)],
  goodsId: [validators.gt(0)],
  quantity: [validators.required(), validators.gt(0)],
  priceIn: [validators.required()],
  priceOut: [validators.required()],
  amountIn: [validators.required()],
  amountOut: [validators.required()],
  status: [validators.gt(0)],
});

export default validator;
