import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  studentId: [validators.gt(0)],
  remark: [validators.required()],
  status: [validators.gt(0)],
});

export default validator;
