import Archive from '../../sys/models/Archive';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 文件 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 路径
  path: FormField<string | null>;

  /// 分页
  page: Page<Archive>;
}

/// 文件 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  path: initField(''),
  page: initPage<Archive>(),
};

export default initState;
