import Tree, { initTree } from 'vts/models/core/Tree';

export const GENRE_WORK = 10; // 单位
export const GENRE_DEPT = 20; // 部门
export const GENRE_GRP = 30; // 小组
export const GENRE_MBR = 40; // 职员

/// 通讯录
export default interface Contact extends Tree {
  /// 类型
  genre: number;

  /// 手机
  phone: string;

  /// 小号
  shortTel: string;

  /// 固话
  tel: string;

  /// 内线专线
  otherTel: string;

  /// 单位
  workId: number;

  /// 单位
  work: string;

  /// 部门
  department: string;

  /// 职务
  position: string;

  /// 办公室
  office: string;

  /// 邮件
  email: string;

  /// 性别
  sex: number;

  /// 生日
  birthday: string;

  /// 地址
  address: string;
}

export const newContact = {
  ...initTree,
  genre: 10,
  phone: '',
  shortTel: '',
  tel: '',
  otherTel: '',
  workId: 0,
  work: '',
  department: '',
  position: '',
  office: '',
  email: '',
  sex: 0,
  birthday: '',
  address: '',
};
