import Linear, { initLinear } from 'vts/models/core/Linear';

export const LOGISTICS_BS = 10; // 百世
export const LOGISTICS_YZ = 20; // EMS
export const LOGISTICS_YD = 30; // 韵达
export const LOGISTICS_ST = 40; // 申通
export const LOGISTICS_YT = 50; // 圆通
export const LOGISTICS_ZT = 60; // 中通
export const LOGISTICS_TT = 70; // 天天
export const LOGISTICS_HT = 80; // 汇通
export const LOGISTICS_SF = 90; // 顺丰

export const STATUS_ORDER = 10; // 下单中
export const STATUS_CART = 20; // 购物中
export const STATUS_PAYMENT = 30; // 已支付
export const STATUS_LOGISTICS = 40; // 已发货
export const STATUS_BALANCE = 50; // 已结算
export const STATUS_REFUND = 60; // 已退货

/// 物流
export default interface Logistics extends Linear {
  /// 订单号
  orderId: number;

  /// 客户
  customerId: number;

  /// 账户
  accountId: number;

  /// 地址
  addressId: number;

  /// 物流
  logistics: number;

  /// 运单号
  waybill: string;

  /// 运费
  freight: number;

  /// 是否结算
  isBalance: boolean;

  /// 状态
  status: number;
}

export const newLogistics = {
  ...initLinear,
  orderId: 0,
  customerId: 0,
  accountId: 0,
  addressId: 0,
  logistics: 0,
  waybill: '',
  freight: 0.0,
  isBalance: true,
  status: 40,
};
