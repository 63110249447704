import Linear, { initLinear } from 'vts/models/core/Linear';

export const SEX_MALE = 10; // 男
export const SEX_FEMALE = 20; // 女

/// 客户
export default interface Customer extends Linear {
  /// 名称
  name: string;

  /// 电话
  telephone: string;

  /// 性别
  sex: number;

  /// 邮件
  email: string;

  /// QQ
  qq: string;
}

export const newCustomer = {
  ...initLinear,
  name: '',
  telephone: '',
  sex: 20,
  email: '',
  qq: '',
};
