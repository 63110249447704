import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  AppBar,
  Breadcrumbs,
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ArrowBackIos,
  Delete,
  Edit,
  Refresh,
  Save,
} from '@mui/icons-material';
import DelDialog from 'vts/components/dialogs/DelDialog';
import { DelHandle } from 'vts/interfaces/dialog';
import LinkRouter from 'vts/components/link/router';
import Nil from 'vts/components/table/Nil';
import Snack from 'vts/components/snacks/Snack';
import OpenSnack from 'vts/interfaces/snack';
import * as globals from 'vts/utils/globals';
import Nav from '../../Nav';
import Goods, { newGoods } from '../../gsb/models/Goods';
import initState from '../../gsb/states/goods';
import reducer from '../../gsb/reducers/goods';
import validator from '../../gsb/validators/goods';
import { del, find, ins, upd } from '../../gsb/apis/goods';

export default function GoodsScreen() {
  const [_state, dispatch] = useReducer(reducer, initState);

  const [accountId, setAccountId] = useState<number | null>(null);
  const [genreId, setGenreId] = useState<number | null>(null);
  const [productId, setProductId] = useState<number | null>(null);
  const [sourceId, setSourceId] = useState<number | null>(null);
  const [priceInBegin, setPriceInBegin] = useState<number | null>(null);
  const [priceInEnd, setPriceInEnd] = useState<number | null>(null);
  const [priceOutBegin, setPriceOutBegin] = useState<number | null>(null);
  const [priceOutEnd, setPriceOutEnd] = useState<number | null>(null);
  const [inventoryBegin, setInventoryBegin] = useState<number | null>(null);
  const [inventoryEnd, setInventoryEnd] = useState<number | null>(null);
  const [status, setStatus] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [isIns, setIsIns] = useState(false);

  const [snackContent, setSnackContent] = useState('保存成功');

  // 需要注意的是如果 ref 为元素，那么初始值得写个 null 才不会报错
  const snackRef = useRef<OpenSnack>(null);
  const delDialogRef = useRef<DelHandle>(null);

  const refresh = () => {
    find({
      accountId: accountId,
      genreId: genreId,
      productId: productId,
      sourceId: sourceId,
      priceInBegin: priceInBegin,
      priceInEnd: priceInEnd,
      priceOutBegin: priceOutBegin,
      priceOutEnd: priceOutEnd,
      inventoryBegin: inventoryBegin,
      inventoryEnd: inventoryEnd,
      status: status,
      page: page,
      perPage: perPage,
    }).then(function (resp) {
      dispatch({ type: 'found', payload: resp.data });
    });
  };

  useEffect(refresh, [accountId, genreId, productId, sourceId, priceInBegin, priceInEnd, priceOutBegin, priceOutEnd, inventoryBegin, inventoryEnd, status, page, perPage]);

  const openFormDialog = (model: Goods, isIns: boolean) => {
    dispatch({ type: 'setModel', payload: model });
    setIsOpenFormDialog(true);
    setIsIns(isIns);
  };

  const closeFormDialog = () => setIsOpenFormDialog(false);

  const openDelDialog = (id: number, other: string) => delDialogRef.current?.open(id, other);

  const insFormDialog = () => openFormDialog(newGoods, true);

  const submit = () => {
    validator
      .validate({
        accountId: _state.accountId.val,
        genreId: _state.genreId.val,
        productId: _state.productId.val,
        sourceId: _state.sourceId.val,
        priceIn: _state.priceIn.val,
        priceOut: _state.priceOut.val,
        status: _state.status.val,
      })
      .then(() => {
        dispatch({ type: 'clearError' });
        let save = isIns ? ins : upd;
        save({
          id: _state.id.val,
          accountId: _state.accountId.val,
          genreId: _state.genreId.val,
          productId: _state.productId.val,
          sourceId: _state.sourceId.val,
          priceIn: _state.priceIn.val,
          priceOut: _state.priceOut.val,
          inventory: _state.inventory.val,
          status: _state.status.val,
          memo: _state.memo.val,
        }).then(function (resp) {
          if (resp.data.success) {
            closeFormDialog();
            refresh();
            setSnackContent('保存成功');
            snackRef.current?.open();
          } else {
            setSnackContent(resp.data.head);
            snackRef.current?.open();
          }
        });
      })
      .catch(({ errors, fields }) => {
        dispatch({ type: 'validFail', payload: fields });
      });
  };

  /// 删除
  const onDel = (id: number, other: string) => {
    del({ id: id, other: other }).then(function (resp) {
      delDialogRef.current?.close();
      if (resp.data.success) {
        refresh();
        setSnackContent('删除成功');
        snackRef.current?.open();
      } else {
        setSnackContent(resp.data.head);
        snackRef.current?.open();
      }
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: 'setPage', payload: newPage + 1 });
    setPage(newPage + 1);
  };

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseInt(event.target.value, 10);
    dispatch({ type: 'setPerPage', payload: value });
    setPerPage(value);
  };

  return (
    <Fragment>
      <Nav />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
        px={2}
      >
        <Breadcrumbs>
          <LinkRouter to="/">首页</LinkRouter>
          <Typography color="textPrimary">库存商品</Typography>
        </Breadcrumbs>
        <Grid>
          <Tooltip title="新增">
            <IconButton onClick={insFormDialog}>
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title="刷新">
            <IconButton onClick={refresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <FormControl
        fullWidth={true}
        sx={{ mx: 1.5, my: 0.5, flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <TextField
          variant="outlined"
          size="small"
          label="账户"
          onChange={(e) => {
            setPage(1);
            setAccountId(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="分类"
          onChange={(e) => {
            setPage(1);
            setGenreId(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="商品"
          onChange={(e) => {
            setPage(1);
            setProductId(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="业务ID"
          onChange={(e) => {
            setPage(1);
            setSourceId(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="开始进价"
          onChange={(e) => {
            setPage(1);
            setPriceInBegin(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="截止进价"
          onChange={(e) => {
            setPage(1);
            setPriceInEnd(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="开始单价"
          onChange={(e) => {
            setPage(1);
            setPriceOutBegin(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="截止单价"
          onChange={(e) => {
            setPage(1);
            setPriceOutEnd(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="开始库存数量"
          onChange={(e) => {
            setPage(1);
            setInventoryBegin(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="截止库存数量"
          onChange={(e) => {
            setPage(1);
            setInventoryEnd(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="状态"
          onChange={(e) => {
            setPage(1);
            setStatus(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
      </FormControl>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={100}>序号</TableCell>
              <TableCell>账户</TableCell>
              <TableCell>分类</TableCell>
              <TableCell>商品</TableCell>
              <TableCell>业务ID</TableCell>
              <TableCell>进价</TableCell>
              <TableCell>单价</TableCell>
              <TableCell>库存数量</TableCell>
              <TableCell>状态</TableCell>
              <TableCell width={70}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_state.page.items?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  {globals.dictPvtVal('gsb_account.id', row.accountId.toString())}
                </TableCell>
                <TableCell>
                  {globals.dictPvtVal('gsb_genre.id', row.genreId.toString())}
                </TableCell>
                <TableCell>
                  {globals.dictPvtVal('gsb_product.id', row.productId.toString())}
                </TableCell>
                <TableCell>{row.sourceId.toString()}</TableCell>
                <TableCell>{row.priceIn.toString()}</TableCell>
                <TableCell>{row.priceOut.toString()}</TableCell>
                <TableCell>{row.inventory.toString()}</TableCell>
                <TableCell>
                  {globals.dictPubVal('gsb_goods.status', row.status.toString())}
                </TableCell>
                <TableCell>
                  <Tooltip title="修改">
                    <IconButton
                      size="small"
                      onClick={() => openFormDialog(row, false)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="删除">
                    <IconButton
                      size="small"
                      onClick={(event) => openDelDialog(row.id, '')}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Nil colSpan={10} rowTotal={_state.page.total} />
        </Table>
        <TablePagination
          component="div"
          count={_state.page.total}
          page={_state.page.page - 1}
          onPageChange={onPageChange}
          rowsPerPage={_state.page.perPage}
          onRowsPerPageChange={onPerPageChange}
          sx={{ mx: 2 }}
        />
      </TableContainer>
      {/* 表单编辑 */}
      <Dialog fullScreen open={isOpenFormDialog} onClose={closeFormDialog}>
        <AppBar elevation={0}>
          <Toolbar>
            <Tooltip title="返回">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <ArrowBackIos />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              {isIns ? '库存商品添加' : '库存商品修改'}
            </Typography>
            <Tooltip title="保存">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <Save />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <FormControl sx={{ mx: 2, mt: 9, mb: 2 }}>
          <TextField
            fullWidth
            variant="standard"
            label="账户"
            type="number"
            value={_state.accountId.val}
            error={_state.accountId.err}
            helperText={_state.accountId.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setAccountId',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="分类"
            type="number"
            value={_state.genreId.val}
            error={_state.genreId.err}
            helperText={_state.genreId.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setGenreId',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="商品"
            type="number"
            value={_state.productId.val}
            error={_state.productId.err}
            helperText={_state.productId.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setProductId',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="业务ID"
            type="number"
            value={_state.sourceId.val}
            error={_state.sourceId.err}
            helperText={_state.sourceId.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setSourceId',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="进价"
            type="number"
            value={_state.priceIn.val}
            error={_state.priceIn.err}
            helperText={_state.priceIn.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setPriceIn',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="单价"
            type="number"
            value={_state.priceOut.val}
            error={_state.priceOut.err}
            helperText={_state.priceOut.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setPriceOut',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="库存数量"
            type="number"
            value={_state.inventory.val}
            error={_state.inventory.err}
            helperText={_state.inventory.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setInventory',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="状态"
            type="number"
            value={_state.status.val}
            error={_state.status.err}
            helperText={_state.status.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setStatus',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="备注"
            type="string"
            value={_state.memo.val}
            error={_state.memo.err}
            helperText={_state.memo.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setMemo',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <span style={{ marginTop: 20 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeFormDialog}
            >
              返回
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={submit}
              sx={{ ml: 2 }}
            >
              保存
            </Button>
          </span>
        </FormControl>
      </Dialog>
      <Snack ref={snackRef} content={snackContent} />
      <DelDialog ref={delDialogRef} onOk={onDel} />
    </Fragment>
  );
}
