import { State } from '../../kpi/states/grade';
import Action from '../../kpi/actions/grade';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        processId: {
          val: action.payload.processId,
          err: false,
          hlp: '',
        },
        toId: {
          val: action.payload.toId,
          err: false,
          hlp: '',
        },
        score: {
          val: action.payload.score,
          err: false,
          hlp: '',
        },
        cause: {
          val: action.payload.cause,
          err: false,
          hlp: '',
        },
        divisor: {
          val: action.payload.divisor,
          err: false,
          hlp: '',
        },
        average: {
          val: action.payload.average,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 流程 值
    case 'setProcessId':
      return {
        ...state,
        processId: {
          ...state.processId,
          val: action.payload,
        },
      };
    /// 设置 评分对象 值
    case 'setToId':
      return {
        ...state,
        toId: {
          ...state.toId,
          val: action.payload,
        },
      };
    /// 设置 扣分分数 值
    case 'setScore':
      return {
        ...state,
        score: {
          ...state.score,
          val: action.payload,
        },
      };
    /// 设置 扣分原因 值
    case 'setCause':
      return {
        ...state,
        cause: {
          ...state.cause,
          val: action.payload,
        },
      };
    /// 设置 平均分除数 值
    case 'setDivisor':
      return {
        ...state,
        divisor: {
          ...state.divisor,
          val: action.payload,
        },
      };
    /// 设置 平均分 值
    case 'setAverage':
      return {
        ...state,
        average: {
          ...state.average,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        processId: {
          ...state.processId,
          err: hasError(action.payload.processId),
          hlp: getFirstErrMsg(action.payload.processId),
        },
        toId: {
          ...state.toId,
          err: hasError(action.payload.toId),
          hlp: getFirstErrMsg(action.payload.toId),
        },
        score: {
          ...state.score,
          err: hasError(action.payload.score),
          hlp: getFirstErrMsg(action.payload.score),
        },
        cause: {
          ...state.cause,
          err: hasError(action.payload.cause),
          hlp: getFirstErrMsg(action.payload.cause),
        },
        divisor: {
          ...state.divisor,
          err: hasError(action.payload.divisor),
          hlp: getFirstErrMsg(action.payload.divisor),
        },
        average: {
          ...state.average,
          err: hasError(action.payload.average),
          hlp: getFirstErrMsg(action.payload.average),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        processId: initField(state.processId.val),
        toId: initField(state.toId.val),
        score: initField(state.score.val),
        cause: initField(state.cause.val),
        divisor: initField(state.divisor.val),
        average: initField(state.average.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
