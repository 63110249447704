import Linear, { initLinear } from 'vts/models/core/Linear';

/// 成绩
export default interface Score extends Linear {
  /// 学生
  studentId: number;

  /// 考试名称
  examId: number;

  /// 课程
  courseId: number;

  /// 成绩
  mark: number;

  /// 考试时间
  examAt: string;
}

export const newScore = {
  ...initLinear,
  studentId: 0,
  examId: 0,
  courseId: 0,
  mark: 0.0,
  examAt: '',
};
