import Linear, { initLinear } from 'vts/models/core/Linear';

export const SEX_MALE = 10; // 男
export const SEX_FEMALE = 20; // 女

/// 学生
export default interface Student extends Linear {
  /// 姓名
  name: string;

  /// 学号
  sid: string;

  /// 入学时间
  enrolAt: string;

  /// 性别
  sex: number;
}

export const newStudent = {
  ...initLinear,
  name: '',
  sid: '',
  enrolAt: '',
  sex: 0,
};
