import UserDept from '../../sys/models/UserDept';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 用户部门 状态
export interface State extends Linear {
  /// 用户
  userId: FormField<number | null>;

  /// 部门
  deptId: FormField<number | null>;

  /// 是否是默认
  isDefault: FormField<boolean | null>;

  /// 分页
  page: Page<UserDept>;
}

/// 用户部门 初始状态
const initState: State = {
  ...initLinear,
  userId: initField(0),
  deptId: initField(0),
  isDefault: initField(false),
  page: initPage<UserDept>(),
};

export default initState;
