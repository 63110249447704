import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import Routes from './Routes';
import RoutesH from './Routes.h';
import theme from 'vts/themes/index';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes />
          <RoutesH />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
