import { State } from '../../ses/states/bind';
import Action from '../../ses/actions/bind';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        userId: {
          val: action.payload.userId,
          err: false,
          hlp: '',
        },
        studentId: {
          val: action.payload.studentId,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 用户 值
    case 'setUserId':
      return {
        ...state,
        userId: {
          ...state.userId,
          val: action.payload,
        },
      };
    /// 设置 学生 值
    case 'setStudentId':
      return {
        ...state,
        studentId: {
          ...state.studentId,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        userId: {
          ...state.userId,
          err: hasError(action.payload.userId),
          hlp: getFirstErrMsg(action.payload.userId),
        },
        studentId: {
          ...state.studentId,
          err: hasError(action.payload.studentId),
          hlp: getFirstErrMsg(action.payload.studentId),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        userId: initField(state.userId.val),
        studentId: initField(state.studentId.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
