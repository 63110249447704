import { ROOT } from 'vts/consts/id';
import Tree, { initTree } from 'vts/models/core/Tree';

export const STATUS_OPEN = 10; // 开业
export const STATUS_CLOSE = 20; // 歇业
export const STATUS_STOP = 30; // 停业

export const GENRE_GSB = 101010; // 商品销售记账
export const GENRE_MCB = 202010; // 会员消费记账
export const GENRE_SES = 303020; // 学生考试成绩
export const GENRE_CMP = 304030; // 同学周年集会
export const GENRE_KPI = 405040; // 公司绩效考核
export const GENRE_HRB = 506010; // 房屋租赁记账
export const GENRE_GPC = 707050; // 政府党政公车
export const GENRE_GPH = 707060; // 政府党政公房
export const GENRE_GPT = 707070; // 政府党政电话

export const SHIFTS_TWO = 20; // 两班制
export const SHIFTS_THREE = 30; // 三班制

/// 机构
export default interface Organ extends Tree {
  /// 区域
  areaId: number;

  /// 昵称
  nickname: string;

  /// 简介
  intro: string;

  /// 城市
  city: string;

  /// 地址
  address: string;

  /// 手机
  phone: string;

  /// 固话
  tel: string;

  /// 开业时间
  startAt: string;

  /// 状态
  status: number;

  /// 类型
  genre: number;

  /// 上班制
  shifts: number;

  /// 二维码
  qrcode: string;

  /// 允许所有用户搜索到本机构
  isPub: boolean;
}

export const newOrgan = {
  ...initTree,
  areaId: ROOT,
  nickname: '',
  intro: '',
  city: '',
  address: '',
  phone: '',
  tel: '',
  startAt: '',
  status: 10,
  genre: 0,
  shifts: 20,
  qrcode: '',
  isPub: false,
};
