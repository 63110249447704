import Linear, { initLinear } from 'vts/models/core/Linear';

/// 对照表
export default interface Contrast extends Linear {
  /// 第几排
  rows: number;

  /// 第几个
  col: number;

  /// 名称
  name: string;
}

export const newContrast = {
  ...initLinear,
  rows: 0,
  col: 0,
  name: '',
};
