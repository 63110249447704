import { KeyboardEvent, useState, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Box,
  Stack,
  Divider,
  FormControl,
} from '@mui/material';
import AsyncValidator from 'async-validator';
import ConfirmDialog from 'vts/components/dialogs/ConfirmDialog';
import { ConfirmHandle } from 'vts/interfaces/dialog';
import axios, { AxiosResponse } from 'vts/utils/axios';
import Auth from 'vts/models/core/Auth';
import initState from 'vts/states/cmn/login';
import reducer from 'vts/reducers/cmn/login';
import Snack from 'vts/components/snacks/Snack';
import OpenSnack from 'vts/interfaces/snack';
import { resetAuth, setDictPub, setDictPvt } from 'vts/utils/globals';
import { dictpub, dictpvt } from 'vts/apis/core/cache';
import { setRevision, isEqRevision } from 'vts/utils/revisions';
import { PLATFORM_ADM } from './sys/models/User';

const validator = new AsyncValidator({
  username: [
    {
      required: true,
      message: '请填写用户名',
    },
    {
      pattern: /^[a-zA-Z0-9_-]{3,16}$/,
      message: '由长度3到16位的字母或数字或下划线组成',
    },
  ],
  password: [
    {
      required: true,
      message: '请填写密码',
    },
    {
      pattern: /^[a-zA-Z0-9_-]{6,}$/,
      message: '由长度不小于6的字母或数字或下划线组成',
    },
  ],
  captcha: [
    {
      required: true,
      message: '请填写授权码',
    },
    {
      pattern: /^[0-9_-]{6}$/,
      message: '由长度6位的数字组成',
    },
  ],
});

const sx = {
  screen: {
    width: '100%',
    height: '100%',
    background:
      'radial-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .15) 30%, rgba(255, 255, 255, .3) 32%, rgba(255, 255, 255, 0) 33%) 0 0, radial-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .1) 11%, rgba(255, 255, 255, .3) 13%, rgba(255, 255, 255, 0) 14%) 0 0, radial-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .2) 17%, rgba(255, 255, 255, .43) 19%, rgba(255, 255, 255, 0) 20%) 0 110px, radial-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .2) 11%, rgba(255, 255, 255, .4) 13%, rgba(255, 255, 255, 0) 14%) -130px -170px, radial-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .2) 11%, rgba(255, 255, 255, .4) 13%, rgba(255, 255, 255, 0) 14%) 130px 370px, radial-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .1) 11%, rgba(255, 255, 255, .2) 13%, rgba(255, 255, 255, 0) 14%) 0 0, linear-gradient(45deg, #343702 0%, #184500 20%, #187546 30%, #006782 40%, #0b1284 50%, #760ea1 60%, #83096e 70%, #840b2a 80%, #b13e12 90%, #e27412 100%)',
    backgroundSize:
      '470px 470px, 970px 970px, 410px 410px, 610px 610px, 530px 530px, 730px 730px, 100% 100%',
    backgroundColor: '#840b2a',
  },
  form: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    margin: 'auto',
    padding: 'auto',
    minWidth: '330px',
  },
};

export default function LoginScreen() {
  const [snackContent, setSnackContent] = useState('');

  const [state, dispatch] = useReducer(reducer, initState);

  const passwordRef = useRef<HTMLDivElement>(null);
  const captchaRef = useRef<HTMLDivElement>(null);

  const snackRef = useRef<OpenSnack>(null);
  const confirmDialogRef = useRef<ConfirmHandle>(null);

  const navigate = useNavigate();

  const submit = () => {
    let captcha =
      process.env.REACT_APP_LOGIN_AUTH_CODE === 'none'
        ? '111111'
        : state.captcha.val;
    validator
      .validate({
        username: state.username.val,
        password: state.password.val,
        captcha: captcha,
      })
      .then(() => {
        dispatch({ type: 'clearError' });
        axios
          .post<any, AxiosResponse<Auth>>('/auth/login', {
            username: state.username.val,
            password: state.password.val,
            captcha: captcha,
            platform: PLATFORM_ADM,
          })
          .then(function (resp) {
            if (resp.data.success) {
              postLogin(resp.data);
            } else {
              if (resp.data.code === 5555) {
                confirmDialogRef.current?.open(state.username.val);
              } else {
                setSnackContent(resp.data.head);
                snackRef.current?.open();
              }
            }
          });
      })
      .catch(({ errors, fields }) => {
        dispatch({ type: 'validFail', payload: fields });
      });
  };

  /// 用户名回车后执行
  const onKeyUpUsername = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      passwordRef.current?.focus();
    }
  };

  /// 密码回车后执行
  const onKeyUpPasswd = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      captchaRef.current?.focus();
    }
  };

  /// 授权码回车后执行
  const onKeyUpCaptcha = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  /// 首次登录设置密码
  const onOk = (id: number | string) => {
    axios
      .post<any, AxiosResponse<Auth>>('/auth/resetpwd', {
        username: state.username.val,
        password: state.password.val,
        platform: PLATFORM_ADM,
      })
      .then(function (resp) {
        if (resp.data.success) {
          postLogin(resp.data);
        } else {
          setSnackContent(resp.data.head);
          snackRef.current?.open();
        }
      });
  };

  /// 登录后
  const postLogin = (auth: Auth) => {
    // 获取所有机构公用的字典缓存
    dictpub({}).then(function (resp) {
      if (resp.data.success) {
        setDictPub(resp.data);
      }
    });
    // 获取当前机构自己的字典缓存
    dictpvt({}).then(function (resp) {
      if (resp.data.success) {
        setDictPvt(resp.data);
      }
    });
    // 设置全局登录信息
    resetAuth(auth);
    if (isEqRevision(auth.revision)) {
      // 版本一致时，不刷新页面，因为是单页面，只是路由跳转并不会刷新页面
      navigate('/?r=' + auth.revision);
    } else {
      // 版本不一致时，刷新页面，并设置新版本
      setRevision(auth.revision);
      window.location.href = '/?r=' + auth.revision;
    }
  };

  return (
    <Stack sx={sx.screen}>
      <Stack sx={sx.form}>
        <Box fontSize={18} mx={3} my={2}>
          {process.env.REACT_APP_TITLE}
        </Box>
        <Divider />
        <FormControl sx={{ margin: 3 }}>
          <TextField
            fullWidth
            variant="standard"
            label="用户名"
            placeholder="请输入用户名"
            value={state.username.val}
            error={state.username.err}
            helperText={state.username.hlp}
            onChange={(e) =>
              dispatch({ type: 'setUsername', payload: e.target.value })
            }
            onKeyUp={onKeyUpUsername}
          />
          <TextField
            ref={passwordRef}
            fullWidth
            variant="standard"
            type="password"
            label="密码"
            placeholder="请输入密码"
            value={state.password.val}
            error={state.password.err}
            helperText={state.password.hlp}
            onChange={(e) =>
              dispatch({ type: 'setPassword', payload: e.target.value })
            }
            onKeyUp={onKeyUpPasswd}
            sx={{ mt: 2 }}
          />
          <TextField
            ref={captchaRef}
            fullWidth
            variant="standard"
            label="授权码"
            placeholder="请输入授权码"
            value={state.captcha.val}
            error={state.captcha.err}
            helperText={state.captcha.hlp}
            onChange={(e) =>
              dispatch({ type: 'setCaptcha', payload: e.target.value })
            }
            onKeyUp={onKeyUpCaptcha}
            sx={{
              mt: 2,
              display: process.env.REACT_APP_LOGIN_AUTH_CODE,
            }}
          />
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            size="large"
            onClick={submit}
            sx={{ mt: 4 }}
          >
            登录
          </Button>
        </FormControl>
        <Snack ref={snackRef} content={snackContent} />
        <ConfirmDialog
          ref={confirmDialogRef}
          onOk={onOk}
          title="首次登录请设置密码"
          content="确定把输入的密码作为以后的登录密码吗？"
        />
      </Stack>
    </Stack>
  );
}
