import Msg from '../../cmn/models/Msg';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 消息 状态
export interface State extends Linear {
  /// 机构
  organId: FormField<number | null>;

  /// 机构名称
  organName: FormField<string | null>;

  /// 用户
  userId: FormField<number | null>;

  /// 用户名
  userName: FormField<string | null>;

  /// 内容
  content: FormField<string | null>;

  /// 状态
  status: FormField<number | null>;

  /// 分页
  page: Page<Msg>;
}

/// 消息 初始状态
const initState: State = {
  ...initLinear,
  organId: initField(0),
  organName: initField(''),
  userId: initField(0),
  userName: initField(''),
  content: initField(''),
  status: initField(0),
  page: initPage<Msg>(),
};

export default initState;
