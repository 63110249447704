import Gov from '../../gpc/models/Gov';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 单位 状态
export interface State extends Linear {
  /// 单位名称
  name: FormField<string | null>;

  /// 单位性质
  nature: FormField<number | null>;

  /// 排序
  ordinal: FormField<number | null>;

  /// 分页
  page: Page<Gov>;
}

/// 单位 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  nature: initField(0),
  ordinal: initField(0),
  page: initPage<Gov>(),
};

export default initState;
