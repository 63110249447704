import Menu from '../../sys/models/Menu';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Tree, { initTree } from 'vts/states/core/tree';

/// 菜单 状态
export interface State extends Tree {
  /// 管理员
  isAdmin: FormField<boolean | null>;

  /// 菜单类型
  genre: FormField<number | null>;

  /// 分页
  page: Page<Menu>;
}

/// 菜单 初始状态
const initState: State = {
  ...initTree,
  isAdmin: initField(false),
  genre: initField(0),
  page: initPage<Menu>(),
};

export default initState;
