import Linear, { initLinear } from 'vts/models/core/Linear';

export const STATUS_ORDER = 10; // 下单中
export const STATUS_CART = 20; // 购物中
export const STATUS_PAYMENT = 30; // 已支付
export const STATUS_LOGISTICS = 40; // 已发货
export const STATUS_BALANCE = 50; // 已结算

/// 订单
export default interface Order extends Linear {
  /// 客户
  customerId: number;

  /// 零售日期
  salesAt: string;

  /// 进货合计
  amountIn: number;

  /// 零售合计
  amountOut: number;

  /// 状态
  status: number;
}

export const newOrder = {
  ...initLinear,
  customerId: 0,
  salesAt: '',
  amountIn: 0.0,
  amountOut: 0.0,
  status: 10,
};
