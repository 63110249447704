import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Breadcrumbs,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Lock, LockOpen, Loop, Refresh } from '@mui/icons-material';
import axios, { AxiosResponse } from 'vts/utils/axios';
import LinkRouter from 'vts/components/link/router';
import Nil from 'vts/components/table/Nil';
import ConfirmDialog from 'vts/components/dialogs/ConfirmDialog';
import { ConfirmHandle } from 'vts/interfaces/dialog';
import Snack from 'vts/components/snacks/Snack';
import Resp from 'vts/models/core/Resp';
import OpenSnack from 'vts/interfaces/snack';
import Nav from '../../Nav';
import initState from '../../sys/states/user';
import reducer from '../../sys/reducers/user';
import { find } from '../../sys/apis/user';

export default function UserScreen() {
  const [_state, dispatch] = useReducer(reducer, initState);

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [snackContent, setSnackContent] = useState('保存成功');

  // 需要注意的是如果 ref 为元素，那么初始值得写个 null 才不会报错
  const snackRef = useRef<OpenSnack>(null);
  const lockDialogRef = useRef<ConfirmHandle>(null);
  const unlockDialogRef = useRef<ConfirmHandle>(null);
  const resetDialogRef = useRef<ConfirmHandle>(null);

  const refresh = () => {
    find({
      name: name,
      username: username,
      email: email,
      platform: 20,
      genre: 20,
      page: page,
      perPage: perPage,
    }).then(function (resp) {
      dispatch({ type: 'found', payload: resp.data });
    });
  };

  useEffect(refresh, [name, username, email, page, perPage]);

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: 'setPage', payload: newPage + 1 });
    setPage(newPage + 1);
  };

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseInt(event.target.value, 10);
    dispatch({ type: 'setPerPage', payload: value });
    setPerPage(value);
  };

  /// 禁用登录
  const onLockSubmit = (id: number | string) => {
    axios
      .post<any, AxiosResponse<Resp>>('/auth/lock', {
        id: id,
      })
      .then(function (resp) {
        if (resp.data.success) {
          lockDialogRef.current?.close();
          refresh();
        } else {
          lockDialogRef.current?.close();
          setSnackContent(resp.data.head);
          snackRef.current?.open();
        }
      });
  };

  /// 开启登录
  const onUnlockSubmit = (id: number | string) => {
    axios
      .post<any, AxiosResponse<Resp>>('/auth/unlock', {
        id: id,
      })
      .then(function (resp) {
        if (resp.data.success) {
          unlockDialogRef.current?.close();
          refresh();
        } else {
          unlockDialogRef.current?.close();
          setSnackContent(resp.data.head);
          snackRef.current?.open();
        }
      });
  };

  /// 重置密码
  const onResetSubmit = (id: number | string) => {
    axios
      .post<any, AxiosResponse<Resp>>('/auth/resetpasswd', {
        id: id,
      })
      .then(function (resp) {
        if (resp.data.success) {
          resetDialogRef.current?.close();
          setSnackContent('密码重置成功');
          snackRef.current?.open();
        } else {
          resetDialogRef.current?.close();
          setSnackContent(resp.data.head);
          snackRef.current?.open();
        }
      });
  };

  return (
    <Fragment>
      <Nav />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
        px={2}
      >
        <Breadcrumbs>
          <LinkRouter to="/">首页</LinkRouter>
          <Typography color="textPrimary">后台用户</Typography>
        </Breadcrumbs>
        <Grid>
          <Tooltip title="刷新">
            <IconButton onClick={refresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <FormControl
        fullWidth={true}
        sx={{ mx: 1.5, my: 0.5, flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <TextField
          variant="outlined"
          size="small"
          label="姓名"
          onChange={(e) => {
            setPage(1);
            setName(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="手机"
          onChange={(e) => {
            setPage(1);
            setUsername(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="邮件"
          onChange={(e) => {
            setPage(1);
            setEmail(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
      </FormControl>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={100}>序号</TableCell>
              <TableCell>姓名</TableCell>
              <TableCell>手机</TableCell>
              <TableCell>邮箱</TableCell>
              <TableCell>登录状态</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_state.page.items?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.froze ? '已禁用' : '已开启'}</TableCell>
                <TableCell>
                  <Tooltip title={row.froze ? '开启登录' : '禁用登录'}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (row.froze) {
                          unlockDialogRef.current?.open(row.id);
                        } else {
                          lockDialogRef.current?.open(row.id);
                        }
                      }}
                    >
                      {row.froze ? <LockOpen /> : <Lock />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="重置密码">
                    <IconButton
                      size="small"
                      onClick={() => resetDialogRef.current?.open(row.id)}
                    >
                      <Loop />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Nil colSpan={7} rowTotal={_state.page.total} />
        </Table>
        <TablePagination
          component="div"
          count={_state.page.total}
          page={_state.page.page - 1}
          onPageChange={onPageChange}
          rowsPerPage={_state.page.perPage}
          onRowsPerPageChange={onPerPageChange}
          sx={{ mx: 2 }}
        />
      </TableContainer>
      <ConfirmDialog
        ref={lockDialogRef}
        onOk={onLockSubmit}
        content="确定禁用登录吗？"
      />
      <ConfirmDialog
        ref={unlockDialogRef}
        onOk={onUnlockSubmit}
        content="确定开启登录吗？"
      />
      <ConfirmDialog
        ref={resetDialogRef}
        onOk={onResetSubmit}
        content="确定重置密码吗？"
      />
      <Snack ref={snackRef} content={snackContent} />
    </Fragment>
  );
}
