import { State } from '../../gsb/states/product';
import Action from '../../gsb/actions/product';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        accountId: {
          val: action.payload.accountId,
          err: false,
          hlp: '',
        },
        genreId: {
          val: action.payload.genreId,
          err: false,
          hlp: '',
        },
        priceIn: {
          val: action.payload.priceIn,
          err: false,
          hlp: '',
        },
        priceOut: {
          val: action.payload.priceOut,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 名称 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 账户 值
    case 'setAccountId':
      return {
        ...state,
        accountId: {
          ...state.accountId,
          val: action.payload,
        },
      };
    /// 设置 分类 值
    case 'setGenreId':
      return {
        ...state,
        genreId: {
          ...state.genreId,
          val: action.payload,
        },
      };
    /// 设置 进价 值
    case 'setPriceIn':
      return {
        ...state,
        priceIn: {
          ...state.priceIn,
          val: action.payload,
        },
      };
    /// 设置 单价 值
    case 'setPriceOut':
      return {
        ...state,
        priceOut: {
          ...state.priceOut,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        accountId: {
          ...state.accountId,
          err: hasError(action.payload.accountId),
          hlp: getFirstErrMsg(action.payload.accountId),
        },
        genreId: {
          ...state.genreId,
          err: hasError(action.payload.genreId),
          hlp: getFirstErrMsg(action.payload.genreId),
        },
        priceIn: {
          ...state.priceIn,
          err: hasError(action.payload.priceIn),
          hlp: getFirstErrMsg(action.payload.priceIn),
        },
        priceOut: {
          ...state.priceOut,
          err: hasError(action.payload.priceOut),
          hlp: getFirstErrMsg(action.payload.priceOut),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        name: initField(state.name.val),
        accountId: initField(state.accountId.val),
        genreId: initField(state.genreId.val),
        priceIn: initField(state.priceIn.val),
        priceOut: initField(state.priceOut.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
