import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  phones: [validators.required()],
  signName: [validators.required()],
  templateCode: [validators.required()],
  content: [validators.required()],
});

export default validator;
