import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  studentId: [validators.gt(0)],
  telephone1: [validators.phone()],
  telephone2: [validators.phone()],
  telephone3: [validators.phone()],
});

export default validator;
