import Linear, { initLinear } from 'vts/models/core/Linear';

/// 文件
export default interface Archive extends Linear {
  /// 名称
  name: string;

  /// 路径
  path: string;
}

export const newArchive = {
  ...initLinear,
  name: '',
  path: '',
};
