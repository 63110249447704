import Score from '../../ses/models/Score';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 成绩 状态
export interface State extends Linear {
  /// 学生
  studentId: FormField<number | null>;

  /// 考试名称
  examId: FormField<number | null>;

  /// 课程
  courseId: FormField<number | null>;

  /// 成绩
  mark: FormField<number | null>;

  /// 考试时间
  examAt: FormField<string | null>;

  /// 分页
  page: Page<Score>;
}

/// 成绩 初始状态
const initState: State = {
  ...initLinear,
  studentId: initField(0),
  examId: initField(0),
  courseId: initField(0),
  mark: initField(0.0),
  examAt: initField(''),
  page: initPage<Score>(),
};

export default initState;
