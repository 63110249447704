import Task from '../../bpm/models/Task';
import { ROOT } from 'vts/consts/id';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 任务 状态
export interface State extends Linear {
  /// 流程
  processId: FormField<number | null>;

  /// 上一步任务ID
  fromId: FormField<number | null>;

  /// 上一步任务定义ID
  fromDefId: FormField<string | null>;

  /// 上一步办理意见
  fromOpinion: FormField<string | null>;

  /// 上上一步办理意见
  from2Opinion: FormField<string | null>;

  /// 任务定义ID
  defId: FormField<string | null>;

  /// 任务定义排序
  ordinal: FormField<number | null>;

  /// 名称
  name: FormField<string | null>;

  /// 待办人岗位
  postId: FormField<number | null>;

  /// 分页
  page: Page<Task>;
}

/// 任务 初始状态
const initState: State = {
  ...initLinear,
  processId: initField(0),
  fromId: initField(ROOT),
  fromDefId: initField('root'),
  fromOpinion: initField(''),
  from2Opinion: initField(''),
  defId: initField(''),
  ordinal: initField(0),
  name: initField(''),
  postId: initField(0),
  page: initPage<Task>(),
};

export default initState;
