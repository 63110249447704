import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  AppBar,
  Breadcrumbs,
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ArrowBackIos,
  Delete,
  Edit,
  Refresh,
  Save,
} from '@mui/icons-material';
import DelDialog from 'vts/components/dialogs/DelDialog';
import { DelHandle } from 'vts/interfaces/dialog';
import LinkRouter from 'vts/components/link/router';
import Nil from 'vts/components/table/Nil';
import Snack from 'vts/components/snacks/Snack';
import OpenSnack from 'vts/interfaces/snack';
import * as globals from 'vts/utils/globals';
import Nav from '../../Nav';
import Order, { newOrder } from '../../gsb/models/Order';
import initState from '../../gsb/states/order';
import reducer from '../../gsb/reducers/order';
import validator from '../../gsb/validators/order';
import { del, find, ins, upd } from '../../gsb/apis/order';

export default function OrderScreen() {
  const [_state, dispatch] = useReducer(reducer, initState);

  const [customerId, setCustomerId] = useState<number | null>(null);
  const [salesAtBegin, setSalesAtBegin] = useState('');
  const [salesAtEnd, setSalesAtEnd] = useState('');
  const [amountInBegin, setAmountInBegin] = useState<number | null>(null);
  const [amountInEnd, setAmountInEnd] = useState<number | null>(null);
  const [amountOutBegin, setAmountOutBegin] = useState<number | null>(null);
  const [amountOutEnd, setAmountOutEnd] = useState<number | null>(null);
  const [status, setStatus] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [isIns, setIsIns] = useState(false);

  const [snackContent, setSnackContent] = useState('保存成功');

  // 需要注意的是如果 ref 为元素，那么初始值得写个 null 才不会报错
  const snackRef = useRef<OpenSnack>(null);
  const delDialogRef = useRef<DelHandle>(null);

  const refresh = () => {
    find({
      customerId: customerId,
      salesAtBegin: salesAtBegin,
      salesAtEnd: salesAtEnd,
      amountInBegin: amountInBegin,
      amountInEnd: amountInEnd,
      amountOutBegin: amountOutBegin,
      amountOutEnd: amountOutEnd,
      status: status,
      page: page,
      perPage: perPage,
    }).then(function (resp) {
      dispatch({ type: 'found', payload: resp.data });
    });
  };

  useEffect(refresh, [customerId, salesAtBegin, salesAtEnd, amountInBegin, amountInEnd, amountOutBegin, amountOutEnd, status, page, perPage]);

  const openFormDialog = (model: Order, isIns: boolean) => {
    dispatch({ type: 'setModel', payload: model });
    setIsOpenFormDialog(true);
    setIsIns(isIns);
  };

  const closeFormDialog = () => setIsOpenFormDialog(false);

  const openDelDialog = (id: number, other: string) => delDialogRef.current?.open(id, other);

  const insFormDialog = () => openFormDialog(newOrder, true);

  const submit = () => {
    validator
      .validate({
        customerId: _state.customerId.val,
        salesAt: _state.salesAt.val,
        amountIn: _state.amountIn.val,
        amountOut: _state.amountOut.val,
        status: _state.status.val,
      })
      .then(() => {
        dispatch({ type: 'clearError' });
        let save = isIns ? ins : upd;
        save({
          id: _state.id.val,
          customerId: _state.customerId.val,
          salesAt: _state.salesAt.val,
          amountIn: _state.amountIn.val,
          amountOut: _state.amountOut.val,
          status: _state.status.val,
          memo: _state.memo.val,
        }).then(function (resp) {
          if (resp.data.success) {
            closeFormDialog();
            refresh();
            setSnackContent('保存成功');
            snackRef.current?.open();
          } else {
            setSnackContent(resp.data.head);
            snackRef.current?.open();
          }
        });
      })
      .catch(({ errors, fields }) => {
        dispatch({ type: 'validFail', payload: fields });
      });
  };

  /// 删除
  const onDel = (id: number, other: string) => {
    del({ id: id, other: other }).then(function (resp) {
      delDialogRef.current?.close();
      if (resp.data.success) {
        refresh();
        setSnackContent('删除成功');
        snackRef.current?.open();
      } else {
        setSnackContent(resp.data.head);
        snackRef.current?.open();
      }
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: 'setPage', payload: newPage + 1 });
    setPage(newPage + 1);
  };

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseInt(event.target.value, 10);
    dispatch({ type: 'setPerPage', payload: value });
    setPerPage(value);
  };

  return (
    <Fragment>
      <Nav />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
        px={2}
      >
        <Breadcrumbs>
          <LinkRouter to="/">首页</LinkRouter>
          <Typography color="textPrimary">订单</Typography>
        </Breadcrumbs>
        <Grid>
          <Tooltip title="新增">
            <IconButton onClick={insFormDialog}>
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title="刷新">
            <IconButton onClick={refresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <FormControl
        fullWidth={true}
        sx={{ mx: 1.5, my: 0.5, flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <TextField
          variant="outlined"
          size="small"
          label="客户"
          onChange={(e) => {
            setPage(1);
            setCustomerId(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="开始零售日期"
          onChange={(e) => {
            setPage(1);
            setSalesAtBegin(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="截止零售日期"
          onChange={(e) => {
            setPage(1);
            setSalesAtEnd(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="开始进货合计"
          onChange={(e) => {
            setPage(1);
            setAmountInBegin(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="截止进货合计"
          onChange={(e) => {
            setPage(1);
            setAmountInEnd(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="开始零售合计"
          onChange={(e) => {
            setPage(1);
            setAmountOutBegin(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="截止零售合计"
          onChange={(e) => {
            setPage(1);
            setAmountOutEnd(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="状态"
          onChange={(e) => {
            setPage(1);
            setStatus(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
      </FormControl>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={100}>序号</TableCell>
              <TableCell>客户</TableCell>
              <TableCell>零售日期</TableCell>
              <TableCell>进货合计</TableCell>
              <TableCell>零售合计</TableCell>
              <TableCell>状态</TableCell>
              <TableCell width={70}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_state.page.items?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  {globals.dictPvtVal('gsb_customer.id', row.customerId.toString())}
                </TableCell>
                <TableCell>{row.salesAt}</TableCell>
                <TableCell>{row.amountIn.toString()}</TableCell>
                <TableCell>{row.amountOut.toString()}</TableCell>
                <TableCell>
                  {globals.dictPubVal('gsb_order.status', row.status.toString())}
                </TableCell>
                <TableCell>
                  <Tooltip title="修改">
                    <IconButton
                      size="small"
                      onClick={() => openFormDialog(row, false)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="删除">
                    <IconButton
                      size="small"
                      onClick={(event) => openDelDialog(row.id, '')}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Nil colSpan={7} rowTotal={_state.page.total} />
        </Table>
        <TablePagination
          component="div"
          count={_state.page.total}
          page={_state.page.page - 1}
          onPageChange={onPageChange}
          rowsPerPage={_state.page.perPage}
          onRowsPerPageChange={onPerPageChange}
          sx={{ mx: 2 }}
        />
      </TableContainer>
      {/* 表单编辑 */}
      <Dialog fullScreen open={isOpenFormDialog} onClose={closeFormDialog}>
        <AppBar elevation={0}>
          <Toolbar>
            <Tooltip title="返回">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <ArrowBackIos />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              {isIns ? '订单添加' : '订单修改'}
            </Typography>
            <Tooltip title="保存">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <Save />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <FormControl sx={{ mx: 2, mt: 9, mb: 2 }}>
          <TextField
            fullWidth
            variant="standard"
            label="客户"
            type="number"
            value={_state.customerId.val}
            error={_state.customerId.err}
            helperText={_state.customerId.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setCustomerId',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="零售日期"
            type="string"
            value={_state.salesAt.val}
            error={_state.salesAt.err}
            helperText={_state.salesAt.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setSalesAt',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="进货合计"
            type="number"
            value={_state.amountIn.val}
            error={_state.amountIn.err}
            helperText={_state.amountIn.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setAmountIn',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="零售合计"
            type="number"
            value={_state.amountOut.val}
            error={_state.amountOut.err}
            helperText={_state.amountOut.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setAmountOut',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="状态"
            type="number"
            value={_state.status.val}
            error={_state.status.err}
            helperText={_state.status.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setStatus',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="备注"
            type="string"
            value={_state.memo.val}
            error={_state.memo.err}
            helperText={_state.memo.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setMemo',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <span style={{ marginTop: 20 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeFormDialog}
            >
              返回
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={submit}
              sx={{ ml: 2 }}
            >
              保存
            </Button>
          </span>
        </FormControl>
      </Dialog>
      <Snack ref={snackRef} content={snackContent} />
      <DelDialog ref={delDialogRef} onOk={onDel} />
    </Fragment>
  );
}
