import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  platform: [validators.gt(0)],
  genre: [validators.gt(0)],
  content: [validators.required(), validators.maxlen(1500)],
});

export default validator;
