import Linear, { initLinear } from 'vts/models/core/Linear';

/// 短信
export default interface Sms extends Linear {
  /// 手机号列表
  phones: string;

  /// 短信签名
  signName: string;

  /// 模板编号
  templateCode: string;

  /// 模板变量值
  templateParams: string;

  /// 短信内容
  content: string;

  /// 发送回执ID
  bizId: string;

  /// 类型
  genre: number;

  /// 状态
  status: number;

  /// 其他
  other: string;
}

export const newSms = {
  ...initLinear,
  phones: '',
  signName: '',
  templateCode: '',
  templateParams: '',
  content: '',
  bizId: '',
  genre: 0,
  status: 0,
  other: '',
};
