import Page from 'vts/models/core/Page';
import Resp from 'vts/models/core/Resp';
import axios, { AxiosResponse } from 'vts/utils/axios';
import Brand from '../../gpc/models/Brand';

// 查询
export async function find(params?: any) {
  return await axios.post<any, AxiosResponse<Page<Brand>>>(
    '/gpc/brand/find',
    params
  );
}

// 添加
export async function ins(params?: any) {
  return await axios.post<any, AxiosResponse<Brand>>(
    '/gpc/brand/ins',
    params
  );
}

// 删除
export async function del(params?: any) {
  return await axios.post<any, AxiosResponse<Resp>>(
    '/gpc/brand/del',
    params
  );
}

// 修改
export async function upd(params?: any) {
  return await axios.post<any, AxiosResponse<Brand>>(
    '/gpc/brand/upd',
    params
  );
}

// 查看
export async function see(params?: any) {
  return await axios.post<any, AxiosResponse<Brand>>(
    '/gpc/brand/see',
    params
  );
}
