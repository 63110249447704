import Account from '../../gsb/models/Account';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 账户 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 利润
  profit: FormField<number | null>;

  /// 余额
  balance: FormField<number | null>;

  /// 充值总额
  recharge: FormField<number | null>;

  /// 库存总额
  stock: FormField<number | null>;

  /// 分页
  page: Page<Account>;
}

/// 账户 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  profit: initField(0.0),
  balance: initField(0.0),
  recharge: initField(0),
  stock: initField(0.0),
  page: initPage<Account>(),
};

export default initState;
