import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  genre: [validators.required()],
  descr: [validators.required()],
  mkey: [validators.required()],
  mval: [validators.required()],
  ordinal: [validators.required()],
});

export default validator;
