import { State } from '../../bpm/states/task';
import Action from '../../bpm/actions/task';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        processId: {
          val: action.payload.processId,
          err: false,
          hlp: '',
        },
        fromId: {
          val: action.payload.fromId,
          err: false,
          hlp: '',
        },
        fromDefId: {
          val: action.payload.fromDefId,
          err: false,
          hlp: '',
        },
        fromOpinion: {
          val: action.payload.fromOpinion,
          err: false,
          hlp: '',
        },
        from2Opinion: {
          val: action.payload.from2Opinion,
          err: false,
          hlp: '',
        },
        defId: {
          val: action.payload.defId,
          err: false,
          hlp: '',
        },
        ordinal: {
          val: action.payload.ordinal,
          err: false,
          hlp: '',
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        postId: {
          val: action.payload.postId,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 流程 值
    case 'setProcessId':
      return {
        ...state,
        processId: {
          ...state.processId,
          val: action.payload,
        },
      };
    /// 设置 上一步任务ID 值
    case 'setFromId':
      return {
        ...state,
        fromId: {
          ...state.fromId,
          val: action.payload,
        },
      };
    /// 设置 上一步任务定义ID 值
    case 'setFromDefId':
      return {
        ...state,
        fromDefId: {
          ...state.fromDefId,
          val: action.payload,
        },
      };
    /// 设置 上一步办理意见 值
    case 'setFromOpinion':
      return {
        ...state,
        fromOpinion: {
          ...state.fromOpinion,
          val: action.payload,
        },
      };
    /// 设置 上上一步办理意见 值
    case 'setFrom2Opinion':
      return {
        ...state,
        from2Opinion: {
          ...state.from2Opinion,
          val: action.payload,
        },
      };
    /// 设置 任务定义ID 值
    case 'setDefId':
      return {
        ...state,
        defId: {
          ...state.defId,
          val: action.payload,
        },
      };
    /// 设置 任务定义排序 值
    case 'setOrdinal':
      return {
        ...state,
        ordinal: {
          ...state.ordinal,
          val: action.payload,
        },
      };
    /// 设置 名称 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 待办人岗位 值
    case 'setPostId':
      return {
        ...state,
        postId: {
          ...state.postId,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        processId: {
          ...state.processId,
          err: hasError(action.payload.processId),
          hlp: getFirstErrMsg(action.payload.processId),
        },
        fromId: {
          ...state.fromId,
          err: hasError(action.payload.fromId),
          hlp: getFirstErrMsg(action.payload.fromId),
        },
        fromDefId: {
          ...state.fromDefId,
          err: hasError(action.payload.fromDefId),
          hlp: getFirstErrMsg(action.payload.fromDefId),
        },
        fromOpinion: {
          ...state.fromOpinion,
          err: hasError(action.payload.fromOpinion),
          hlp: getFirstErrMsg(action.payload.fromOpinion),
        },
        from2Opinion: {
          ...state.from2Opinion,
          err: hasError(action.payload.from2Opinion),
          hlp: getFirstErrMsg(action.payload.from2Opinion),
        },
        defId: {
          ...state.defId,
          err: hasError(action.payload.defId),
          hlp: getFirstErrMsg(action.payload.defId),
        },
        ordinal: {
          ...state.ordinal,
          err: hasError(action.payload.ordinal),
          hlp: getFirstErrMsg(action.payload.ordinal),
        },
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        postId: {
          ...state.postId,
          err: hasError(action.payload.postId),
          hlp: getFirstErrMsg(action.payload.postId),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        processId: initField(state.processId.val),
        fromId: initField(state.fromId.val),
        fromDefId: initField(state.fromDefId.val),
        fromOpinion: initField(state.fromOpinion.val),
        from2Opinion: initField(state.from2Opinion.val),
        defId: initField(state.defId.val),
        ordinal: initField(state.ordinal.val),
        name: initField(state.name.val),
        postId: initField(state.postId.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
