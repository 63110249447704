import Linear, { initLinear } from 'vts/models/core/Linear';

export const GENRE_FRONTEND = 10; // 前台
export const GENRE_BACKEND = 20; // 后台

export const CLASSIFY_FUNC = 10; // 功能权限
export const CLASSIFY_DATA = 20; // 数据权限

/// 岗位
export default interface Post extends Linear {
  /// 名称
  name: string;

  /// 管理员
  isAdmin: boolean;

  /// 类型
  genre: number;

  /// 分类
  classify: number;

  /// 排序
  ordinal: number;
}

export const newPost = {
  ...initLinear,
  name: '',
  isAdmin: false,
  genre: 0,
  classify: 0,
  ordinal: 0,
};
