import Linear, { initLinear } from 'vts/models/core/Linear';

/// 账户
export default interface Account extends Linear {
  /// 用户
  userId: number;

  /// 余额
  balance: number;

  /// 余额单位
  unit: string;

  /// 充值总金额
  recharge: number;

  /// 可用
  usable: boolean;

  /// 是否启用授权码
  authcode: boolean;
}

export const newAccount = {
  ...initLinear,
  userId: 0,
  balance: 0.0,
  unit: '元',
  recharge: 0,
  usable: true,
  authcode: false,
};
