import UserPost from '../../sys/models/UserPost';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 用户岗位 状态
export interface State extends Linear {
  /// 用户
  userId: FormField<number | null>;

  /// 岗位
  postId: FormField<number | null>;

  /// 是否是默认
  isDefault: FormField<boolean | null>;

  /// 分页
  page: Page<UserPost>;
}

/// 用户岗位 初始状态
const initState: State = {
  ...initLinear,
  userId: initField(0),
  postId: initField(0),
  isDefault: initField(false),
  page: initPage<UserPost>(),
};

export default initState;
