import Linear, { initLinear } from 'vts/models/core/Linear';

/// 通讯录
export default interface Contact extends Linear {
  /// 学生
  studentId: number;

  /// 住址
  address: string;

  /// 家长电话1
  telephone1: string;

  /// 家长电话2
  telephone2: string;

  /// 学生电话
  telephone3: string;
}

export const newContact = {
  ...initLinear,
  studentId: 0,
  address: '',
  telephone1: '',
  telephone2: '',
  telephone3: '',
};
