import Linear, { initLinear } from 'vts/models/core/Linear';

export const STATUS_DEFAULT = 10; // 准备中
export const STATUS_ONLINE = 20; // 热销中
export const STATUS_OFFLINE = 30; // 已结束

/// 活动
export default interface Activity extends Linear {
  /// 名称
  name: string;

  /// 描述
  descr: string;

  /// 起始时间
  beginAt: string;

  /// 截止时间
  endAt: string;

  /// 状态
  status: number;
}

export const newActivity = {
  ...initLinear,
  name: '',
  descr: '',
  beginAt: '',
  endAt: '',
  status: 0,
};
