import { State } from '../../ses/states/score';
import Action from '../../ses/actions/score';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        studentId: {
          val: action.payload.studentId,
          err: false,
          hlp: '',
        },
        examId: {
          val: action.payload.examId,
          err: false,
          hlp: '',
        },
        courseId: {
          val: action.payload.courseId,
          err: false,
          hlp: '',
        },
        mark: {
          val: action.payload.mark,
          err: false,
          hlp: '',
        },
        examAt: {
          val: action.payload.examAt,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 学生 值
    case 'setStudentId':
      return {
        ...state,
        studentId: {
          ...state.studentId,
          val: action.payload,
        },
      };
    /// 设置 考试名称 值
    case 'setExamId':
      return {
        ...state,
        examId: {
          ...state.examId,
          val: action.payload,
        },
      };
    /// 设置 课程 值
    case 'setCourseId':
      return {
        ...state,
        courseId: {
          ...state.courseId,
          val: action.payload,
        },
      };
    /// 设置 成绩 值
    case 'setMark':
      return {
        ...state,
        mark: {
          ...state.mark,
          val: action.payload,
        },
      };
    /// 设置 考试时间 值
    case 'setExamAt':
      return {
        ...state,
        examAt: {
          ...state.examAt,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        studentId: {
          ...state.studentId,
          err: hasError(action.payload.studentId),
          hlp: getFirstErrMsg(action.payload.studentId),
        },
        examId: {
          ...state.examId,
          err: hasError(action.payload.examId),
          hlp: getFirstErrMsg(action.payload.examId),
        },
        courseId: {
          ...state.courseId,
          err: hasError(action.payload.courseId),
          hlp: getFirstErrMsg(action.payload.courseId),
        },
        mark: {
          ...state.mark,
          err: hasError(action.payload.mark),
          hlp: getFirstErrMsg(action.payload.mark),
        },
        examAt: {
          ...state.examAt,
          err: hasError(action.payload.examAt),
          hlp: getFirstErrMsg(action.payload.examAt),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        studentId: initField(state.studentId.val),
        examId: initField(state.examId.val),
        courseId: initField(state.courseId.val),
        mark: initField(state.mark.val),
        examAt: initField(state.examAt.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
