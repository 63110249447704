import Product from '../../gsb/models/Product';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 商品 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 账户
  accountId: FormField<number | null>;

  /// 分类
  genreId: FormField<number | null>;

  /// 进价
  priceIn: FormField<number | null>;

  /// 单价
  priceOut: FormField<number | null>;

  /// 分页
  page: Page<Product>;
}

/// 商品 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  accountId: initField(0),
  genreId: initField(0),
  priceIn: initField(0.0),
  priceOut: initField(0.0),
  page: initPage<Product>(),
};

export default initState;
