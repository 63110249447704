import Record from '../../mcb/models/Record';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 消费流水 状态
export interface State extends Linear {
  /// 用户
  userId: FormField<number | null>;

  /// 账户
  accountId: FormField<number | null>;

  /// 摘要
  summary: FormField<string | null>;

  /// 余额
  balance: FormField<number | null>;

  /// 余额单位
  unit: FormField<string | null>;

  /// 时间
  recordAt: FormField<string | null>;

  /// 状态
  status: FormField<number | null>;

  /// 分页
  page: Page<Record>;
}

/// 消费流水 初始状态
const initState: State = {
  ...initLinear,
  userId: initField(0),
  accountId: initField(0),
  summary: initField(''),
  balance: initField(0.0),
  unit: initField(''),
  recordAt: initField(''),
  status: initField(10),
  page: initPage<Record>(),
};

export default initState;
