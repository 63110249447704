import Linear, { initLinear } from 'vts/models/core/Linear';

/// 个人考核
export default interface Mark extends Linear {
  /// 评分对象
  toId: number;

  /// 考核项目
  itemId: number;

  /// 扣分分数
  score: number;

  /// 扣分原因
  cause: string;
}

export const newMark = {
  ...initLinear,
  toId: 0,
  itemId: 0,
  score: 0,
  cause: '',
};
