import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  processId: [validators.gt(0)],
  toId: [validators.gt(0)],
  score: [validators.required()],
  cause: [validators.required()],
  divisor: [validators.required(), validators.min(1)],
  average: [validators.required()],
});

export default validator;
