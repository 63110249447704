import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';
import * as h from './organ.h';

const validator = new AsyncValidator({
  name: [validators.required(), validators.minlen(4)],
  areaId: [validators.gt(0)],
  nickname: [validators.required(), validators.rangelen(4, 6), validators.h(h._hasNickname())],
  intro: [validators.required()],
  phone: [validators.telephone()],
  tel: [validators.telephone()],
  status: [validators.gt(0)],
  genre: [validators.gt(0)],
});

export default validator;
