import Uses from '../../gpc/models/Uses';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 编制类型 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 简称
  nickname: FormField<string | null>;

  /// 排序
  ordinal: FormField<number | null>;

  /// 分页
  page: Page<Uses>;
}

/// 编制类型 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  nickname: initField(''),
  ordinal: initField(0),
  page: initPage<Uses>(),
};

export default initState;
