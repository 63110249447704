import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  orderId: [validators.gt(0)],
  customerId: [validators.gt(0)],
  accountId: [validators.gt(0)],
  addressId: [validators.gt(0)],
  logistics: [validators.gt(0)],
  freight: [validators.required()],
  isBalance: [validators.required()],
  status: [validators.gt(0)],
});

export default validator;
