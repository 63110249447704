import Grade from '../../kpi/models/Grade';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 部门考核 状态
export interface State extends Linear {
  /// 流程
  processId: FormField<number | null>;

  /// 评分对象
  toId: FormField<number | null>;

  /// 扣分分数
  score: FormField<number | null>;

  /// 扣分原因
  cause: FormField<string | null>;

  /// 平均分除数
  divisor: FormField<number | null>;

  /// 平均分
  average: FormField<number | null>;

  /// 分页
  page: Page<Grade>;
}

/// 部门考核 初始状态
const initState: State = {
  ...initLinear,
  processId: initField(0),
  toId: initField(0),
  score: initField(0.0),
  cause: initField(''),
  divisor: initField(1),
  average: initField(0.0),
  page: initPage<Grade>(),
};

export default initState;
