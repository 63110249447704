import Res from '../../sys/models/Res';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Tree, { initTree } from 'vts/states/core/tree';

/// 资源 状态
export interface State extends Tree {
  /// 链接
  href: FormField<string | null>;

  /// 目标
  target: FormField<string | null>;

  /// 图标
  icon: FormField<string | null>;

  /// 隐藏
  hidden: FormField<boolean | null>;

  /// 权限
  permission: FormField<string | null>;

  /// 分组
  grp: FormField<string | null>;

  /// 资源类型
  genre: FormField<number | null>;

  /// 分页
  page: Page<Res>;
}

/// 资源 初始状态
const initState: State = {
  ...initTree,
  href: initField(''),
  target: initField(''),
  icon: initField(''),
  hidden: initField(false),
  permission: initField(''),
  grp: initField(''),
  genre: initField(0),
  page: initPage<Res>(),
};

export default initState;
