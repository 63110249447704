import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  processId: [validators.gt(0)],
  fromId: [validators.gt(0)],
  fromDefId: [validators.required()],
  fromOpinion: [validators.required()],
  from2Opinion: [validators.required()],
  defId: [validators.required()],
  ordinal: [validators.required()],
  name: [validators.required()],
  postId: [validators.gt(0)],
});

export default validator;
