import Linear, { initLinear } from 'vts/models/core/Linear';

/// 充值
export default interface Recharge extends Linear {
  /// 账户
  accountId: number;

  /// 金额
  amount: number;

  /// 充值日期
  rechargeAt: string;
}

export const newRecharge = {
  ...initLinear,
  accountId: 0,
  amount: 0,
  rechargeAt: '',
};
