import Linear, { initLinear } from 'vts/models/core/Linear';

/// 岗位菜单
export default interface PostMenu extends Linear {
  /// 岗位
  postId: number;

  /// 菜单
  menuId: number;
}

export const newPostMenu = {
  ...initLinear,
  postId: 0,
  menuId: 0,
};
