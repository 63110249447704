import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required()],
  genre: [validators.required()],
  usable: [validators.required()],
});

export default validator;
