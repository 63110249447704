import Linear, { initLinear } from 'vts/models/core/Linear';

/// 流程
export default interface Process extends Linear {
  /// 过程
  defId: string;

  /// 名称
  name: string;

  /// 部门
  deptId: number;

  /// 岗位
  postId: number;

  /// 进度
  taskId: string;

  /// 排序
  ordinal: number;
}

export const newProcess = {
  ...initLinear,
  defId: '',
  name: '',
  deptId: 0,
  postId: 0,
  taskId: '',
  ordinal: 0,
};
