import Linear, { initLinear } from 'vts/models/core/Linear';

/// 客户地址
export default interface Address extends Linear {
  /// 客户
  customerId: number;

  /// 地址
  customerAddr: string;

  /// 默认地址
  isDefault: boolean;
}

export const newAddress = {
  ...initLinear,
  customerId: 0,
  customerAddr: '',
  isDefault: false,
};
