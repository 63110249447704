import Linear, { initLinear } from 'vts/models/core/Linear';

export const STATUS_WAIT = 10; // 待审
export const STATUS_PASS = 20; // 同意
export const STATUS_BACK = 30; // 拒绝

/// 我的申请
export default interface Apply extends Linear {
  /// 学生
  studentId: number;

  /// 申请描述
  remark: string;

  /// 申请状态
  status: number;
}

export const newApply = {
  ...initLinear,
  studentId: 0,
  remark: '',
  status: 0,
};
