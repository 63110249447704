import { Fragment, useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  SvgIcon,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { ExitToApp, Menu, ImportExport } from '@mui/icons-material';
import axios, { AxiosResponse } from 'vts/utils/axios';
import Resp from 'vts/models/core/Resp';
import Auth from 'vts/models/core/Auth';
import initAuthState from 'vts/states/core/auth';
import { removeAuth as removeLocalAuth } from 'vts/utils/auths';
import {
  getAuth,
  isNotAuth,
  isAdminOrSa,
  getNavTitle,
} from 'vts/utils/globals';
import { getRevision } from 'vts/utils/revisions';
import menus from './menus';

const sx = {
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
};

export default function Nav() {
  const [open, setOpen] = useState(false);

  const [navTitle, setNavTitle] = useState('');

  const [auth, setAuth] = useState<Auth>(initAuthState);
  const [adminOrSa, setAdminOrSa] = useState(false);
  const [rev, setRev] = useState(0);

  const navigate = useNavigate();

  const onOpen = useCallback(() => setOpen(true), []);

  useEffect(() => {
    // 先通过全局变量（内存中）获取登录信息（获取快）
    // 如果状态无法获取登录信息，比如刷新了页面是无法获取的
    // 再从 localStorage 获取登录信息
    // 如果还无法获取，就跳转到登录页
    if (isNotAuth()) {
      navigate('/login?r=' + rev);
    }
    setNavTitle(getNavTitle());
  }, [navigate, rev]);

  useEffect(() => {
    setAuth(getAuth());
    setAdminOrSa(isAdminOrSa());
    setRev(getRevision());
  }, []);

  const toggleDrawer =
    (isOpen: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpen(isOpen);
    };

  const logout = () => {
    axios.post<any, AxiosResponse<Resp>>('/auth/logout').then(function () {
      removeLocalAuth();
      navigate('/login?r=' + rev);
    });
  };

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <AppBar elevation={0}>
        <Toolbar>
          <Tooltip title="导航菜单">
            <IconButton
              onClick={onOpen}
              edge="start"
              sx={{ marginRight: 2 }}
              color="inherit"
            >
              <Menu />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" align="left" sx={{ flexGrow: 1 }}>
            {process.env.REACT_APP_TITLE}
            {navTitle}
          </Typography>
          <Link to={'/?r=' + rev} style={sx.link}>
            <Button color="inherit">{auth.name}</Button>
          </Link>
          {adminOrSa ? (
            <Tooltip title="机构切换">
              <IconButton color="inherit" onClick={() => navigate('/swap')}>
                <ImportExport />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
          <Tooltip title="退出">
            <IconButton color="inherit" onClick={logout}>
              <ExitToApp />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {menus.map((menu, index) =>
          menu.userGenre >= auth.genre &&
          ((adminOrSa && menu.organGenre === 0) ||
            auth.organGenre === menu.organGenre) ? (
            <Fragment key={index}>
              <List
                dense
                subheader={<ListSubheader>{menu.title}</ListSubheader>}
              >
                {menu.children.map((item, idx) =>
                  item.userGenre >= auth.genre ? (
                    <ListItem button key={idx}>
                      <ListItemIcon>
                        <SvgIcon component={item.icon} />
                      </ListItemIcon>
                      <Link to={item.path + '?r=' + rev} style={sx.link}>
                        <ListItemText
                          primary={item.title}
                          sx={{ minWidth: 120 }}
                        />
                      </Link>
                    </ListItem>
                  ) : (
                    <Fragment key={idx}></Fragment>
                  )
                )}
              </List>
              <Divider />
            </Fragment>
          ) : (
            <Fragment key={index}></Fragment>
          )
        )}
      </Drawer>
    </Stack>
  );
}
