import Linear, { initLinear } from 'vts/models/core/Linear';

export const PLATFORM_ADM = 20; // 管理后台
export const PLATFORM_WFG = 30; // WEB前台
export const PLATFORM_MNA = 40; // 小程序
export const PLATFORM_APP = 50; // APP

/// 用户轨迹
export default interface Traces extends Linear {
  /// 使用平台
  platform: number;

  /// User-Agent
  userAgent: string;

  /// 请求地址
  reqUrl: string;

  /// 请求IP
  reqIp: string;
}

export const newTraces = {
  ...initLinear,
  platform: 50,
  userAgent: '',
  reqUrl: '',
  reqIp: '',
};
