import User from '../../sys/models/User';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 用户 状态
export interface State extends Linear {
  /// 区域名称
  areaName: FormField<string | null>;

  /// 第三方用户标识
  openId: FormField<string | null>;

  /// 是否启用OpenID
  isOpenId: FormField<boolean | null>;

  /// 姓名
  name: FormField<string | null>;

  /// 用户名
  username: FormField<string | null>;

  /// 密码
  passwd: FormField<string | null>;

  /// 昵称
  nickname: FormField<string | null>;

  /// 使用平台
  platform: FormField<number | null>;

  /// 类型
  genre: FormField<number | null>;

  /// 邮件
  email: FormField<string | null>;

  /// 手机
  phone: FormField<string | null>;

  /// 头像
  avatar: FormField<string | null>;

  /// 性别
  sex: FormField<number | null>;

  /// QQ
  qq: FormField<string | null>;

  /// 二维码
  qrcode: FormField<string | null>;

  /// 是否冻结
  froze: FormField<boolean | null>;

  /// 冻结时间
  frozeAt: FormField<string | null>;

  /// 是否登录过
  isLogin: FormField<boolean | null>;

  /// 首次登录时间
  loginAt: FormField<string | null>;

  /// 来源
  sourceId: FormField<number | null>;

  /// 历史
  history: FormField<boolean | null>;

  /// 分页
  page: Page<User>;
}

/// 用户 初始状态
const initState: State = {
  ...initLinear,
  areaName: initField(''),
  openId: initField(''),
  isOpenId: initField(false),
  name: initField(''),
  username: initField(''),
  passwd: initField(''),
  nickname: initField(''),
  platform: initField(20),
  genre: initField(40),
  email: initField(''),
  phone: initField(''),
  avatar: initField(''),
  sex: initField(10),
  qq: initField(''),
  qrcode: initField(''),
  froze: initField(false),
  frozeAt: initField(''),
  isLogin: initField(false),
  loginAt: initField(''),
  sourceId: initField(0),
  history: initField(false),
  page: initPage<User>(),
};

export default initState;
