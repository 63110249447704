import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  organId: [validators.gt(0)],
  organName: [validators.required()],
  userId: [validators.gt(0)],
  userName: [validators.required()],
  content: [validators.required()],
  status: [validators.gt(0)],
});

export default validator;
