import Linear, { initLinear } from 'vts/models/core/Linear';

/// 字典
export default interface Dict extends Linear {
  /// 类型
  genre: string;

  /// 描述
  descr: string;

  /// 键
  mkey: number;

  /// 值
  mval: string;

  /// 过滤
  filters: string;

  /// 排序
  ordinal: number;
}

export const newDict = {
  ...initLinear,
  genre: '',
  descr: '',
  mkey: 0,
  mval: '',
  filters: '',
  ordinal: 0,
};
