import Linear, { initLinear } from 'vts/models/core/Linear';

/// 黑名单
export default interface Blacklist extends Linear {
  /// 名称
  name: string;

  /// 类型
  genre: number;

  /// 可用
  usable: boolean;
}

export const newBlacklist = {
  ...initLinear,
  name: '',
  genre: 0,
  usable: false,
};
