import { State } from '../../ses/states/contact';
import Action from '../../ses/actions/contact';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        studentId: {
          val: action.payload.studentId,
          err: false,
          hlp: '',
        },
        address: {
          val: action.payload.address,
          err: false,
          hlp: '',
        },
        telephone1: {
          val: action.payload.telephone1,
          err: false,
          hlp: '',
        },
        telephone2: {
          val: action.payload.telephone2,
          err: false,
          hlp: '',
        },
        telephone3: {
          val: action.payload.telephone3,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 学生 值
    case 'setStudentId':
      return {
        ...state,
        studentId: {
          ...state.studentId,
          val: action.payload,
        },
      };
    /// 设置 住址 值
    case 'setAddress':
      return {
        ...state,
        address: {
          ...state.address,
          val: action.payload,
        },
      };
    /// 设置 家长电话1 值
    case 'setTelephone1':
      return {
        ...state,
        telephone1: {
          ...state.telephone1,
          val: action.payload,
        },
      };
    /// 设置 家长电话2 值
    case 'setTelephone2':
      return {
        ...state,
        telephone2: {
          ...state.telephone2,
          val: action.payload,
        },
      };
    /// 设置 学生电话 值
    case 'setTelephone3':
      return {
        ...state,
        telephone3: {
          ...state.telephone3,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        studentId: {
          ...state.studentId,
          err: hasError(action.payload.studentId),
          hlp: getFirstErrMsg(action.payload.studentId),
        },
        address: {
          ...state.address,
          err: hasError(action.payload.address),
          hlp: getFirstErrMsg(action.payload.address),
        },
        telephone1: {
          ...state.telephone1,
          err: hasError(action.payload.telephone1),
          hlp: getFirstErrMsg(action.payload.telephone1),
        },
        telephone2: {
          ...state.telephone2,
          err: hasError(action.payload.telephone2),
          hlp: getFirstErrMsg(action.payload.telephone2),
        },
        telephone3: {
          ...state.telephone3,
          err: hasError(action.payload.telephone3),
          hlp: getFirstErrMsg(action.payload.telephone3),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        studentId: initField(state.studentId.val),
        address: initField(state.address.val),
        telephone1: initField(state.telephone1.val),
        telephone2: initField(state.telephone2.val),
        telephone3: initField(state.telephone3.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
