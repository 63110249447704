import Car from '../../gpc/models/Car';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 车辆 状态
export interface State extends Linear {
  /// 单位性质
  nature: FormField<number | null>;

  /// 单位
  govId: FormField<number | null>;

  /// 单位
  govName: FormField<string | null>;

  /// 品牌
  brandId: FormField<number | null>;

  /// 品牌
  brandName: FormField<string | null>;

  /// 车型
  modelId: FormField<number | null>;

  /// 车型
  modelName: FormField<string | null>;

  /// 车牌号
  sn: FormField<string | null>;

  /// 编制类型
  usesId: FormField<number | null>;

  /// 编制类型
  usesName: FormField<string | null>;

  /// 分页
  page: Page<Car>;
}

/// 车辆 初始状态
const initState: State = {
  ...initLinear,
  nature: initField(10),
  govId: initField(10),
  govName: initField(''),
  brandId: initField(10),
  brandName: initField(''),
  modelId: initField(10),
  modelName: initField(''),
  sn: initField(''),
  usesId: initField(10),
  usesName: initField(''),
  page: initPage<Car>(),
};

export default initState;
