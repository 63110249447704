import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required()],
  accountId: [validators.gt(0)],
  genreId: [validators.gt(0)],
  priceIn: [validators.required()],
  priceOut: [validators.required()],
});

export default validator;
