import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required(), validators.maxlen(10)],
  telephone: [validators.required(), validators.telephone()],
  work: [validators.maxlen(30)],
  residence: [validators.maxlen(30)],
  family: [validators.maxlen(30)],
});

export default validator;
