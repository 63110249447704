import Contact from '../../cmp/models/Contact';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 通讯录 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 性别
  sex: FormField<number | null>;

  /// 生日
  birthday: FormField<string | null>;

  /// 电话
  telephone: FormField<string | null>;

  /// 工作单位
  work: FormField<string | null>;

  /// 现居住地
  residence: FormField<string | null>;

  /// 家庭地址
  family: FormField<string | null>;

  /// 分页
  page: Page<Contact>;
}

/// 通讯录 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  sex: initField(0),
  birthday: initField(''),
  telephone: initField(''),
  work: initField(''),
  residence: initField(''),
  family: initField(''),
  page: initPage<Contact>(),
};

export default initState;
