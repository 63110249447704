import { State } from '../../sys/states/organ';
import Action from '../../sys/actions/organ';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        parentId: {
          val: action.payload.parentId,
          err: false,
          hlp: '',
        },
        ordinal: {
          val: action.payload.ordinal,
          err: false,
          hlp: '',
        },
        areaId: {
          val: action.payload.areaId,
          err: false,
          hlp: '',
        },
        nickname: {
          val: action.payload.nickname,
          err: false,
          hlp: '',
        },
        intro: {
          val: action.payload.intro,
          err: false,
          hlp: '',
        },
        city: {
          val: action.payload.city,
          err: false,
          hlp: '',
        },
        address: {
          val: action.payload.address,
          err: false,
          hlp: '',
        },
        phone: {
          val: action.payload.phone,
          err: false,
          hlp: '',
        },
        tel: {
          val: action.payload.tel,
          err: false,
          hlp: '',
        },
        startAt: {
          val: action.payload.startAt,
          err: false,
          hlp: '',
        },
        status: {
          val: action.payload.status,
          err: false,
          hlp: '',
        },
        genre: {
          val: action.payload.genre,
          err: false,
          hlp: '',
        },
        shifts: {
          val: action.payload.shifts,
          err: false,
          hlp: '',
        },
        qrcode: {
          val: action.payload.qrcode,
          err: false,
          hlp: '',
        },
        isPub: {
          val: action.payload.isPub,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 名称 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 父标识 值
    case 'setParentId':
      return {
        ...state,
        parentId: {
          ...state.parentId,
          val: action.payload,
        },
      };
    /// 设置 排序 值
    case 'setOrdinal':
      return {
        ...state,
        ordinal: {
          ...state.ordinal,
          val: action.payload,
        },
      };
    /// 设置 区域 值
    case 'setAreaId':
      return {
        ...state,
        areaId: {
          ...state.areaId,
          val: action.payload,
        },
      };
    /// 设置 昵称 值
    case 'setNickname':
      return {
        ...state,
        nickname: {
          ...state.nickname,
          val: action.payload,
        },
      };
    /// 设置 简介 值
    case 'setIntro':
      return {
        ...state,
        intro: {
          ...state.intro,
          val: action.payload,
        },
      };
    /// 设置 城市 值
    case 'setCity':
      return {
        ...state,
        city: {
          ...state.city,
          val: action.payload,
        },
      };
    /// 设置 地址 值
    case 'setAddress':
      return {
        ...state,
        address: {
          ...state.address,
          val: action.payload,
        },
      };
    /// 设置 手机 值
    case 'setPhone':
      return {
        ...state,
        phone: {
          ...state.phone,
          val: action.payload,
        },
      };
    /// 设置 固话 值
    case 'setTel':
      return {
        ...state,
        tel: {
          ...state.tel,
          val: action.payload,
        },
      };
    /// 设置 开业时间 值
    case 'setStartAt':
      return {
        ...state,
        startAt: {
          ...state.startAt,
          val: action.payload,
        },
      };
    /// 设置 状态 值
    case 'setStatus':
      return {
        ...state,
        status: {
          ...state.status,
          val: action.payload,
        },
      };
    /// 设置 类型 值
    case 'setGenre':
      return {
        ...state,
        genre: {
          ...state.genre,
          val: action.payload,
        },
      };
    /// 设置 上班制 值
    case 'setShifts':
      return {
        ...state,
        shifts: {
          ...state.shifts,
          val: action.payload,
        },
      };
    /// 设置 二维码 值
    case 'setQrcode':
      return {
        ...state,
        qrcode: {
          ...state.qrcode,
          val: action.payload,
        },
      };
    /// 设置 允许所有用户搜索到本机构 值
    case 'setIsPub':
      return {
        ...state,
        isPub: {
          ...state.isPub,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        parentId: {
          ...state.parentId,
          err: hasError(action.payload.parentId),
          hlp: getFirstErrMsg(action.payload.parentId),
        },
        ordinal: {
          ...state.ordinal,
          err: hasError(action.payload.ordinal),
          hlp: getFirstErrMsg(action.payload.ordinal),
        },
        areaId: {
          ...state.areaId,
          err: hasError(action.payload.areaId),
          hlp: getFirstErrMsg(action.payload.areaId),
        },
        nickname: {
          ...state.nickname,
          err: hasError(action.payload.nickname),
          hlp: getFirstErrMsg(action.payload.nickname),
        },
        intro: {
          ...state.intro,
          err: hasError(action.payload.intro),
          hlp: getFirstErrMsg(action.payload.intro),
        },
        city: {
          ...state.city,
          err: hasError(action.payload.city),
          hlp: getFirstErrMsg(action.payload.city),
        },
        address: {
          ...state.address,
          err: hasError(action.payload.address),
          hlp: getFirstErrMsg(action.payload.address),
        },
        phone: {
          ...state.phone,
          err: hasError(action.payload.phone),
          hlp: getFirstErrMsg(action.payload.phone),
        },
        tel: {
          ...state.tel,
          err: hasError(action.payload.tel),
          hlp: getFirstErrMsg(action.payload.tel),
        },
        startAt: {
          ...state.startAt,
          err: hasError(action.payload.startAt),
          hlp: getFirstErrMsg(action.payload.startAt),
        },
        status: {
          ...state.status,
          err: hasError(action.payload.status),
          hlp: getFirstErrMsg(action.payload.status),
        },
        genre: {
          ...state.genre,
          err: hasError(action.payload.genre),
          hlp: getFirstErrMsg(action.payload.genre),
        },
        shifts: {
          ...state.shifts,
          err: hasError(action.payload.shifts),
          hlp: getFirstErrMsg(action.payload.shifts),
        },
        qrcode: {
          ...state.qrcode,
          err: hasError(action.payload.qrcode),
          hlp: getFirstErrMsg(action.payload.qrcode),
        },
        isPub: {
          ...state.isPub,
          err: hasError(action.payload.isPub),
          hlp: getFirstErrMsg(action.payload.isPub),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        name: initField(state.name.val),
        parentId: initField(state.parentId.val),
        ordinal: initField(state.ordinal.val),
        areaId: initField(state.areaId.val),
        nickname: initField(state.nickname.val),
        intro: initField(state.intro.val),
        city: initField(state.city.val),
        address: initField(state.address.val),
        phone: initField(state.phone.val),
        tel: initField(state.tel.val),
        startAt: initField(state.startAt.val),
        status: initField(state.status.val),
        genre: initField(state.genre.val),
        shifts: initField(state.shifts.val),
        qrcode: initField(state.qrcode.val),
        isPub: initField(state.isPub.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
