import { State } from '../../cmp/states/contact';
import Action from '../../cmp/actions/contact';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        sex: {
          val: action.payload.sex,
          err: false,
          hlp: '',
        },
        birthday: {
          val: action.payload.birthday,
          err: false,
          hlp: '',
        },
        telephone: {
          val: action.payload.telephone,
          err: false,
          hlp: '',
        },
        work: {
          val: action.payload.work,
          err: false,
          hlp: '',
        },
        residence: {
          val: action.payload.residence,
          err: false,
          hlp: '',
        },
        family: {
          val: action.payload.family,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 名称 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 性别 值
    case 'setSex':
      return {
        ...state,
        sex: {
          ...state.sex,
          val: action.payload,
        },
      };
    /// 设置 生日 值
    case 'setBirthday':
      return {
        ...state,
        birthday: {
          ...state.birthday,
          val: action.payload,
        },
      };
    /// 设置 电话 值
    case 'setTelephone':
      return {
        ...state,
        telephone: {
          ...state.telephone,
          val: action.payload,
        },
      };
    /// 设置 工作单位 值
    case 'setWork':
      return {
        ...state,
        work: {
          ...state.work,
          val: action.payload,
        },
      };
    /// 设置 现居住地 值
    case 'setResidence':
      return {
        ...state,
        residence: {
          ...state.residence,
          val: action.payload,
        },
      };
    /// 设置 家庭地址 值
    case 'setFamily':
      return {
        ...state,
        family: {
          ...state.family,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        sex: {
          ...state.sex,
          err: hasError(action.payload.sex),
          hlp: getFirstErrMsg(action.payload.sex),
        },
        birthday: {
          ...state.birthday,
          err: hasError(action.payload.birthday),
          hlp: getFirstErrMsg(action.payload.birthday),
        },
        telephone: {
          ...state.telephone,
          err: hasError(action.payload.telephone),
          hlp: getFirstErrMsg(action.payload.telephone),
        },
        work: {
          ...state.work,
          err: hasError(action.payload.work),
          hlp: getFirstErrMsg(action.payload.work),
        },
        residence: {
          ...state.residence,
          err: hasError(action.payload.residence),
          hlp: getFirstErrMsg(action.payload.residence),
        },
        family: {
          ...state.family,
          err: hasError(action.payload.family),
          hlp: getFirstErrMsg(action.payload.family),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        name: initField(state.name.val),
        sex: initField(state.sex.val),
        birthday: initField(state.birthday.val),
        telephone: initField(state.telephone.val),
        work: initField(state.work.val),
        residence: initField(state.residence.val),
        family: initField(state.family.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
