import { State } from '../../cmn/states/msg';
import Action from '../../cmn/actions/msg';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        organId: {
          val: action.payload.organId,
          err: false,
          hlp: '',
        },
        organName: {
          val: action.payload.organName,
          err: false,
          hlp: '',
        },
        userId: {
          val: action.payload.userId,
          err: false,
          hlp: '',
        },
        userName: {
          val: action.payload.userName,
          err: false,
          hlp: '',
        },
        content: {
          val: action.payload.content,
          err: false,
          hlp: '',
        },
        status: {
          val: action.payload.status,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 机构 值
    case 'setOrganId':
      return {
        ...state,
        organId: {
          ...state.organId,
          val: action.payload,
        },
      };
    /// 设置 机构名称 值
    case 'setOrganName':
      return {
        ...state,
        organName: {
          ...state.organName,
          val: action.payload,
        },
      };
    /// 设置 用户 值
    case 'setUserId':
      return {
        ...state,
        userId: {
          ...state.userId,
          val: action.payload,
        },
      };
    /// 设置 用户名 值
    case 'setUserName':
      return {
        ...state,
        userName: {
          ...state.userName,
          val: action.payload,
        },
      };
    /// 设置 内容 值
    case 'setContent':
      return {
        ...state,
        content: {
          ...state.content,
          val: action.payload,
        },
      };
    /// 设置 状态 值
    case 'setStatus':
      return {
        ...state,
        status: {
          ...state.status,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        organId: {
          ...state.organId,
          err: hasError(action.payload.organId),
          hlp: getFirstErrMsg(action.payload.organId),
        },
        organName: {
          ...state.organName,
          err: hasError(action.payload.organName),
          hlp: getFirstErrMsg(action.payload.organName),
        },
        userId: {
          ...state.userId,
          err: hasError(action.payload.userId),
          hlp: getFirstErrMsg(action.payload.userId),
        },
        userName: {
          ...state.userName,
          err: hasError(action.payload.userName),
          hlp: getFirstErrMsg(action.payload.userName),
        },
        content: {
          ...state.content,
          err: hasError(action.payload.content),
          hlp: getFirstErrMsg(action.payload.content),
        },
        status: {
          ...state.status,
          err: hasError(action.payload.status),
          hlp: getFirstErrMsg(action.payload.status),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        organId: initField(state.organId.val),
        organName: initField(state.organName.val),
        userId: initField(state.userId.val),
        userName: initField(state.userName.val),
        content: initField(state.content.val),
        status: initField(state.status.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
