import Linear, { initLinear } from 'vts/models/core/Linear';

/// 用户部门
export default interface UserDept extends Linear {
  /// 用户
  userId: number;

  /// 部门
  deptId: number;

  /// 是否是默认
  isDefault: boolean;
}

export const newUserDept = {
  ...initLinear,
  userId: 0,
  deptId: 0,
  isDefault: false,
};
