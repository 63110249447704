import { ROOT } from 'vts/consts/id';
import Linear, { initLinear } from 'vts/models/core/Linear';

export const STATUS_DOIN = 10; // 待办
export const STATUS_DONE = 20; // 已办

/// 任务部门
export default interface TaskDept extends Linear {
  /// 上一步部门任务ID
  fromId: number;

  /// 上一步待办部门ID
  fromDeptId: number;

  /// 父任务ID
  parentId: number;

  /// 任务
  taskId: number;

  /// 部门
  deptId: number;

  /// 当前步意见
  opinion: string;

  /// 操作
  operate: string;

  /// 状态
  status: number;
}

export const newTaskDept = {
  ...initLinear,
  fromId: ROOT,
  fromDeptId: ROOT,
  parentId: ROOT,
  taskId: 0,
  deptId: 0,
  opinion: '',
  operate: '',
  status: 10,
};
