import Traces from '../../sys/models/Traces';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 用户轨迹 状态
export interface State extends Linear {
  /// 使用平台
  platform: FormField<number | null>;

  /// User-Agent
  userAgent: FormField<string | null>;

  /// 请求地址
  reqUrl: FormField<string | null>;

  /// 请求IP
  reqIp: FormField<string | null>;

  /// 分页
  page: Page<Traces>;
}

/// 用户轨迹 初始状态
const initState: State = {
  ...initLinear,
  platform: initField(50),
  userAgent: initField(''),
  reqUrl: initField(''),
  reqIp: initField(''),
  page: initPage<Traces>(),
};

export default initState;
