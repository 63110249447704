import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  orderId: [validators.gt(0)],
  customerId: [validators.gt(0)],
  payment: [validators.gt(0)],
  status: [validators.gt(0)],
});

export default validator;
