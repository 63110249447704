import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required(), validators.maxlen(20)],
  descr: [validators.required()],
  beginAt: [validators.required()],
  endAt: [validators.required()],
  status: [validators.gt(0)],
});

export default validator;
