import Linear, { initLinear } from 'vts/models/core/Linear';

/// 充值
export default interface Recharge extends Linear {
  /// 用户
  userId: number;

  /// 账户
  accountId: number;

  /// 账户余额
  balance: number;

  /// 余额单位
  unit: string;

  /// 金额
  amount: number;

  /// 活动
  activityId: number;
}

export const newRecharge = {
  ...initLinear,
  userId: 0,
  accountId: 0,
  balance: 0,
  unit: '',
  amount: 0,
  activityId: 0,
};
