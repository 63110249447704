import { State } from '../../ses/states/student';
import Action from '../../ses/actions/student';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        sid: {
          val: action.payload.sid,
          err: false,
          hlp: '',
        },
        enrolAt: {
          val: action.payload.enrolAt,
          err: false,
          hlp: '',
        },
        sex: {
          val: action.payload.sex,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 姓名 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 学号 值
    case 'setSid':
      return {
        ...state,
        sid: {
          ...state.sid,
          val: action.payload,
        },
      };
    /// 设置 入学时间 值
    case 'setEnrolAt':
      return {
        ...state,
        enrolAt: {
          ...state.enrolAt,
          val: action.payload,
        },
      };
    /// 设置 性别 值
    case 'setSex':
      return {
        ...state,
        sex: {
          ...state.sex,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        sid: {
          ...state.sid,
          err: hasError(action.payload.sid),
          hlp: getFirstErrMsg(action.payload.sid),
        },
        enrolAt: {
          ...state.enrolAt,
          err: hasError(action.payload.enrolAt),
          hlp: getFirstErrMsg(action.payload.enrolAt),
        },
        sex: {
          ...state.sex,
          err: hasError(action.payload.sex),
          hlp: getFirstErrMsg(action.payload.sex),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        name: initField(state.name.val),
        sid: initField(state.sid.val),
        enrolAt: initField(state.enrolAt.val),
        sex: initField(state.sex.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
