import Cart from '../../gsb/models/Cart';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 购物车 状态
export interface State extends Linear {
  /// 订单号
  orderId: FormField<number | null>;

  /// 客户
  customerId: FormField<number | null>;

  /// 账户
  accountId: FormField<number | null>;

  /// 商品
  productId: FormField<number | null>;

  /// 商品
  goodsId: FormField<number | null>;

  /// 数量
  quantity: FormField<number | null>;

  /// 进价
  priceIn: FormField<number | null>;

  /// 单价
  priceOut: FormField<number | null>;

  /// 进货小计
  amountIn: FormField<number | null>;

  /// 零售小计
  amountOut: FormField<number | null>;

  /// 状态
  status: FormField<number | null>;

  /// 分页
  page: Page<Cart>;
}

/// 购物车 初始状态
const initState: State = {
  ...initLinear,
  orderId: initField(0),
  customerId: initField(0),
  accountId: initField(0),
  productId: initField(0),
  goodsId: initField(0),
  quantity: initField(0.0),
  priceIn: initField(0.0),
  priceOut: initField(0.0),
  amountIn: initField(0.0),
  amountOut: initField(0.0),
  status: initField(20),
  page: initPage<Cart>(),
};

export default initState;
