import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required()],
  ordinal: [validators.gt(0)],
  genre: [validators.gt(0)],
  phone: [validators.phone()],
  tel: [validators.tel()],
  work: [validators.maxlen(50)],
  department: [validators.maxlen(30)],
  position: [validators.maxlen(30)],
  office: [validators.maxlen(20)],
  email: [validators.maxlen(100), validators.email()],
});

export default validator;
