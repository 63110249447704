import Goods from '../../gsb/models/Goods';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 库存商品 状态
export interface State extends Linear {
  /// 账户
  accountId: FormField<number | null>;

  /// 分类
  genreId: FormField<number | null>;

  /// 商品
  productId: FormField<number | null>;

  /// 业务ID
  sourceId: FormField<number | null>;

  /// 进价
  priceIn: FormField<number | null>;

  /// 单价
  priceOut: FormField<number | null>;

  /// 库存数量
  inventory: FormField<number | null>;

  /// 状态
  status: FormField<number | null>;

  /// 分页
  page: Page<Goods>;
}

/// 库存商品 初始状态
const initState: State = {
  ...initLinear,
  accountId: initField(0),
  genreId: initField(0),
  productId: initField(0),
  sourceId: initField(0),
  priceIn: initField(0.0),
  priceOut: initField(0.0),
  inventory: initField(0.0),
  status: initField(10),
  page: initPage<Goods>(),
};

export default initState;
