import { State } from '../../sys/states/dict';
import Action from '../../sys/actions/dict';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        genre: {
          val: action.payload.genre,
          err: false,
          hlp: '',
        },
        descr: {
          val: action.payload.descr,
          err: false,
          hlp: '',
        },
        mkey: {
          val: action.payload.mkey,
          err: false,
          hlp: '',
        },
        mval: {
          val: action.payload.mval,
          err: false,
          hlp: '',
        },
        filters: {
          val: action.payload.filters,
          err: false,
          hlp: '',
        },
        ordinal: {
          val: action.payload.ordinal,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 类型 值
    case 'setGenre':
      return {
        ...state,
        genre: {
          ...state.genre,
          val: action.payload,
        },
      };
    /// 设置 描述 值
    case 'setDescr':
      return {
        ...state,
        descr: {
          ...state.descr,
          val: action.payload,
        },
      };
    /// 设置 键 值
    case 'setMkey':
      return {
        ...state,
        mkey: {
          ...state.mkey,
          val: action.payload,
        },
      };
    /// 设置 值 值
    case 'setMval':
      return {
        ...state,
        mval: {
          ...state.mval,
          val: action.payload,
        },
      };
    /// 设置 过滤 值
    case 'setFilters':
      return {
        ...state,
        filters: {
          ...state.filters,
          val: action.payload,
        },
      };
    /// 设置 排序 值
    case 'setOrdinal':
      return {
        ...state,
        ordinal: {
          ...state.ordinal,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        genre: {
          ...state.genre,
          err: hasError(action.payload.genre),
          hlp: getFirstErrMsg(action.payload.genre),
        },
        descr: {
          ...state.descr,
          err: hasError(action.payload.descr),
          hlp: getFirstErrMsg(action.payload.descr),
        },
        mkey: {
          ...state.mkey,
          err: hasError(action.payload.mkey),
          hlp: getFirstErrMsg(action.payload.mkey),
        },
        mval: {
          ...state.mval,
          err: hasError(action.payload.mval),
          hlp: getFirstErrMsg(action.payload.mval),
        },
        filters: {
          ...state.filters,
          err: hasError(action.payload.filters),
          hlp: getFirstErrMsg(action.payload.filters),
        },
        ordinal: {
          ...state.ordinal,
          err: hasError(action.payload.ordinal),
          hlp: getFirstErrMsg(action.payload.ordinal),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        genre: initField(state.genre.val),
        descr: initField(state.descr.val),
        mkey: initField(state.mkey.val),
        mval: initField(state.mval.val),
        filters: initField(state.filters.val),
        ordinal: initField(state.ordinal.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
