import { State } from '../../gsb/states/account';
import Action from '../../gsb/actions/account';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        profit: {
          val: action.payload.profit,
          err: false,
          hlp: '',
        },
        balance: {
          val: action.payload.balance,
          err: false,
          hlp: '',
        },
        recharge: {
          val: action.payload.recharge,
          err: false,
          hlp: '',
        },
        stock: {
          val: action.payload.stock,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 名称 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 利润 值
    case 'setProfit':
      return {
        ...state,
        profit: {
          ...state.profit,
          val: action.payload,
        },
      };
    /// 设置 余额 值
    case 'setBalance':
      return {
        ...state,
        balance: {
          ...state.balance,
          val: action.payload,
        },
      };
    /// 设置 充值总额 值
    case 'setRecharge':
      return {
        ...state,
        recharge: {
          ...state.recharge,
          val: action.payload,
        },
      };
    /// 设置 库存总额 值
    case 'setStock':
      return {
        ...state,
        stock: {
          ...state.stock,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        profit: {
          ...state.profit,
          err: hasError(action.payload.profit),
          hlp: getFirstErrMsg(action.payload.profit),
        },
        balance: {
          ...state.balance,
          err: hasError(action.payload.balance),
          hlp: getFirstErrMsg(action.payload.balance),
        },
        recharge: {
          ...state.recharge,
          err: hasError(action.payload.recharge),
          hlp: getFirstErrMsg(action.payload.recharge),
        },
        stock: {
          ...state.stock,
          err: hasError(action.payload.stock),
          hlp: getFirstErrMsg(action.payload.stock),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        name: initField(state.name.val),
        profit: initField(state.profit.val),
        balance: initField(state.balance.val),
        recharge: initField(state.recharge.val),
        stock: initField(state.stock.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
