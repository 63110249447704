import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  platform: [validators.gt(0)],
  userAgent: [validators.required()],
  reqUrl: [validators.required()],
});

export default validator;
