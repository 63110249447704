import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  customerId: [validators.gt(0)],
  customerAddr: [validators.required()],
  isDefault: [validators.required()],
});

export default validator;
