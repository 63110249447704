import Linear, { initLinear } from 'vts/models/core/Linear';

/// 课程
export default interface Course extends Linear {
  /// 名称
  name: string;
}

export const newCourse = {
  ...initLinear,
  name: '',
};
