import Address from '../../gsb/models/Address';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 客户地址 状态
export interface State extends Linear {
  /// 客户
  customerId: FormField<number | null>;

  /// 地址
  customerAddr: FormField<string | null>;

  /// 默认地址
  isDefault: FormField<boolean | null>;

  /// 分页
  page: Page<Address>;
}

/// 客户地址 初始状态
const initState: State = {
  ...initLinear,
  customerId: initField(0),
  customerAddr: initField(''),
  isDefault: initField(false),
  page: initPage<Address>(),
};

export default initState;
