import Mark from '../../kpi/models/Mark';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 个人考核 状态
export interface State extends Linear {
  /// 评分对象
  toId: FormField<number | null>;

  /// 考核项目
  itemId: FormField<number | null>;

  /// 扣分分数
  score: FormField<number | null>;

  /// 扣分原因
  cause: FormField<string | null>;

  /// 分页
  page: Page<Mark>;
}

/// 个人考核 初始状态
const initState: State = {
  ...initLinear,
  toId: initField(0),
  itemId: initField(0),
  score: initField(0),
  cause: initField(''),
  page: initPage<Mark>(),
};

export default initState;
