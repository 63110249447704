import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  name: [validators.required()],
  sid: [validators.required()],
  enrolAt: [validators.required()],
  sex: [validators.gt(0)],
});

export default validator;
