import Linear, { initLinear } from 'vts/models/core/Linear';

/// 账户
export default interface Account extends Linear {
  /// 名称
  name: string;

  /// 利润
  profit: number;

  /// 余额
  balance: number;

  /// 充值总额
  recharge: number;

  /// 库存总额
  stock: number;
}

export const newAccount = {
  ...initLinear,
  name: '',
  profit: 0.0,
  balance: 0.0,
  recharge: 0,
  stock: 0.0,
};
