import { State } from '../../sys/states/user';
import Action from '../../sys/actions/user';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        areaName: {
          val: action.payload.areaName,
          err: false,
          hlp: '',
        },
        openId: {
          val: action.payload.openId,
          err: false,
          hlp: '',
        },
        isOpenId: {
          val: action.payload.isOpenId,
          err: false,
          hlp: '',
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        username: {
          val: action.payload.username,
          err: false,
          hlp: '',
        },
        passwd: {
          val: action.payload.passwd,
          err: false,
          hlp: '',
        },
        nickname: {
          val: action.payload.nickname,
          err: false,
          hlp: '',
        },
        platform: {
          val: action.payload.platform,
          err: false,
          hlp: '',
        },
        genre: {
          val: action.payload.genre,
          err: false,
          hlp: '',
        },
        email: {
          val: action.payload.email,
          err: false,
          hlp: '',
        },
        phone: {
          val: action.payload.phone,
          err: false,
          hlp: '',
        },
        avatar: {
          val: action.payload.avatar,
          err: false,
          hlp: '',
        },
        sex: {
          val: action.payload.sex,
          err: false,
          hlp: '',
        },
        qq: {
          val: action.payload.qq,
          err: false,
          hlp: '',
        },
        qrcode: {
          val: action.payload.qrcode,
          err: false,
          hlp: '',
        },
        froze: {
          val: action.payload.froze,
          err: false,
          hlp: '',
        },
        frozeAt: {
          val: action.payload.frozeAt,
          err: false,
          hlp: '',
        },
        isLogin: {
          val: action.payload.isLogin,
          err: false,
          hlp: '',
        },
        loginAt: {
          val: action.payload.loginAt,
          err: false,
          hlp: '',
        },
        sourceId: {
          val: action.payload.sourceId,
          err: false,
          hlp: '',
        },
        history: {
          val: action.payload.history,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 区域名称 值
    case 'setAreaName':
      return {
        ...state,
        areaName: {
          ...state.areaName,
          val: action.payload,
        },
      };
    /// 设置 第三方用户标识 值
    case 'setOpenId':
      return {
        ...state,
        openId: {
          ...state.openId,
          val: action.payload,
        },
      };
    /// 设置 是否启用OpenID 值
    case 'setIsOpenId':
      return {
        ...state,
        isOpenId: {
          ...state.isOpenId,
          val: action.payload,
        },
      };
    /// 设置 姓名 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 用户名 值
    case 'setUsername':
      return {
        ...state,
        username: {
          ...state.username,
          val: action.payload,
        },
      };
    /// 设置 密码 值
    case 'setPasswd':
      return {
        ...state,
        passwd: {
          ...state.passwd,
          val: action.payload,
        },
      };
    /// 设置 昵称 值
    case 'setNickname':
      return {
        ...state,
        nickname: {
          ...state.nickname,
          val: action.payload,
        },
      };
    /// 设置 使用平台 值
    case 'setPlatform':
      return {
        ...state,
        platform: {
          ...state.platform,
          val: action.payload,
        },
      };
    /// 设置 类型 值
    case 'setGenre':
      return {
        ...state,
        genre: {
          ...state.genre,
          val: action.payload,
        },
      };
    /// 设置 邮件 值
    case 'setEmail':
      return {
        ...state,
        email: {
          ...state.email,
          val: action.payload,
        },
      };
    /// 设置 手机 值
    case 'setPhone':
      return {
        ...state,
        phone: {
          ...state.phone,
          val: action.payload,
        },
      };
    /// 设置 头像 值
    case 'setAvatar':
      return {
        ...state,
        avatar: {
          ...state.avatar,
          val: action.payload,
        },
      };
    /// 设置 性别 值
    case 'setSex':
      return {
        ...state,
        sex: {
          ...state.sex,
          val: action.payload,
        },
      };
    /// 设置 QQ 值
    case 'setQq':
      return {
        ...state,
        qq: {
          ...state.qq,
          val: action.payload,
        },
      };
    /// 设置 二维码 值
    case 'setQrcode':
      return {
        ...state,
        qrcode: {
          ...state.qrcode,
          val: action.payload,
        },
      };
    /// 设置 是否冻结 值
    case 'setFroze':
      return {
        ...state,
        froze: {
          ...state.froze,
          val: action.payload,
        },
      };
    /// 设置 冻结时间 值
    case 'setFrozeAt':
      return {
        ...state,
        frozeAt: {
          ...state.frozeAt,
          val: action.payload,
        },
      };
    /// 设置 是否登录过 值
    case 'setIsLogin':
      return {
        ...state,
        isLogin: {
          ...state.isLogin,
          val: action.payload,
        },
      };
    /// 设置 首次登录时间 值
    case 'setLoginAt':
      return {
        ...state,
        loginAt: {
          ...state.loginAt,
          val: action.payload,
        },
      };
    /// 设置 来源 值
    case 'setSourceId':
      return {
        ...state,
        sourceId: {
          ...state.sourceId,
          val: action.payload,
        },
      };
    /// 设置 历史 值
    case 'setHistory':
      return {
        ...state,
        history: {
          ...state.history,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        areaName: {
          ...state.areaName,
          err: hasError(action.payload.areaName),
          hlp: getFirstErrMsg(action.payload.areaName),
        },
        openId: {
          ...state.openId,
          err: hasError(action.payload.openId),
          hlp: getFirstErrMsg(action.payload.openId),
        },
        isOpenId: {
          ...state.isOpenId,
          err: hasError(action.payload.isOpenId),
          hlp: getFirstErrMsg(action.payload.isOpenId),
        },
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        username: {
          ...state.username,
          err: hasError(action.payload.username),
          hlp: getFirstErrMsg(action.payload.username),
        },
        passwd: {
          ...state.passwd,
          err: hasError(action.payload.passwd),
          hlp: getFirstErrMsg(action.payload.passwd),
        },
        nickname: {
          ...state.nickname,
          err: hasError(action.payload.nickname),
          hlp: getFirstErrMsg(action.payload.nickname),
        },
        platform: {
          ...state.platform,
          err: hasError(action.payload.platform),
          hlp: getFirstErrMsg(action.payload.platform),
        },
        genre: {
          ...state.genre,
          err: hasError(action.payload.genre),
          hlp: getFirstErrMsg(action.payload.genre),
        },
        email: {
          ...state.email,
          err: hasError(action.payload.email),
          hlp: getFirstErrMsg(action.payload.email),
        },
        phone: {
          ...state.phone,
          err: hasError(action.payload.phone),
          hlp: getFirstErrMsg(action.payload.phone),
        },
        avatar: {
          ...state.avatar,
          err: hasError(action.payload.avatar),
          hlp: getFirstErrMsg(action.payload.avatar),
        },
        sex: {
          ...state.sex,
          err: hasError(action.payload.sex),
          hlp: getFirstErrMsg(action.payload.sex),
        },
        qq: {
          ...state.qq,
          err: hasError(action.payload.qq),
          hlp: getFirstErrMsg(action.payload.qq),
        },
        qrcode: {
          ...state.qrcode,
          err: hasError(action.payload.qrcode),
          hlp: getFirstErrMsg(action.payload.qrcode),
        },
        froze: {
          ...state.froze,
          err: hasError(action.payload.froze),
          hlp: getFirstErrMsg(action.payload.froze),
        },
        frozeAt: {
          ...state.frozeAt,
          err: hasError(action.payload.frozeAt),
          hlp: getFirstErrMsg(action.payload.frozeAt),
        },
        isLogin: {
          ...state.isLogin,
          err: hasError(action.payload.isLogin),
          hlp: getFirstErrMsg(action.payload.isLogin),
        },
        loginAt: {
          ...state.loginAt,
          err: hasError(action.payload.loginAt),
          hlp: getFirstErrMsg(action.payload.loginAt),
        },
        sourceId: {
          ...state.sourceId,
          err: hasError(action.payload.sourceId),
          hlp: getFirstErrMsg(action.payload.sourceId),
        },
        history: {
          ...state.history,
          err: hasError(action.payload.history),
          hlp: getFirstErrMsg(action.payload.history),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        areaName: initField(state.areaName.val),
        openId: initField(state.openId.val),
        isOpenId: initField(state.isOpenId.val),
        name: initField(state.name.val),
        username: initField(state.username.val),
        passwd: initField(state.passwd.val),
        nickname: initField(state.nickname.val),
        platform: initField(state.platform.val),
        genre: initField(state.genre.val),
        email: initField(state.email.val),
        phone: initField(state.phone.val),
        avatar: initField(state.avatar.val),
        sex: initField(state.sex.val),
        qq: initField(state.qq.val),
        qrcode: initField(state.qrcode.val),
        froze: initField(state.froze.val),
        frozeAt: initField(state.frozeAt.val),
        isLogin: initField(state.isLogin.val),
        loginAt: initField(state.loginAt.val),
        sourceId: initField(state.sourceId.val),
        history: initField(state.history.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
