import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  accountId: [validators.gt(0)],
  sourceId: [validators.gt(0)],
  sourceAt: [validators.required()],
  genre: [validators.gt(0)],
  pre: [validators.required()],
  post: [validators.required()],
});

export default validator;
