import Contact from '../../gpt/models/Contact';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Tree, { initTree } from 'vts/states/core/tree';

/// 通讯录 状态
export interface State extends Tree {
  /// 类型
  genre: FormField<number | null>;

  /// 手机
  phone: FormField<string | null>;

  /// 小号
  shortTel: FormField<string | null>;

  /// 固话
  tel: FormField<string | null>;

  /// 内线专线
  otherTel: FormField<string | null>;

  /// 单位
  workId: FormField<number | null>;

  /// 单位
  work: FormField<string | null>;

  /// 部门
  department: FormField<string | null>;

  /// 职务
  position: FormField<string | null>;

  /// 办公室
  office: FormField<string | null>;

  /// 邮件
  email: FormField<string | null>;

  /// 性别
  sex: FormField<number | null>;

  /// 生日
  birthday: FormField<string | null>;

  /// 地址
  address: FormField<string | null>;

  /// 分页
  page: Page<Contact>;
}

/// 通讯录 初始状态
const initState: State = {
  ...initTree,
  genre: initField(10),
  phone: initField(''),
  shortTel: initField(''),
  tel: initField(''),
  otherTel: initField(''),
  workId: initField(0),
  work: initField(''),
  department: initField(''),
  position: initField(''),
  office: initField(''),
  email: initField(''),
  sex: initField(0),
  birthday: initField(''),
  address: initField(''),
  page: initPage<Contact>(),
};

export default initState;
