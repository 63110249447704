import Linear, { initLinear } from 'vts/models/core/Linear';

export const STATE_FOUND = 10; // 创建
export const STATE_ADMIN = 20; // 管理
export const STATE_STAFF = 30; // 入职
export const STATE_BIND = 40; // 绑定
export const STATE_WATCH = 50; // 关注

/// 我的机构
export default interface My extends Linear {
  /// 机构
  organId: number;

  /// 机构名称
  organName: string;

  /// 机构类型
  organGenre: number;

  /// 用户
  userId: number;

  /// 昵称
  nickname: string;

  /// 手机
  phone: string;

  /// 邮件
  email: string;

  /// 是否允许该机构查看手机邮箱
  isPub: boolean;

  /// 是否启用机构切换功能
  isSwap: boolean;

  /// 是否是默认机构
  isDefault: boolean;

  /// 加入时间
  joinAt: string;

  /// 可用
  usable: boolean;

  /// 状态
  state: number;
}

export const newMy = {
  ...initLinear,
  organId: 0,
  organName: '',
  organGenre: 101010,
  userId: 0,
  nickname: '',
  phone: '',
  email: '',
  isPub: false,
  isSwap: true,
  isDefault: false,
  joinAt: '',
  usable: true,
  state: 0,
};
