import Linear, { initLinear } from 'vts/models/core/Linear';

/// 流程
export default interface Flw extends Linear {
  /// 来源ID
  srcId: number;

  /// 之前状态
  preStatus: number;

  /// 之后状态
  postStatus: number;
}

export const newFlw = {
  ...initLinear,
  srcId: 0,
  preStatus: 0,
  postStatus: 0,
};
