import Dept from '../../sys/models/Dept';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Tree, { initTree } from 'vts/states/core/tree';

/// 部门 状态
export interface State extends Tree {
  /// 配置
  confId: FormField<number | null>;

  /// 类型
  genre: FormField<number | null>;

  /// 过滤
  filters: FormField<string | null>;

  /// 分页
  page: Page<Dept>;
}

/// 部门 初始状态
const initState: State = {
  ...initTree,
  confId: initField(0),
  genre: initField(10),
  filters: initField(''),
  page: initPage<Dept>(),
};

export default initState;
