import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  srcId: [validators.gt(0)],
  preStatus: [validators.gt(0)],
  postStatus: [validators.gt(0)],
});

export default validator;
