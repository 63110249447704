import { State } from '../../bpm/states/task_dept';
import Action from '../../bpm/actions/task_dept';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        fromId: {
          val: action.payload.fromId,
          err: false,
          hlp: '',
        },
        fromDeptId: {
          val: action.payload.fromDeptId,
          err: false,
          hlp: '',
        },
        parentId: {
          val: action.payload.parentId,
          err: false,
          hlp: '',
        },
        taskId: {
          val: action.payload.taskId,
          err: false,
          hlp: '',
        },
        deptId: {
          val: action.payload.deptId,
          err: false,
          hlp: '',
        },
        opinion: {
          val: action.payload.opinion,
          err: false,
          hlp: '',
        },
        operate: {
          val: action.payload.operate,
          err: false,
          hlp: '',
        },
        status: {
          val: action.payload.status,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 上一步部门任务ID 值
    case 'setFromId':
      return {
        ...state,
        fromId: {
          ...state.fromId,
          val: action.payload,
        },
      };
    /// 设置 上一步待办部门ID 值
    case 'setFromDeptId':
      return {
        ...state,
        fromDeptId: {
          ...state.fromDeptId,
          val: action.payload,
        },
      };
    /// 设置 父任务ID 值
    case 'setParentId':
      return {
        ...state,
        parentId: {
          ...state.parentId,
          val: action.payload,
        },
      };
    /// 设置 任务 值
    case 'setTaskId':
      return {
        ...state,
        taskId: {
          ...state.taskId,
          val: action.payload,
        },
      };
    /// 设置 部门 值
    case 'setDeptId':
      return {
        ...state,
        deptId: {
          ...state.deptId,
          val: action.payload,
        },
      };
    /// 设置 当前步意见 值
    case 'setOpinion':
      return {
        ...state,
        opinion: {
          ...state.opinion,
          val: action.payload,
        },
      };
    /// 设置 操作 值
    case 'setOperate':
      return {
        ...state,
        operate: {
          ...state.operate,
          val: action.payload,
        },
      };
    /// 设置 状态 值
    case 'setStatus':
      return {
        ...state,
        status: {
          ...state.status,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        fromId: {
          ...state.fromId,
          err: hasError(action.payload.fromId),
          hlp: getFirstErrMsg(action.payload.fromId),
        },
        fromDeptId: {
          ...state.fromDeptId,
          err: hasError(action.payload.fromDeptId),
          hlp: getFirstErrMsg(action.payload.fromDeptId),
        },
        parentId: {
          ...state.parentId,
          err: hasError(action.payload.parentId),
          hlp: getFirstErrMsg(action.payload.parentId),
        },
        taskId: {
          ...state.taskId,
          err: hasError(action.payload.taskId),
          hlp: getFirstErrMsg(action.payload.taskId),
        },
        deptId: {
          ...state.deptId,
          err: hasError(action.payload.deptId),
          hlp: getFirstErrMsg(action.payload.deptId),
        },
        opinion: {
          ...state.opinion,
          err: hasError(action.payload.opinion),
          hlp: getFirstErrMsg(action.payload.opinion),
        },
        operate: {
          ...state.operate,
          err: hasError(action.payload.operate),
          hlp: getFirstErrMsg(action.payload.operate),
        },
        status: {
          ...state.status,
          err: hasError(action.payload.status),
          hlp: getFirstErrMsg(action.payload.status),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        fromId: initField(state.fromId.val),
        fromDeptId: initField(state.fromDeptId.val),
        parentId: initField(state.parentId.val),
        taskId: initField(state.taskId.val),
        deptId: initField(state.deptId.val),
        opinion: initField(state.opinion.val),
        operate: initField(state.operate.val),
        status: initField(state.status.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
