import Linear, { initLinear } from 'vts/models/core/Linear';

export const NATURE_XZDW = 10; // 行政单位
export const NATURE_SYDW = 20; // 事业单位
export const NATURE_QWZS = 30; // 旗委直属
export const NATURE_ZFZS = 40; // 政府直属

/// 车辆
export default interface Car extends Linear {
  /// 单位性质
  nature: number;

  /// 单位
  govId: number;

  /// 单位
  govName: string;

  /// 品牌
  brandId: number;

  /// 品牌
  brandName: string;

  /// 车型
  modelId: number;

  /// 车型
  modelName: string;

  /// 车牌号
  sn: string;

  /// 编制类型
  usesId: number;

  /// 编制类型
  usesName: string;
}

export const newCar = {
  ...initLinear,
  nature: 10,
  govId: 10,
  govName: '',
  brandId: 10,
  brandName: '',
  modelId: 10,
  modelName: '',
  sn: '',
  usesId: 10,
  usesName: '',
};
