import { State } from '../../gsb/states/payment';
import Action from '../../gsb/actions/payment';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        orderId: {
          val: action.payload.orderId,
          err: false,
          hlp: '',
        },
        customerId: {
          val: action.payload.customerId,
          err: false,
          hlp: '',
        },
        payment: {
          val: action.payload.payment,
          err: false,
          hlp: '',
        },
        status: {
          val: action.payload.status,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 订单号 值
    case 'setOrderId':
      return {
        ...state,
        orderId: {
          ...state.orderId,
          val: action.payload,
        },
      };
    /// 设置 客户 值
    case 'setCustomerId':
      return {
        ...state,
        customerId: {
          ...state.customerId,
          val: action.payload,
        },
      };
    /// 设置 支付方式 值
    case 'setPayment':
      return {
        ...state,
        payment: {
          ...state.payment,
          val: action.payload,
        },
      };
    /// 设置 状态 值
    case 'setStatus':
      return {
        ...state,
        status: {
          ...state.status,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        orderId: {
          ...state.orderId,
          err: hasError(action.payload.orderId),
          hlp: getFirstErrMsg(action.payload.orderId),
        },
        customerId: {
          ...state.customerId,
          err: hasError(action.payload.customerId),
          hlp: getFirstErrMsg(action.payload.customerId),
        },
        payment: {
          ...state.payment,
          err: hasError(action.payload.payment),
          hlp: getFirstErrMsg(action.payload.payment),
        },
        status: {
          ...state.status,
          err: hasError(action.payload.status),
          hlp: getFirstErrMsg(action.payload.status),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        orderId: initField(state.orderId.val),
        customerId: initField(state.customerId.val),
        payment: initField(state.payment.val),
        status: initField(state.status.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
