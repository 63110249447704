import Conf from '../../sys/models/Conf';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 配置 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 编号
  sn: FormField<string | null>;

  /// 内容
  content: FormField<string | null>;

  /// 配置值
  value: FormField<number | null>;

  /// 类型
  genre: FormField<number | null>;

  /// 可用
  usable: FormField<boolean | null>;

  /// 排序
  ordinal: FormField<number | null>;

  /// 分页
  page: Page<Conf>;
}

/// 配置 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  sn: initField(''),
  content: initField(''),
  value: initField(0),
  genre: initField(0),
  usable: initField(false),
  ordinal: initField(0),
  page: initPage<Conf>(),
};

export default initState;
