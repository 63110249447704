import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  accountId: [validators.gt(0)],
  genreId: [validators.gt(0)],
  productId: [validators.gt(0)],
  sourceId: [validators.gt(0)],
  priceIn: [validators.required()],
  priceOut: [validators.required()],
  status: [validators.gt(0)],
});

export default validator;
