import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  AppBar,
  Breadcrumbs,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ArrowBackIos,
  Delete,
  Edit,
  Login,
  Person,
  PersonAdd,
  Refresh,
  Save,
} from '@mui/icons-material';
import axios, { AxiosResponse } from 'vts/utils/axios';
import Resp from 'vts/models/core/Resp';
import { PLATFORM_APP } from 'vts/consts/auth';
import DelDialog from 'vts/components/dialogs/DelDialog';
import { DelHandle } from 'vts/interfaces/dialog';
import LinkRouter from 'vts/components/link/router';
import { ROOT } from 'vts/consts/id';
import LinkFn from 'vts/components/link/fn';
import {
  TreeBreadcrumbs,
  popTreeBreadcrumb,
  pushTreeBreadcrumb,
} from 'vts/utils/breadcrumbs';
import Nil from 'vts/components/table/Nil';
import Snack from 'vts/components/snacks/Snack';
import OpenSnack from 'vts/interfaces/snack';
import Nav from '../../Nav';
import Contact, {
  newContact,
  GENRE_WORK,
  GENRE_DEPT,
  GENRE_MBR,
} from '../../gpt/models/Contact';
import initState from '../../gpt/states/contact';
import reducer from '../../gpt/reducers/contact';
import validator from '../../gpt/validators/contact';
import { del, find, imp, ins, upd } from '../../gpt/apis/contact';

export default function ContactScreen() {
  const [_state, dispatch] = useReducer(reducer, initState);

  const [leaf, setLeaf] = useState(false);

  const [name, setName] = useState('');
  const [genre, setGenre] = useState(0);
  const [parentId, setParentId] = useState(ROOT);
  const [phone, setPhone] = useState('');
  const [tel, setTel] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);

  const [snackContent, setSnackContent] = useState('');

  const [fileList, setFileList] = useState<FileList | null>(null);

  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [isOpenImpDialog, setIsOpenImpDialog] = useState(false);
  const [isIns, setIsIns] = useState(false);
  const [treeBreadcrumbs, setTreeBreadcrumbs] = useState<TreeBreadcrumbs>({
    items: [],
  });

  // 需要注意的是如果 ref 为元素，那么初始值得写个 null 才不会报错
  const snackRef = useRef<OpenSnack>(null);
  const delDialogRef = useRef<DelHandle>(null);

  const refresh = () => {
    find({
      name: name,
      parentId: parentId,
      phone: phone,
      tel: tel,
      page: page,
      perPage: perPage,
    }).then(function (resp) {
      if (resp.data.items.length === 0) {
        setGenre(0);
        setLeaf(false);
      } else {
        if (resp.data.items[0].genre !== genre)
          setGenre(resp.data.items[0].genre);
        if (resp.data.items[0].genre === GENRE_MBR && !leaf) setLeaf(true);
      }
      dispatch({ type: 'found', payload: resp.data });
    });
  };

  // eslint-disable-next-line
  useEffect(refresh, [name, parentId, phone, tel, page, perPage]);

  const openFormDialog = (model: Contact, isIns: boolean, isMbr: boolean) => {
    if (isIns) {
      model.parentId = parentId;
      if (isMbr) {
        model.genre = GENRE_MBR;
        model.leaf = true;
      } else {
        if (parentId === ROOT) {
          model.genre = GENRE_WORK;
        } else {
          model.genre = GENRE_DEPT;
        }
      }
    }
    dispatch({ type: 'setModel', payload: model });
    setIsOpenFormDialog(true);
    setIsIns(isIns);
    setLeaf(isMbr);
  };

  const enableLogin = (model: Contact) => {
    if (model.name === null || model.name.trim() === '') {
      setSnackContent('请先输入姓名');
      snackRef.current?.open();
      return;
    }
    if (model.phone === null || model.phone.trim() === '') {
      setSnackContent('请先输入手机号');
      snackRef.current?.open();
      return;
    }
    if (model.email === null || model.email.trim() === '') {
      setSnackContent('请先输入邮箱');
      snackRef.current?.open();
      return;
    }
    axios
      .post<any, AxiosResponse<Resp>>('/auth/register', {
        name: model.name,
        username: model.phone,
        email: model.email,
        sourceId: model.id,
        passwd: 'user_reset',
        platform: PLATFORM_APP,
        captcha: '111111',
      })
      .then(function (resp) {
        if (resp.data.success) {
          setSnackContent('开启登录成功');
          snackRef.current?.open();
        } else {
          setSnackContent('已开启登录了');
          snackRef.current?.open();
        }
      });
  };

  const onCloseFormDialog = () => setIsOpenFormDialog(false);
  const onCloseImpDialog = () => setIsOpenImpDialog(false);
  const onChangeImp = (e: ChangeEvent<HTMLInputElement>) =>
    setFileList(e.target.files);

  const openImpDialog = () => setIsOpenImpDialog(true);
  const openDelDialog = (id: number, other: string) =>
    delDialogRef.current?.open(id, other);

  const insDeptFormDialog = () => openFormDialog(newContact, true, false);
  const insMbrFormDialog = () => openFormDialog(newContact, true, true);

  const displayWhenDept = () => (genre < GENRE_MBR ? '' : 'none');
  const displayWhenLeaf = () => (leaf ? '' : 'none');
  const displayWhenMbr = () =>
    genre === 0 || genre === GENRE_MBR ? '' : 'none';

  const onSubmitForm = () => {
    validator
      .validate({
        name: _state.name.val,
        ordinal: _state.ordinal.val,
        genre: _state.genre.val,
        phone: _state.phone.val,
        tel: _state.tel.val,
        work: _state.work.val,
        department: _state.department.val,
        position: _state.position.val,
        office: _state.office.val,
        email: _state.email.val,
      })
      .then(() => {
        dispatch({ type: 'clearError' });
        let save = isIns ? ins : upd;
        save({
          id: _state.id.val,
          name: _state.name.val,
          parentId: _state.parentId.val,
          ordinal: _state.ordinal.val,
          genre: _state.genre.val,
          phone: _state.phone.val,
          shortTel: _state.shortTel.val,
          tel: _state.tel.val,
          otherTel: _state.otherTel.val,
          workId: _state.workId.val,
          work: _state.work.val,
          department: _state.department.val,
          position: _state.position.val,
          office: _state.office.val,
          email: _state.email.val,
          sex: _state.sex.val,
          birthday: _state.birthday.val,
          address: _state.address.val,
          memo: _state.memo.val,
        }).then(function (resp) {
          if (resp.data.success) {
            onCloseFormDialog();
            refresh();
            setSnackContent('保存成功');
            snackRef.current?.open();
          } else {
            setSnackContent(resp.data.head);
            snackRef.current?.open();
          }
        });
      })
      .catch(({ errors, fields }) => {
        dispatch({ type: 'validFail', payload: fields });
      });
  };

  const onSubmitImp = () => {
    if (fileList !== null && fileList.length > 0) {
      let file = fileList.item(0);
      if (file !== null) {
        let param = new FormData();
        param.append('file', file);
        imp(param).then(function (resp) {
          if (resp.data.success) {
            onCloseImpDialog();
            refresh();
            setSnackContent('导入成功');
            snackRef.current?.open();
          } else {
            setSnackContent(resp.data.head);
            snackRef.current?.open();
          }
        });
      }
    }
  };

  /// 删除
  const onDel = (id: number, other: string) => {
    del({ id: id, other: other }).then(function (resp) {
      delDialogRef.current?.close();
      if (resp.data.success) {
        refresh();
        setSnackContent('删除成功');
        snackRef.current?.open();
      } else {
        setSnackContent(resp.data.head);
        snackRef.current?.open();
      }
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: 'setPage', payload: newPage + 1 });
    setPage(newPage + 1);
  };

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseInt(event.target.value, 10);
    dispatch({ type: 'setPerPage', payload: value });
    setPerPage(value);
  };

  return (
    <Fragment>
      <Nav />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
        px={2}
      >
        <Breadcrumbs>
          <LinkRouter to="/">首页</LinkRouter>
          <LinkFn
            onClick={() => {
              setLeaf(false);
              setParentId(ROOT);
              popTreeBreadcrumb(treeBreadcrumbs, setTreeBreadcrumbs, ROOT);
            }}
          >
            通讯录
          </LinkFn>
          {treeBreadcrumbs.items.map((row) => (
            <LinkFn
              key={row.id}
              onClick={() => {
                setLeaf(row.leaf);
                setParentId(row.id);
                popTreeBreadcrumb(treeBreadcrumbs, setTreeBreadcrumbs, row.id);
              }}
            >
              {row.name}
            </LinkFn>
          ))}
        </Breadcrumbs>
        <Grid>
          <Tooltip title="导入">
            <IconButton onClick={openImpDialog}>
              <Login />
            </IconButton>
          </Tooltip>
          <Tooltip title="新增单位或部门" sx={{ display: displayWhenDept() }}>
            <IconButton onClick={insDeptFormDialog}>
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title="新增联系人" sx={{ display: displayWhenMbr() }}>
            <IconButton onClick={insMbrFormDialog}>
              <PersonAdd />
            </IconButton>
          </Tooltip>
          <Tooltip title="刷新">
            <IconButton onClick={refresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <FormControl
        fullWidth={true}
        sx={{ mx: 1.5, my: 0.5, flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <TextField
          variant="outlined"
          size="small"
          label={leaf ? '姓名' : '名称'}
          onChange={(e) => {
            setPage(1);
            setName(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="手机"
          onChange={(e) => {
            setPage(1);
            setPhone(e.target.value);
          }}
          sx={{ m: 0.5, display: displayWhenLeaf() }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="固话"
          onChange={(e) => {
            setPage(1);
            setTel(e.target.value);
          }}
          sx={{ m: 0.5, display: displayWhenLeaf() }}
        />
      </FormControl>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={100}>序号</TableCell>
              <TableCell>{leaf ? '姓名' : '名称'}</TableCell>
              <TableCell>排序</TableCell>
              <TableCell sx={{ display: displayWhenLeaf() }}>手机</TableCell>
              <TableCell sx={{ display: displayWhenLeaf() }}>小号</TableCell>
              <TableCell sx={{ display: displayWhenLeaf() }}>固话</TableCell>
              <TableCell sx={{ display: displayWhenLeaf() }}>
                内线专线
              </TableCell>
              <TableCell sx={{ display: displayWhenLeaf() }}>职务</TableCell>
              <TableCell width={110}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_state.page.items?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell sx={{ display: displayWhenDept() }}>
                  <LinkFn
                    onClick={() => {
                      setLeaf(row.leaf);
                      setParentId(row.id);
                      pushTreeBreadcrumb(
                        treeBreadcrumbs,
                        setTreeBreadcrumbs,
                        row.id,
                        row.parentId,
                        row.leaf,
                        row.name
                      );
                    }}
                  >
                    {row.name}
                  </LinkFn>
                </TableCell>
                <TableCell sx={{ display: displayWhenLeaf() }}>
                  {row.name}
                </TableCell>
                <TableCell>{row.ordinal}</TableCell>
                <TableCell sx={{ display: displayWhenLeaf() }}>
                  {row.phone}
                </TableCell>
                <TableCell sx={{ display: displayWhenLeaf() }}>
                  {row.shortTel}
                </TableCell>
                <TableCell sx={{ display: displayWhenLeaf() }}>
                  {row.tel}
                </TableCell>
                <TableCell sx={{ display: displayWhenLeaf() }}>
                  {row.otherTel}
                </TableCell>
                <TableCell sx={{ display: displayWhenLeaf() }}>
                  {row.position}
                </TableCell>
                <TableCell>
                  <Tooltip title="修改">
                    <IconButton
                      size="small"
                      onClick={() =>
                        openFormDialog(row, false, row.genre === GENRE_MBR)
                      }
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="开启登录" sx={{ display: displayWhenLeaf() }}>
                    <IconButton size="small" onClick={() => enableLogin(row)}>
                      <Person />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="删除">
                    <IconButton
                      size="small"
                      onClick={(event) => openDelDialog(row.id, '')}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Nil colSpan={11} rowTotal={_state.page.total} />
        </Table>
        <TablePagination
          component="div"
          count={_state.page.total}
          page={_state.page.page - 1}
          onPageChange={onPageChange}
          rowsPerPage={_state.page.perPage}
          onRowsPerPageChange={onPerPageChange}
          sx={{ mx: 2 }}
        />
      </TableContainer>
      {/* 表单编辑 */}
      <Dialog fullScreen open={isOpenFormDialog} onClose={onCloseFormDialog}>
        <AppBar elevation={0}>
          <Toolbar>
            <Tooltip title="返回">
              <IconButton color="inherit" onClick={onCloseFormDialog}>
                <ArrowBackIos />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              {isIns ? '通讯录添加' : '通讯录修改'}
            </Typography>
            <Tooltip title="保存">
              <IconButton color="inherit" onClick={onCloseFormDialog}>
                <Save />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <FormControl sx={{ mx: 2, mt: 9, mb: 2 }}>
          <TextField
            fullWidth
            variant="standard"
            label={leaf ? '姓名' : '名称'}
            type="string"
            value={_state.name.val}
            error={_state.name.err}
            helperText={_state.name.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setName',
                payload: e.target.value ? e.target.value : '',
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="排序"
            type="number"
            value={_state.ordinal.val}
            error={_state.ordinal.err}
            helperText={_state.ordinal.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setOrdinal',
                payload: e.target.value ? Number(e.target.value) : 0,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="手机"
            type="string"
            value={_state.phone.val}
            error={_state.phone.err}
            helperText={_state.phone.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setPhone',
                payload: e.target.value ? e.target.value : '',
              })
            }
            sx={{ mt: 1, display: displayWhenLeaf() }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="小号"
            type="string"
            value={_state.shortTel.val}
            error={_state.shortTel.err}
            helperText={_state.shortTel.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setShortTel',
                payload: e.target.value ? e.target.value : '',
              })
            }
            sx={{ mt: 1, display: displayWhenLeaf() }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="固话"
            type="string"
            value={_state.tel.val}
            error={_state.tel.err}
            helperText={_state.tel.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setTel',
                payload: e.target.value ? e.target.value : '',
              })
            }
            sx={{ mt: 1, display: displayWhenLeaf() }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="内线专线"
            type="string"
            value={_state.otherTel.val}
            error={_state.otherTel.err}
            helperText={_state.otherTel.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setOtherTel',
                payload: e.target.value ? e.target.value : '',
              })
            }
            sx={{ mt: 1, display: displayWhenLeaf() }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="职务"
            type="string"
            value={_state.position.val}
            error={_state.position.err}
            helperText={_state.position.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setPosition',
                payload: e.target.value ? e.target.value : '',
              })
            }
            sx={{ mt: 1, display: displayWhenLeaf() }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="办公室"
            type="string"
            value={_state.office.val}
            error={_state.office.err}
            helperText={_state.office.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setOffice',
                payload: e.target.value ? e.target.value : '',
              })
            }
            sx={{ mt: 1, display: displayWhenLeaf() }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="邮件"
            type="string"
            value={_state.email.val}
            error={_state.email.err}
            helperText={_state.email.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setEmail',
                payload: e.target.value ? e.target.value : '',
              })
            }
            sx={{ mt: 1, display: displayWhenLeaf() }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="备注"
            type="string"
            value={_state.memo.val}
            error={_state.memo.err}
            helperText={_state.memo.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setMemo',
                payload: e.target.value ? e.target.value : '',
              })
            }
            sx={{ mt: 1 }}
          />
          <span style={{ marginTop: 20 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onCloseFormDialog}
            >
              返回
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={onSubmitForm}
              sx={{ ml: 2 }}
            >
              保存
            </Button>
          </span>
        </FormControl>
      </Dialog>
      <Dialog onClose={onCloseImpDialog} open={isOpenImpDialog}>
        <DialogTitle>请选择导入文件</DialogTitle>
        <Input
          id="file"
          type="file"
          inputProps={{ accept: '.md' }}
          sx={{ mx: 3, mb: 3 }}
          onChange={onChangeImp}
        />
        <Grid container sx={{ mb: 3 }} direction="row" justifyContent="center">
          <Button variant="outlined" color="primary" onClick={onCloseImpDialog}>
            取消
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onSubmitImp}
            sx={{ ml: 2 }}
          >
            导入
          </Button>
        </Grid>
      </Dialog>
      <Snack ref={snackRef} content={snackContent} />
      <DelDialog ref={delDialogRef} onOk={onDel} />
    </Fragment>
  );
}
