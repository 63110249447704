import Account from '../../mcb/models/Account';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 账户 状态
export interface State extends Linear {
  /// 用户
  userId: FormField<number | null>;

  /// 余额
  balance: FormField<number | null>;

  /// 余额单位
  unit: FormField<string | null>;

  /// 充值总金额
  recharge: FormField<number | null>;

  /// 可用
  usable: FormField<boolean | null>;

  /// 是否启用授权码
  authcode: FormField<boolean | null>;

  /// 分页
  page: Page<Account>;
}

/// 账户 初始状态
const initState: State = {
  ...initLinear,
  userId: initField(0),
  balance: initField(0.0),
  unit: initField('元'),
  recharge: initField(0),
  usable: initField(true),
  authcode: initField(false),
  page: initPage<Account>(),
};

export default initState;
