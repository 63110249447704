import My from '../../cmn/models/My';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 我的机构 状态
export interface State extends Linear {
  /// 机构
  organId: FormField<number | null>;

  /// 机构名称
  organName: FormField<string | null>;

  /// 机构类型
  organGenre: FormField<number | null>;

  /// 用户
  userId: FormField<number | null>;

  /// 昵称
  nickname: FormField<string | null>;

  /// 手机
  phone: FormField<string | null>;

  /// 邮件
  email: FormField<string | null>;

  /// 是否允许该机构查看手机邮箱
  isPub: FormField<boolean | null>;

  /// 是否启用机构切换功能
  isSwap: FormField<boolean | null>;

  /// 是否是默认机构
  isDefault: FormField<boolean | null>;

  /// 加入时间
  joinAt: FormField<string | null>;

  /// 可用
  usable: FormField<boolean | null>;

  /// 状态
  state: FormField<number | null>;

  /// 分页
  page: Page<My>;
}

/// 我的机构 初始状态
const initState: State = {
  ...initLinear,
  organId: initField(0),
  organName: initField(''),
  organGenre: initField(101010),
  userId: initField(0),
  nickname: initField(''),
  phone: initField(''),
  email: initField(''),
  isPub: initField(false),
  isSwap: initField(true),
  isDefault: initField(false),
  joinAt: initField(''),
  usable: initField(true),
  state: initField(0),
  page: initPage<My>(),
};

export default initState;
