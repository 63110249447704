import Linear, { initLinear } from 'vts/models/core/Linear';

export const STATUS_ORDER = 10; // 下单中
export const STATUS_CART = 20; // 购物中
export const STATUS_PAYMENT = 30; // 已支付
export const STATUS_LOGISTICS = 40; // 已发货
export const STATUS_BALANCE = 50; // 已结算
export const STATUS_REFUND = 60; // 已退货

/// 购物车
export default interface Cart extends Linear {
  /// 订单号
  orderId: number;

  /// 客户
  customerId: number;

  /// 账户
  accountId: number;

  /// 商品
  productId: number;

  /// 商品
  goodsId: number;

  /// 数量
  quantity: number;

  /// 进价
  priceIn: number;

  /// 单价
  priceOut: number;

  /// 进货小计
  amountIn: number;

  /// 零售小计
  amountOut: number;

  /// 状态
  status: number;
}

export const newCart = {
  ...initLinear,
  orderId: 0,
  customerId: 0,
  accountId: 0,
  productId: 0,
  goodsId: 0,
  quantity: 0.0,
  priceIn: 0.0,
  priceOut: 0.0,
  amountIn: 0.0,
  amountOut: 0.0,
  status: 20,
};
