import Logistics from '../../gsb/models/Logistics';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 物流 状态
export interface State extends Linear {
  /// 订单号
  orderId: FormField<number | null>;

  /// 客户
  customerId: FormField<number | null>;

  /// 账户
  accountId: FormField<number | null>;

  /// 地址
  addressId: FormField<number | null>;

  /// 物流
  logistics: FormField<number | null>;

  /// 运单号
  waybill: FormField<string | null>;

  /// 运费
  freight: FormField<number | null>;

  /// 是否结算
  isBalance: FormField<boolean | null>;

  /// 状态
  status: FormField<number | null>;

  /// 分页
  page: Page<Logistics>;
}

/// 物流 初始状态
const initState: State = {
  ...initLinear,
  orderId: initField(0),
  customerId: initField(0),
  accountId: initField(0),
  addressId: initField(0),
  logistics: initField(0),
  waybill: initField(''),
  freight: initField(0.0),
  isBalance: initField(true),
  status: initField(40),
  page: initPage<Logistics>(),
};

export default initState;
