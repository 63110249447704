import Contact from '../../ses/models/Contact';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 通讯录 状态
export interface State extends Linear {
  /// 学生
  studentId: FormField<number | null>;

  /// 住址
  address: FormField<string | null>;

  /// 家长电话1
  telephone1: FormField<string | null>;

  /// 家长电话2
  telephone2: FormField<string | null>;

  /// 学生电话
  telephone3: FormField<string | null>;

  /// 分页
  page: Page<Contact>;
}

/// 通讯录 初始状态
const initState: State = {
  ...initLinear,
  studentId: initField(0),
  address: initField(''),
  telephone1: initField(''),
  telephone2: initField(''),
  telephone3: initField(''),
  page: initPage<Contact>(),
};

export default initState;
