import Linear, { initLinear } from 'vts/models/core/Linear';

/// 消息
export default interface Msg extends Linear {
  /// 机构
  organId: number;

  /// 机构名称
  organName: string;

  /// 用户
  userId: number;

  /// 用户名
  userName: string;

  /// 内容
  content: string;

  /// 状态
  status: number;
}

export const newMsg = {
  ...initLinear,
  organId: 0,
  organName: '',
  userId: 0,
  userName: '',
  content: '',
  status: 0,
};
