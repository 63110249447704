import Linear, { initLinear } from 'vts/models/core/Linear';

/// 用户岗位
export default interface UserPost extends Linear {
  /// 用户
  userId: number;

  /// 岗位
  postId: number;

  /// 是否是默认
  isDefault: boolean;
}

export const newUserPost = {
  ...initLinear,
  userId: 0,
  postId: 0,
  isDefault: false,
};
