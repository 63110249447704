import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Swap from './Swap';
import SysRes from './sys/screens/Res';
import SysMenu from './sys/screens/Menu';
import SysMenuRes from './sys/screens/MenuRes';
import SysPost from './sys/screens/Post';
import SysPostMenu from './sys/screens/PostMenu';
import SysArea from './sys/screens/Area';
import SysOrgan from './sys/screens/Organ';
import SysDept from './sys/screens/Dept';
import SysUserDept from './sys/screens/UserDept';
import SysUserPost from './sys/screens/UserPost';
import SysUser from './sys/screens/User';
import SysTraces from './sys/screens/Traces';
import SysDict from './sys/screens/Dict';
import SysSms from './sys/screens/Sms';
import SysBlacklist from './sys/screens/Blacklist';
import SysConf from './sys/screens/Conf';
import SysArchive from './sys/screens/Archive';
import SysFeedbacks from './sys/screens/Feedbacks';
import CmnMsg from './cmn/screens/Msg';
import CmnMy from './cmn/screens/My';
import CmnFlw from './cmn/screens/Flw';
import BpmProcess from './bpm/screens/Process';
import BpmTask from './bpm/screens/Task';
import BpmTaskDept from './bpm/screens/TaskDept';
import McbAccount from './mcb/screens/Account';
import McbRecord from './mcb/screens/Record';
import McbBalance from './mcb/screens/Balance';
import McbActivity from './mcb/screens/Activity';
import McbPartake from './mcb/screens/Partake';
import McbRecharge from './mcb/screens/Recharge';
import SesScore from './ses/screens/Score';
import SesExam from './ses/screens/Exam';
import SesStudent from './ses/screens/Student';
import SesCourse from './ses/screens/Course';
import SesBind from './ses/screens/Bind';
import SesApply from './ses/screens/Apply';
import SesContact from './ses/screens/Contact';
import GsbAccount from './gsb/screens/Account';
import GsbBalance from './gsb/screens/Balance';
import GsbProfit from './gsb/screens/Profit';
import GsbStock from './gsb/screens/Stock';
import GsbRecharge from './gsb/screens/Recharge';
import GsbGenre from './gsb/screens/Genre';
import GsbProduct from './gsb/screens/Product';
import GsbGoods from './gsb/screens/Goods';
import GsbCustomer from './gsb/screens/Customer';
import GsbAddress from './gsb/screens/Address';
import GsbOrder from './gsb/screens/Order';
import GsbCart from './gsb/screens/Cart';
import GsbPayment from './gsb/screens/Payment';
import GsbLogistics from './gsb/screens/Logistics';
import CmpContrast from './cmp/screens/Contrast';
import CmpContact from './cmp/screens/Contact';
import CmpEnroll from './cmp/screens/Enroll';
import KpiItem from './kpi/screens/Item';
import KpiMark from './kpi/screens/Mark';
import KpiGrade from './kpi/screens/Grade';
import HrbRoom from './hrb/screens/Room';
import GpcGov from './gpc/screens/Gov';
import GpcBrand from './gpc/screens/Brand';
import GpcModel from './gpc/screens/Model';
import GpcUses from './gpc/screens/Uses';
import GptContact from './gpt/screens/Contact';
import GptUserCatalog from './gpt/screens/UserCatalog';
import GptAdmUser from './gpt/screens/AdmUser';
import GptFirstLogin from './gpt/screens/FirstLogin';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/swap" element={<Swap />} />
      <Route path="/sys/res" element={<SysRes />} />
      <Route path="/sys/menu" element={<SysMenu />} />
      <Route path="/sys/menu_res" element={<SysMenuRes />} />
      <Route path="/sys/post" element={<SysPost />} />
      <Route path="/sys/post_menu" element={<SysPostMenu />} />
      <Route path="/sys/area" element={<SysArea />} />
      <Route path="/sys/organ" element={<SysOrgan />} />
      <Route path="/sys/dept" element={<SysDept />} />
      <Route path="/sys/user_dept" element={<SysUserDept />} />
      <Route path="/sys/user_post" element={<SysUserPost />} />
      <Route path="/sys/user" element={<SysUser />} />
      <Route path="/sys/traces" element={<SysTraces />} />
      <Route path="/sys/dict" element={<SysDict />} />
      <Route path="/sys/sms" element={<SysSms />} />
      <Route path="/sys/blacklist" element={<SysBlacklist />} />
      <Route path="/sys/conf" element={<SysConf />} />
      <Route path="/sys/archive" element={<SysArchive />} />
      <Route path="/sys/feedbacks" element={<SysFeedbacks />} />
      <Route path="/cmn/msg" element={<CmnMsg />} />
      <Route path="/cmn/my" element={<CmnMy />} />
      <Route path="/cmn/flw" element={<CmnFlw />} />
      <Route path="/bpm/process" element={<BpmProcess />} />
      <Route path="/bpm/task" element={<BpmTask />} />
      <Route path="/bpm/task_dept" element={<BpmTaskDept />} />
      <Route path="/mcb/account" element={<McbAccount />} />
      <Route path="/mcb/record" element={<McbRecord />} />
      <Route path="/mcb/balance" element={<McbBalance />} />
      <Route path="/mcb/activity" element={<McbActivity />} />
      <Route path="/mcb/partake" element={<McbPartake />} />
      <Route path="/mcb/recharge" element={<McbRecharge />} />
      <Route path="/ses/score" element={<SesScore />} />
      <Route path="/ses/exam" element={<SesExam />} />
      <Route path="/ses/student" element={<SesStudent />} />
      <Route path="/ses/course" element={<SesCourse />} />
      <Route path="/ses/bind" element={<SesBind />} />
      <Route path="/ses/apply" element={<SesApply />} />
      <Route path="/ses/contact" element={<SesContact />} />
      <Route path="/gsb/account" element={<GsbAccount />} />
      <Route path="/gsb/balance" element={<GsbBalance />} />
      <Route path="/gsb/profit" element={<GsbProfit />} />
      <Route path="/gsb/stock" element={<GsbStock />} />
      <Route path="/gsb/recharge" element={<GsbRecharge />} />
      <Route path="/gsb/genre" element={<GsbGenre />} />
      <Route path="/gsb/product" element={<GsbProduct />} />
      <Route path="/gsb/goods" element={<GsbGoods />} />
      <Route path="/gsb/customer" element={<GsbCustomer />} />
      <Route path="/gsb/address" element={<GsbAddress />} />
      <Route path="/gsb/order" element={<GsbOrder />} />
      <Route path="/gsb/cart" element={<GsbCart />} />
      <Route path="/gsb/payment" element={<GsbPayment />} />
      <Route path="/gsb/logistics" element={<GsbLogistics />} />
      <Route path="/cmp/contrast" element={<CmpContrast />} />
      <Route path="/cmp/contact" element={<CmpContact />} />
      <Route path="/cmp/enroll" element={<CmpEnroll />} />
      <Route path="/kpi/item" element={<KpiItem />} />
      <Route path="/kpi/mark" element={<KpiMark />} />
      <Route path="/kpi/grade" element={<KpiGrade />} />
      <Route path="/hrb/room" element={<HrbRoom />} />
      <Route path="/gpc/gov" element={<GpcGov />} />
      <Route path="/gpc/brand" element={<GpcBrand />} />
      <Route path="/gpc/model" element={<GpcModel />} />
      <Route path="/gpc/uses" element={<GpcUses />} />
      <Route path="/gpt/contact" element={<GptContact />} />
      <Route path="/gpt/user_catalog" element={<GptUserCatalog />} />
      <Route path="/gpt/adm_user" element={<GptAdmUser />} />
      <Route path="/gpt/first_login" element={<GptFirstLogin />} />
    </Routes>
  );
}

export default App;
