import Course from '../../ses/models/Course';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 课程 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 分页
  page: Page<Course>;
}

/// 课程 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  page: initPage<Course>(),
};

export default initState;
