import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  userId: [validators.gt(0)],
  accountId: [validators.gt(0)],
  balance: [validators.required()],
  unit: [validators.required()],
  recordAt: [validators.required()],
  status: [validators.gt(0)],
});

export default validator;
