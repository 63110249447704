import Organ from '../../sys/models/Organ';
import { ROOT } from 'vts/consts/id';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Tree, { initTree } from 'vts/states/core/tree';

/// 机构 状态
export interface State extends Tree {
  /// 区域
  areaId: FormField<number | null>;

  /// 昵称
  nickname: FormField<string | null>;

  /// 简介
  intro: FormField<string | null>;

  /// 城市
  city: FormField<string | null>;

  /// 地址
  address: FormField<string | null>;

  /// 手机
  phone: FormField<string | null>;

  /// 固话
  tel: FormField<string | null>;

  /// 开业时间
  startAt: FormField<string | null>;

  /// 状态
  status: FormField<number | null>;

  /// 类型
  genre: FormField<number | null>;

  /// 上班制
  shifts: FormField<number | null>;

  /// 二维码
  qrcode: FormField<string | null>;

  /// 允许所有用户搜索到本机构
  isPub: FormField<boolean | null>;

  /// 分页
  page: Page<Organ>;
}

/// 机构 初始状态
const initState: State = {
  ...initTree,
  areaId: initField(ROOT),
  nickname: initField(''),
  intro: initField(''),
  city: initField(''),
  address: initField(''),
  phone: initField(''),
  tel: initField(''),
  startAt: initField(''),
  status: initField(10),
  genre: initField(0),
  shifts: initField(20),
  qrcode: initField(''),
  isPub: initField(false),
  page: initPage<Organ>(),
};

export default initState;
