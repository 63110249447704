import Linear, { initLinear } from 'vts/models/core/Linear';

export const GENRE_INCOMING = 20; // 进货
export const GENRE_RETAIL = 30; // 零售
export const GENRE_REJECTED = 40; // 退货

/// 库存变动
export default interface Stock extends Linear {
  /// 账户
  accountId: number;

  /// 变动业务ID
  sourceId: number;

  /// 变动日期
  sourceAt: string;

  /// 变动类型
  genre: number;

  /// 变动前库存总额
  pre: number;

  /// 变动后库存总额
  post: number;
}

export const newStock = {
  ...initLinear,
  accountId: 0,
  sourceId: 0,
  sourceAt: '',
  genre: 0,
  pre: 0.0,
  post: 0.0,
};
