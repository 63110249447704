import { State } from '../../gsb/states/cart';
import Action from '../../gsb/actions/cart';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        orderId: {
          val: action.payload.orderId,
          err: false,
          hlp: '',
        },
        customerId: {
          val: action.payload.customerId,
          err: false,
          hlp: '',
        },
        accountId: {
          val: action.payload.accountId,
          err: false,
          hlp: '',
        },
        productId: {
          val: action.payload.productId,
          err: false,
          hlp: '',
        },
        goodsId: {
          val: action.payload.goodsId,
          err: false,
          hlp: '',
        },
        quantity: {
          val: action.payload.quantity,
          err: false,
          hlp: '',
        },
        priceIn: {
          val: action.payload.priceIn,
          err: false,
          hlp: '',
        },
        priceOut: {
          val: action.payload.priceOut,
          err: false,
          hlp: '',
        },
        amountIn: {
          val: action.payload.amountIn,
          err: false,
          hlp: '',
        },
        amountOut: {
          val: action.payload.amountOut,
          err: false,
          hlp: '',
        },
        status: {
          val: action.payload.status,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 订单号 值
    case 'setOrderId':
      return {
        ...state,
        orderId: {
          ...state.orderId,
          val: action.payload,
        },
      };
    /// 设置 客户 值
    case 'setCustomerId':
      return {
        ...state,
        customerId: {
          ...state.customerId,
          val: action.payload,
        },
      };
    /// 设置 账户 值
    case 'setAccountId':
      return {
        ...state,
        accountId: {
          ...state.accountId,
          val: action.payload,
        },
      };
    /// 设置 商品 值
    case 'setProductId':
      return {
        ...state,
        productId: {
          ...state.productId,
          val: action.payload,
        },
      };
    /// 设置 商品 值
    case 'setGoodsId':
      return {
        ...state,
        goodsId: {
          ...state.goodsId,
          val: action.payload,
        },
      };
    /// 设置 数量 值
    case 'setQuantity':
      return {
        ...state,
        quantity: {
          ...state.quantity,
          val: action.payload,
        },
      };
    /// 设置 进价 值
    case 'setPriceIn':
      return {
        ...state,
        priceIn: {
          ...state.priceIn,
          val: action.payload,
        },
      };
    /// 设置 单价 值
    case 'setPriceOut':
      return {
        ...state,
        priceOut: {
          ...state.priceOut,
          val: action.payload,
        },
      };
    /// 设置 进货小计 值
    case 'setAmountIn':
      return {
        ...state,
        amountIn: {
          ...state.amountIn,
          val: action.payload,
        },
      };
    /// 设置 零售小计 值
    case 'setAmountOut':
      return {
        ...state,
        amountOut: {
          ...state.amountOut,
          val: action.payload,
        },
      };
    /// 设置 状态 值
    case 'setStatus':
      return {
        ...state,
        status: {
          ...state.status,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        orderId: {
          ...state.orderId,
          err: hasError(action.payload.orderId),
          hlp: getFirstErrMsg(action.payload.orderId),
        },
        customerId: {
          ...state.customerId,
          err: hasError(action.payload.customerId),
          hlp: getFirstErrMsg(action.payload.customerId),
        },
        accountId: {
          ...state.accountId,
          err: hasError(action.payload.accountId),
          hlp: getFirstErrMsg(action.payload.accountId),
        },
        productId: {
          ...state.productId,
          err: hasError(action.payload.productId),
          hlp: getFirstErrMsg(action.payload.productId),
        },
        goodsId: {
          ...state.goodsId,
          err: hasError(action.payload.goodsId),
          hlp: getFirstErrMsg(action.payload.goodsId),
        },
        quantity: {
          ...state.quantity,
          err: hasError(action.payload.quantity),
          hlp: getFirstErrMsg(action.payload.quantity),
        },
        priceIn: {
          ...state.priceIn,
          err: hasError(action.payload.priceIn),
          hlp: getFirstErrMsg(action.payload.priceIn),
        },
        priceOut: {
          ...state.priceOut,
          err: hasError(action.payload.priceOut),
          hlp: getFirstErrMsg(action.payload.priceOut),
        },
        amountIn: {
          ...state.amountIn,
          err: hasError(action.payload.amountIn),
          hlp: getFirstErrMsg(action.payload.amountIn),
        },
        amountOut: {
          ...state.amountOut,
          err: hasError(action.payload.amountOut),
          hlp: getFirstErrMsg(action.payload.amountOut),
        },
        status: {
          ...state.status,
          err: hasError(action.payload.status),
          hlp: getFirstErrMsg(action.payload.status),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        orderId: initField(state.orderId.val),
        customerId: initField(state.customerId.val),
        accountId: initField(state.accountId.val),
        productId: initField(state.productId.val),
        goodsId: initField(state.goodsId.val),
        quantity: initField(state.quantity.val),
        priceIn: initField(state.priceIn.val),
        priceOut: initField(state.priceOut.val),
        amountIn: initField(state.amountIn.val),
        amountOut: initField(state.amountOut.val),
        status: initField(state.status.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
