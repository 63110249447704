import Stock from '../../gsb/models/Stock';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 库存变动 状态
export interface State extends Linear {
  /// 账户
  accountId: FormField<number | null>;

  /// 变动业务ID
  sourceId: FormField<number | null>;

  /// 变动日期
  sourceAt: FormField<string | null>;

  /// 变动类型
  genre: FormField<number | null>;

  /// 变动前库存总额
  pre: FormField<number | null>;

  /// 变动后库存总额
  post: FormField<number | null>;

  /// 分页
  page: Page<Stock>;
}

/// 库存变动 初始状态
const initState: State = {
  ...initLinear,
  accountId: initField(0),
  sourceId: initField(0),
  sourceAt: initField(''),
  genre: initField(0),
  pre: initField(0.0),
  post: initField(0.0),
  page: initPage<Stock>(),
};

export default initState;
