import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  AppBar,
  Breadcrumbs,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ArrowBackIos,
  Delete,
  Edit,
  IosShare,
  Refresh,
  Save,
  SaveAlt,
} from '@mui/icons-material';
import ExportDialog from 'vts/components/dialogs/ExportDialog';
import DelDialog from 'vts/components/dialogs/DelDialog';
import { ExportHandle, DelHandle } from 'vts/interfaces/dialog';
import LinkRouter from 'vts/components/link/router';
import Nil from 'vts/components/table/Nil';
import Snack from 'vts/components/snacks/Snack';
import OpenSnack from 'vts/interfaces/snack';
import Nav from '../../Nav';
import Car, { newCar } from '../../gpc/models/Car';
import initState from '../../gpc/states/car';
import reducer from '../../gpc/reducers/car';
import validator from '../../gpc/validators/car';
import { del, exp, find, imp, ins, upd } from '../../gpc/apis/car';

export default function CarZfzsScreen() {
  const nature = 40;

  const [_state, dispatch] = useReducer(reducer, initState);

  const [govName, setGovName] = useState('');
  const [brandName, setBrandName] = useState('');
  const [modelName, setModelName] = useState('');
  const [sn, setSn] = useState('');
  const [usesName, setUsesName] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [isIns, setIsIns] = useState(false);

  // 导出
  const expDialogRef = useRef<ExportHandle>(null);
  const openExpDialog = () => expDialogRef.current?.open();
  const onOkExp = () => {
    exp({
      nature: nature,
      govName: govName,
      brandName: brandName,
      modelName: modelName,
      sn: sn,
      usesName: usesName,
    }).then(function (resp) {
      expDialogRef.current?.close();
      if (resp.data.success) {
        window.open(resp.data.body);
      } else {
        setSnackContent(resp.data.head);
        snackRef.current?.open();
      }
    });
  };

  // 导入
  const [isOpenImpDialog, setIsOpenImpDialog] = useState(false);
  const [fileList, setFileList] = useState<FileList | null>(null);
  const onCloseImpDialog = () => setIsOpenImpDialog(false);
  const onChangeImp = (e: ChangeEvent<HTMLInputElement>) =>
    setFileList(e.target.files);
  const openImpDialog = () => setIsOpenImpDialog(true);
  const onSubmitImp = () => {
    if (fileList !== null && fileList.length > 0) {
      let file = fileList.item(0);
      if (file !== null) {
        let param = new FormData();
        param.append('file', file);
        param.append('nature', nature.toString());
        imp(param).then(function (resp) {
          if (resp.data.success) {
            onCloseImpDialog();
            refresh();
            setSnackContent('导入成功');
            snackRef.current?.open();
          } else {
            setSnackContent(resp.data.head);
            snackRef.current?.open();
          }
        });
      }
    }
  };

  const [snackContent, setSnackContent] = useState('保存成功');

  // 需要注意的是如果 ref 为元素，那么初始值得写个 null 才不会报错
  const snackRef = useRef<OpenSnack>(null);
  const delDialogRef = useRef<DelHandle>(null);

  const refresh = () => {
    find({
      nature: nature,
      govName: govName,
      brandName: brandName,
      modelName: modelName,
      sn: sn,
      usesName: usesName,
      page: page,
      perPage: perPage,
    }).then(function (resp) {
      dispatch({ type: 'found', payload: resp.data });
    });
  };

  useEffect(refresh, [
    nature,
    govName,
    brandName,
    modelName,
    sn,
    usesName,
    page,
    perPage,
  ]);

  const openFormDialog = (model: Car, isIns: boolean) => {
    dispatch({ type: 'setModel', payload: model });
    setIsOpenFormDialog(true);
    setIsIns(isIns);
  };

  const closeFormDialog = () => setIsOpenFormDialog(false);

  const openDelDialog = (id: number, other: string) =>
    delDialogRef.current?.open(id, other);

  const insFormDialog = () => openFormDialog(newCar, true);

  const submit = () => {
    validator
      .validate({
        nature: nature,
        govId: _state.govId.val,
        govName: _state.govName.val,
        brandId: _state.brandId.val,
        brandName: _state.brandName.val,
        modelId: _state.modelId.val,
        modelName: _state.modelName.val,
        sn: _state.sn.val,
        usesId: _state.usesId.val,
        usesName: _state.usesName.val,
      })
      .then(() => {
        dispatch({ type: 'clearError' });
        let save = isIns ? ins : upd;
        save({
          id: _state.id.val,
          nature: nature,
          govId: _state.govId.val,
          govName: _state.govName.val,
          brandId: _state.brandId.val,
          brandName: _state.brandName.val,
          modelId: _state.modelId.val,
          modelName: _state.modelName.val,
          sn: _state.sn.val,
          usesId: _state.usesId.val,
          usesName: _state.usesName.val,
          memo: _state.memo.val,
        }).then(function (resp) {
          if (resp.data.success) {
            closeFormDialog();
            refresh();
            setSnackContent('保存成功');
            snackRef.current?.open();
          } else {
            setSnackContent(resp.data.head);
            snackRef.current?.open();
          }
        });
      })
      .catch(({ errors, fields }) => {
        dispatch({ type: 'validFail', payload: fields });
      });
  };

  /// 删除
  const onDel = (id: number, other: string) => {
    del({ id: id, other: other }).then(function (resp) {
      delDialogRef.current?.close();
      if (resp.data.success) {
        refresh();
        setSnackContent('删除成功');
        snackRef.current?.open();
      } else {
        setSnackContent(resp.data.head);
        snackRef.current?.open();
      }
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: 'setPage', payload: newPage + 1 });
    setPage(newPage + 1);
  };

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseInt(event.target.value, 10);
    dispatch({ type: 'setPerPage', payload: value });
    setPerPage(value);
  };

  return (
    <Fragment>
      <Nav />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
        px={2}
      >
        <Breadcrumbs>
          <LinkRouter to="/">首页</LinkRouter>
          <Typography color="textPrimary">政府直属</Typography>
        </Breadcrumbs>
        <Grid>
          <Tooltip title="导出">
            <IconButton onClick={openExpDialog}>
              <IosShare />
            </IconButton>
          </Tooltip>
          <Tooltip title="导入">
            <IconButton onClick={openImpDialog}>
              <SaveAlt />
            </IconButton>
          </Tooltip>
          <Tooltip title="新增">
            <IconButton onClick={insFormDialog}>
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title="刷新">
            <IconButton onClick={refresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <FormControl
        fullWidth={true}
        sx={{ mx: 1.5, my: 0.5, flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <TextField
          variant="outlined"
          size="small"
          label="单位"
          onChange={(e) => {
            setPage(1);
            setGovName(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="品牌"
          onChange={(e) => {
            setPage(1);
            setBrandName(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="车型"
          onChange={(e) => {
            setPage(1);
            setModelName(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="车牌号"
          onChange={(e) => {
            setPage(1);
            setSn(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="编制类型"
          onChange={(e) => {
            setPage(1);
            setUsesName(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
      </FormControl>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={100}>序号</TableCell>
              <TableCell>单位</TableCell>
              <TableCell>品牌</TableCell>
              <TableCell>车型</TableCell>
              <TableCell>车牌号</TableCell>
              <TableCell>编制类型</TableCell>
              <TableCell width={70}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_state.page.items?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.govName}</TableCell>
                <TableCell>{row.brandName}</TableCell>
                <TableCell>{row.modelName}</TableCell>
                <TableCell>{row.sn}</TableCell>
                <TableCell>{row.usesName}</TableCell>
                <TableCell>
                  <Tooltip title="修改">
                    <IconButton
                      size="small"
                      onClick={() => openFormDialog(row, false)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="删除">
                    <IconButton
                      size="small"
                      onClick={(event) => openDelDialog(row.id, '')}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Nil colSpan={8} rowTotal={_state.page.total} />
        </Table>
        <TablePagination
          component="div"
          count={_state.page.total}
          page={_state.page.page - 1}
          onPageChange={onPageChange}
          rowsPerPage={_state.page.perPage}
          onRowsPerPageChange={onPerPageChange}
          sx={{ mx: 2 }}
        />
      </TableContainer>
      {/* 表单编辑 */}
      <Dialog fullScreen open={isOpenFormDialog} onClose={closeFormDialog}>
        <AppBar elevation={0}>
          <Toolbar>
            <Tooltip title="返回">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <ArrowBackIos />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              {isIns ? '政府直属车辆添加' : '政府直属车辆修改'}
            </Typography>
            <Tooltip title="保存">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <Save />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <FormControl sx={{ mx: 2, mt: 9, mb: 2 }}>
          <TextField
            fullWidth
            variant="standard"
            label="单位"
            type="string"
            value={_state.govName.val}
            error={_state.govName.err}
            helperText={_state.govName.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setGovName',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="品牌"
            type="string"
            value={_state.brandName.val}
            error={_state.brandName.err}
            helperText={_state.brandName.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setBrandName',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="车型"
            type="string"
            value={_state.modelName.val}
            error={_state.modelName.err}
            helperText={_state.modelName.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setModelName',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="车牌号"
            type="string"
            value={_state.sn.val}
            error={_state.sn.err}
            helperText={_state.sn.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setSn',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="编制类型"
            type="string"
            value={_state.usesName.val}
            error={_state.usesName.err}
            helperText={_state.usesName.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setUsesName',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="备注"
            type="string"
            value={_state.memo.val}
            error={_state.memo.err}
            helperText={_state.memo.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setMemo',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <span style={{ marginTop: 20 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeFormDialog}
            >
              返回
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={submit}
              sx={{ ml: 2 }}
            >
              保存
            </Button>
          </span>
        </FormControl>
      </Dialog>
      <Dialog onClose={onCloseImpDialog} open={isOpenImpDialog}>
        <DialogTitle>请选择导入文件</DialogTitle>
        <Input
          id="file"
          type="file"
          inputProps={{ accept: '.csv' }}
          sx={{ mx: 3, mb: 3 }}
          onChange={onChangeImp}
        />
        <Grid container sx={{ mb: 3 }} direction="row" justifyContent="center">
          <Button variant="outlined" color="primary" onClick={onCloseImpDialog}>
            取消
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onSubmitImp}
            sx={{ ml: 2 }}
          >
            导入
          </Button>
        </Grid>
      </Dialog>
      <Snack ref={snackRef} content={snackContent} />
      <DelDialog ref={delDialogRef} onOk={onDel} />
      <ExportDialog ref={expDialogRef} onOk={onOkExp} />
    </Fragment>
  );
}
