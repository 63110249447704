import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  studentId: [validators.gt(0)],
  examId: [validators.gt(0)],
  courseId: [validators.gt(0)],
  mark: [validators.required()],
  examAt: [validators.required()],
});

export default validator;
