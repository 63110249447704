import Tree, { initTree } from 'vts/models/core/Tree';

/// 商品分类
export default interface Genre extends Tree {
  /// 账户
  accountId: number;
}

export const newGenre = {
  ...initTree,
  accountId: 0,
};
