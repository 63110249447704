import Linear, { initLinear } from 'vts/models/core/Linear';

export const PAYMENT_WEIXIN = 10; // 微信
export const PAYMENT_ALIPAY = 20; // 支付宝
export const PAYMENT_BANK = 30; // 银行卡
export const PAYMENT_CASH = 40; // 现金

export const STATUS_ORDER = 10; // 下单中
export const STATUS_CART = 20; // 购物中
export const STATUS_PAYMENT = 30; // 已支付
export const STATUS_LOGISTICS = 40; // 已发货
export const STATUS_BALANCE = 50; // 已结算

/// 支付
export default interface Payment extends Linear {
  /// 订单号
  orderId: number;

  /// 客户
  customerId: number;

  /// 支付方式
  payment: number;

  /// 状态
  status: number;
}

export const newPayment = {
  ...initLinear,
  orderId: 0,
  customerId: 0,
  payment: 0,
  status: 30,
};
