import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  AppBar,
  Breadcrumbs,
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ArrowBackIos,
  Delete,
  Edit,
  Refresh,
  Save,
} from '@mui/icons-material';
import DelDialog from 'vts/components/dialogs/DelDialog';
import { DelHandle } from 'vts/interfaces/dialog';
import LinkRouter from 'vts/components/link/router';
import { ROOT } from 'vts/consts/id';
import LinkFn from 'vts/components/link/fn';
import {
  TreeBreadcrumbs,
  popTreeBreadcrumb,
  pushTreeBreadcrumb,
} from 'vts/utils/breadcrumbs';
import Nil from 'vts/components/table/Nil';
import Snack from 'vts/components/snacks/Snack';
import OpenSnack from 'vts/interfaces/snack';
import * as globals from 'vts/utils/globals';
import Nav from '../../Nav';
import Res, { newRes } from '../../sys/models/Res';
import initState from '../../sys/states/res';
import reducer from '../../sys/reducers/res';
import validator from '../../sys/validators/res';
import { del, find, ins, upd } from '../../sys/apis/res';

export default function ResScreen() {
  const [_state, dispatch] = useReducer(reducer, initState);

  const [name, setName] = useState('');
  const [parentId, setParentId] = useState(ROOT);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [isIns, setIsIns] = useState(false);

  const [snackContent, setSnackContent] = useState('保存成功');
  const [treeBreadcrumbs, setTreeBreadcrumbs] = useState<TreeBreadcrumbs>({
    items: [],
  });

  // 需要注意的是如果 ref 为元素，那么初始值得写个 null 才不会报错
  const snackRef = useRef<OpenSnack>(null);
  const delDialogRef = useRef<DelHandle>(null);

  const refresh = () => {
    find({
      name: name,
      parentId: parentId,
      page: page,
      perPage: perPage,
    }).then(function (resp) {
      dispatch({ type: 'found', payload: resp.data });
    });
  };

  useEffect(refresh, [name, parentId, page, perPage]);

  const openFormDialog = (model: Res, isIns: boolean) => {
    if (isIns) model.parentId = parentId;
    dispatch({ type: 'setModel', payload: model });
    setIsOpenFormDialog(true);
    setIsIns(isIns);
  };

  const closeFormDialog = () => setIsOpenFormDialog(false);

  const openDelDialog = (id: number, other: string) => delDialogRef.current?.open(id, other);

  const insFormDialog = () => openFormDialog(newRes, true);

  const submit = () => {
    validator
      .validate({
        name: _state.name.val,
        parentId: _state.parentId.val,
        ordinal: _state.ordinal.val,
        genre: _state.genre.val,
      })
      .then(() => {
        dispatch({ type: 'clearError' });
        let save = isIns ? ins : upd;
        save({
          id: _state.id.val,
          name: _state.name.val,
          parentId: _state.parentId.val,
          ordinal: _state.ordinal.val,
          href: _state.href.val,
          target: _state.target.val,
          icon: _state.icon.val,
          hidden: _state.hidden.val,
          permission: _state.permission.val,
          grp: _state.grp.val,
          genre: _state.genre.val,
          memo: _state.memo.val,
        }).then(function (resp) {
          if (resp.data.success) {
            closeFormDialog();
            refresh();
            setSnackContent('保存成功');
            snackRef.current?.open();
          } else {
            setSnackContent(resp.data.head);
            snackRef.current?.open();
          }
        });
      })
      .catch(({ errors, fields }) => {
        dispatch({ type: 'validFail', payload: fields });
      });
  };

  /// 删除
  const onDel = (id: number, other: string) => {
    del({ id: id, other: other }).then(function (resp) {
      delDialogRef.current?.close();
      if (resp.data.success) {
        refresh();
        setSnackContent('删除成功');
        snackRef.current?.open();
      } else {
        setSnackContent(resp.data.head);
        snackRef.current?.open();
      }
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: 'setPage', payload: newPage + 1 });
    setPage(newPage + 1);
  };

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseInt(event.target.value, 10);
    dispatch({ type: 'setPerPage', payload: value });
    setPerPage(value);
  };

  return (
    <Fragment>
      <Nav />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
        px={2}
      >
        <Breadcrumbs>
          <LinkRouter to="/">首页</LinkRouter>
          <LinkFn
            onClick={() => {
              setParentId(ROOT);
              popTreeBreadcrumb(treeBreadcrumbs, setTreeBreadcrumbs, ROOT);
            }}
          >
            资源
          </LinkFn>
          {treeBreadcrumbs.items.map((row) => (
            <LinkFn
              key={row.id}
              onClick={() => {
                setParentId(row.id);
                popTreeBreadcrumb(treeBreadcrumbs, setTreeBreadcrumbs, row.id);
              }}
            >
              {row.name}
            </LinkFn>
          ))}
        </Breadcrumbs>
        <Grid>
          <Tooltip title="新增">
            <IconButton onClick={insFormDialog}>
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title="刷新">
            <IconButton onClick={refresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <FormControl
        fullWidth={true}
        sx={{ mx: 1.5, my: 0.5, flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <TextField
          variant="outlined"
          size="small"
          label="名称"
          onChange={(e) => {
            setPage(1);
            setName(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
      </FormControl>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={100}>序号</TableCell>
              <TableCell>名称</TableCell>
              <TableCell>链接</TableCell>
              <TableCell>图标</TableCell>
              <TableCell>隐藏</TableCell>
              <TableCell>权限</TableCell>
              <TableCell>分组</TableCell>
              <TableCell>资源类型</TableCell>
              <TableCell width={70}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_state.page.items?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  <LinkFn
                    onClick={() => {
                      setParentId(row.id);
                      pushTreeBreadcrumb(
                        treeBreadcrumbs,
                        setTreeBreadcrumbs,
                        row.id,
                        row.parentId,
                        row.leaf,
                        row.name
                      );
                    }}
                  >
                    {row.name}
                  </LinkFn>
                </TableCell>
                <TableCell>{row.href}</TableCell>
                <TableCell>{row.icon}</TableCell>
                <TableCell>{row.hidden}</TableCell>
                <TableCell>{row.permission}</TableCell>
                <TableCell>{row.grp}</TableCell>
                <TableCell>
                  {globals.dictPubVal('sys_res.genre', row.genre.toString())}
                </TableCell>
                <TableCell>
                  <Tooltip title="修改">
                    <IconButton
                      size="small"
                      onClick={() => openFormDialog(row, false)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="删除">
                    <IconButton
                      size="small"
                      onClick={(event) => openDelDialog(row.id, '')}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Nil colSpan={9} rowTotal={_state.page.total} />
        </Table>
        <TablePagination
          component="div"
          count={_state.page.total}
          page={_state.page.page - 1}
          onPageChange={onPageChange}
          rowsPerPage={_state.page.perPage}
          onRowsPerPageChange={onPerPageChange}
          sx={{ mx: 2 }}
        />
      </TableContainer>
      {/* 表单编辑 */}
      <Dialog fullScreen open={isOpenFormDialog} onClose={closeFormDialog}>
        <AppBar elevation={0}>
          <Toolbar>
            <Tooltip title="返回">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <ArrowBackIos />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              {isIns ? '资源添加' : '资源修改'}
            </Typography>
            <Tooltip title="保存">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <Save />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <FormControl sx={{ mx: 2, mt: 9, mb: 2 }}>
          <TextField
            fullWidth
            variant="standard"
            label="名称"
            type="string"
            value={_state.name.val}
            error={_state.name.err}
            helperText={_state.name.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setName',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="链接"
            type="string"
            value={_state.href.val}
            error={_state.href.err}
            helperText={_state.href.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setHref',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="图标"
            type="string"
            value={_state.icon.val}
            error={_state.icon.err}
            helperText={_state.icon.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setIcon',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="隐藏"
            type="boolean"
            value={_state.hidden.val}
            error={_state.hidden.err}
            helperText={_state.hidden.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setHidden',
                payload: e.target.value ? e.target.value === 'true' : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="权限"
            type="string"
            value={_state.permission.val}
            error={_state.permission.err}
            helperText={_state.permission.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setPermission',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="分组"
            type="string"
            value={_state.grp.val}
            error={_state.grp.err}
            helperText={_state.grp.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setGrp',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="资源类型"
            type="number"
            value={_state.genre.val}
            error={_state.genre.err}
            helperText={_state.genre.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setGenre',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="备注"
            type="string"
            value={_state.memo.val}
            error={_state.memo.err}
            helperText={_state.memo.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setMemo',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <span style={{ marginTop: 20 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeFormDialog}
            >
              返回
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={submit}
              sx={{ ml: 2 }}
            >
              保存
            </Button>
          </span>
        </FormControl>
      </Dialog>
      <Snack ref={snackRef} content={snackContent} />
      <DelDialog ref={delDialogRef} onOk={onDel} />
    </Fragment>
  );
}
