import Linear, { initLinear } from 'vts/models/core/Linear';

/// 房间
export default interface Room extends Linear {
  /// 名称
  name: string;
}

export const newRoom = {
  ...initLinear,
  name: '',
};
