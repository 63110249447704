import { State } from '../../sys/states/dept';
import Action from '../../sys/actions/dept';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        parentId: {
          val: action.payload.parentId,
          err: false,
          hlp: '',
        },
        ordinal: {
          val: action.payload.ordinal,
          err: false,
          hlp: '',
        },
        confId: {
          val: action.payload.confId,
          err: false,
          hlp: '',
        },
        genre: {
          val: action.payload.genre,
          err: false,
          hlp: '',
        },
        filters: {
          val: action.payload.filters,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 名称 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 父标识 值
    case 'setParentId':
      return {
        ...state,
        parentId: {
          ...state.parentId,
          val: action.payload,
        },
      };
    /// 设置 排序 值
    case 'setOrdinal':
      return {
        ...state,
        ordinal: {
          ...state.ordinal,
          val: action.payload,
        },
      };
    /// 设置 配置 值
    case 'setConfId':
      return {
        ...state,
        confId: {
          ...state.confId,
          val: action.payload,
        },
      };
    /// 设置 类型 值
    case 'setGenre':
      return {
        ...state,
        genre: {
          ...state.genre,
          val: action.payload,
        },
      };
    /// 设置 过滤 值
    case 'setFilters':
      return {
        ...state,
        filters: {
          ...state.filters,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        parentId: {
          ...state.parentId,
          err: hasError(action.payload.parentId),
          hlp: getFirstErrMsg(action.payload.parentId),
        },
        ordinal: {
          ...state.ordinal,
          err: hasError(action.payload.ordinal),
          hlp: getFirstErrMsg(action.payload.ordinal),
        },
        confId: {
          ...state.confId,
          err: hasError(action.payload.confId),
          hlp: getFirstErrMsg(action.payload.confId),
        },
        genre: {
          ...state.genre,
          err: hasError(action.payload.genre),
          hlp: getFirstErrMsg(action.payload.genre),
        },
        filters: {
          ...state.filters,
          err: hasError(action.payload.filters),
          hlp: getFirstErrMsg(action.payload.filters),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        name: initField(state.name.val),
        parentId: initField(state.parentId.val),
        ordinal: initField(state.ordinal.val),
        confId: initField(state.confId.val),
        genre: initField(state.genre.val),
        filters: initField(state.filters.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
