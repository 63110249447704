import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  rows: [validators.required()],
  col: [validators.required()],
  name: [validators.maxlen(10)],
});

export default validator;
