import Activity from '../../mcb/models/Activity';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 活动 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 描述
  descr: FormField<string | null>;

  /// 起始时间
  beginAt: FormField<string | null>;

  /// 截止时间
  endAt: FormField<string | null>;

  /// 状态
  status: FormField<number | null>;

  /// 分页
  page: Page<Activity>;
}

/// 活动 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  descr: initField(''),
  beginAt: initField(''),
  endAt: initField(''),
  status: initField(0),
  page: initPage<Activity>(),
};

export default initState;
