import { State } from '../../sys/states/sms';
import Action from '../../sys/actions/sms';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        phones: {
          val: action.payload.phones,
          err: false,
          hlp: '',
        },
        signName: {
          val: action.payload.signName,
          err: false,
          hlp: '',
        },
        templateCode: {
          val: action.payload.templateCode,
          err: false,
          hlp: '',
        },
        templateParams: {
          val: action.payload.templateParams,
          err: false,
          hlp: '',
        },
        content: {
          val: action.payload.content,
          err: false,
          hlp: '',
        },
        bizId: {
          val: action.payload.bizId,
          err: false,
          hlp: '',
        },
        genre: {
          val: action.payload.genre,
          err: false,
          hlp: '',
        },
        status: {
          val: action.payload.status,
          err: false,
          hlp: '',
        },
        other: {
          val: action.payload.other,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 手机号列表 值
    case 'setPhones':
      return {
        ...state,
        phones: {
          ...state.phones,
          val: action.payload,
        },
      };
    /// 设置 短信签名 值
    case 'setSignName':
      return {
        ...state,
        signName: {
          ...state.signName,
          val: action.payload,
        },
      };
    /// 设置 模板编号 值
    case 'setTemplateCode':
      return {
        ...state,
        templateCode: {
          ...state.templateCode,
          val: action.payload,
        },
      };
    /// 设置 模板变量值 值
    case 'setTemplateParams':
      return {
        ...state,
        templateParams: {
          ...state.templateParams,
          val: action.payload,
        },
      };
    /// 设置 短信内容 值
    case 'setContent':
      return {
        ...state,
        content: {
          ...state.content,
          val: action.payload,
        },
      };
    /// 设置 发送回执ID 值
    case 'setBizId':
      return {
        ...state,
        bizId: {
          ...state.bizId,
          val: action.payload,
        },
      };
    /// 设置 类型 值
    case 'setGenre':
      return {
        ...state,
        genre: {
          ...state.genre,
          val: action.payload,
        },
      };
    /// 设置 状态 值
    case 'setStatus':
      return {
        ...state,
        status: {
          ...state.status,
          val: action.payload,
        },
      };
    /// 设置 其他 值
    case 'setOther':
      return {
        ...state,
        other: {
          ...state.other,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        phones: {
          ...state.phones,
          err: hasError(action.payload.phones),
          hlp: getFirstErrMsg(action.payload.phones),
        },
        signName: {
          ...state.signName,
          err: hasError(action.payload.signName),
          hlp: getFirstErrMsg(action.payload.signName),
        },
        templateCode: {
          ...state.templateCode,
          err: hasError(action.payload.templateCode),
          hlp: getFirstErrMsg(action.payload.templateCode),
        },
        templateParams: {
          ...state.templateParams,
          err: hasError(action.payload.templateParams),
          hlp: getFirstErrMsg(action.payload.templateParams),
        },
        content: {
          ...state.content,
          err: hasError(action.payload.content),
          hlp: getFirstErrMsg(action.payload.content),
        },
        bizId: {
          ...state.bizId,
          err: hasError(action.payload.bizId),
          hlp: getFirstErrMsg(action.payload.bizId),
        },
        genre: {
          ...state.genre,
          err: hasError(action.payload.genre),
          hlp: getFirstErrMsg(action.payload.genre),
        },
        status: {
          ...state.status,
          err: hasError(action.payload.status),
          hlp: getFirstErrMsg(action.payload.status),
        },
        other: {
          ...state.other,
          err: hasError(action.payload.other),
          hlp: getFirstErrMsg(action.payload.other),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        phones: initField(state.phones.val),
        signName: initField(state.signName.val),
        templateCode: initField(state.templateCode.val),
        templateParams: initField(state.templateParams.val),
        content: initField(state.content.val),
        bizId: initField(state.bizId.val),
        genre: initField(state.genre.val),
        status: initField(state.status.val),
        other: initField(state.other.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
