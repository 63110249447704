import { State } from '../../gpc/states/car';
import Action from '../../gpc/actions/car';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        nature: {
          val: action.payload.nature,
          err: false,
          hlp: '',
        },
        govId: {
          val: action.payload.govId,
          err: false,
          hlp: '',
        },
        govName: {
          val: action.payload.govName,
          err: false,
          hlp: '',
        },
        brandId: {
          val: action.payload.brandId,
          err: false,
          hlp: '',
        },
        brandName: {
          val: action.payload.brandName,
          err: false,
          hlp: '',
        },
        modelId: {
          val: action.payload.modelId,
          err: false,
          hlp: '',
        },
        modelName: {
          val: action.payload.modelName,
          err: false,
          hlp: '',
        },
        sn: {
          val: action.payload.sn,
          err: false,
          hlp: '',
        },
        usesId: {
          val: action.payload.usesId,
          err: false,
          hlp: '',
        },
        usesName: {
          val: action.payload.usesName,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 单位性质 值
    case 'setNature':
      return {
        ...state,
        nature: {
          ...state.nature,
          val: action.payload,
        },
      };
    /// 设置 单位 值
    case 'setGovId':
      return {
        ...state,
        govId: {
          ...state.govId,
          val: action.payload,
        },
      };
    /// 设置 单位 值
    case 'setGovName':
      return {
        ...state,
        govName: {
          ...state.govName,
          val: action.payload,
        },
      };
    /// 设置 品牌 值
    case 'setBrandId':
      return {
        ...state,
        brandId: {
          ...state.brandId,
          val: action.payload,
        },
      };
    /// 设置 品牌 值
    case 'setBrandName':
      return {
        ...state,
        brandName: {
          ...state.brandName,
          val: action.payload,
        },
      };
    /// 设置 车型 值
    case 'setModelId':
      return {
        ...state,
        modelId: {
          ...state.modelId,
          val: action.payload,
        },
      };
    /// 设置 车型 值
    case 'setModelName':
      return {
        ...state,
        modelName: {
          ...state.modelName,
          val: action.payload,
        },
      };
    /// 设置 车牌号 值
    case 'setSn':
      return {
        ...state,
        sn: {
          ...state.sn,
          val: action.payload,
        },
      };
    /// 设置 编制类型 值
    case 'setUsesId':
      return {
        ...state,
        usesId: {
          ...state.usesId,
          val: action.payload,
        },
      };
    /// 设置 编制类型 值
    case 'setUsesName':
      return {
        ...state,
        usesName: {
          ...state.usesName,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        nature: {
          ...state.nature,
          err: hasError(action.payload.nature),
          hlp: getFirstErrMsg(action.payload.nature),
        },
        govId: {
          ...state.govId,
          err: hasError(action.payload.govId),
          hlp: getFirstErrMsg(action.payload.govId),
        },
        govName: {
          ...state.govName,
          err: hasError(action.payload.govName),
          hlp: getFirstErrMsg(action.payload.govName),
        },
        brandId: {
          ...state.brandId,
          err: hasError(action.payload.brandId),
          hlp: getFirstErrMsg(action.payload.brandId),
        },
        brandName: {
          ...state.brandName,
          err: hasError(action.payload.brandName),
          hlp: getFirstErrMsg(action.payload.brandName),
        },
        modelId: {
          ...state.modelId,
          err: hasError(action.payload.modelId),
          hlp: getFirstErrMsg(action.payload.modelId),
        },
        modelName: {
          ...state.modelName,
          err: hasError(action.payload.modelName),
          hlp: getFirstErrMsg(action.payload.modelName),
        },
        sn: {
          ...state.sn,
          err: hasError(action.payload.sn),
          hlp: getFirstErrMsg(action.payload.sn),
        },
        usesId: {
          ...state.usesId,
          err: hasError(action.payload.usesId),
          hlp: getFirstErrMsg(action.payload.usesId),
        },
        usesName: {
          ...state.usesName,
          err: hasError(action.payload.usesName),
          hlp: getFirstErrMsg(action.payload.usesName),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        nature: initField(state.nature.val),
        govId: initField(state.govId.val),
        govName: initField(state.govName.val),
        brandId: initField(state.brandId.val),
        brandName: initField(state.brandName.val),
        modelId: initField(state.modelId.val),
        modelName: initField(state.modelName.val),
        sn: initField(state.sn.val),
        usesId: initField(state.usesId.val),
        usesName: initField(state.usesName.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
