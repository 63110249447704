import { State } from '../../gsb/states/recharge';
import Action from '../../gsb/actions/recharge';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        accountId: {
          val: action.payload.accountId,
          err: false,
          hlp: '',
        },
        amount: {
          val: action.payload.amount,
          err: false,
          hlp: '',
        },
        rechargeAt: {
          val: action.payload.rechargeAt,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 账户 值
    case 'setAccountId':
      return {
        ...state,
        accountId: {
          ...state.accountId,
          val: action.payload,
        },
      };
    /// 设置 金额 值
    case 'setAmount':
      return {
        ...state,
        amount: {
          ...state.amount,
          val: action.payload,
        },
      };
    /// 设置 充值日期 值
    case 'setRechargeAt':
      return {
        ...state,
        rechargeAt: {
          ...state.rechargeAt,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        accountId: {
          ...state.accountId,
          err: hasError(action.payload.accountId),
          hlp: getFirstErrMsg(action.payload.accountId),
        },
        amount: {
          ...state.amount,
          err: hasError(action.payload.amount),
          hlp: getFirstErrMsg(action.payload.amount),
        },
        rechargeAt: {
          ...state.rechargeAt,
          err: hasError(action.payload.rechargeAt),
          hlp: getFirstErrMsg(action.payload.rechargeAt),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        accountId: initField(state.accountId.val),
        amount: initField(state.amount.val),
        rechargeAt: initField(state.rechargeAt.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
