import Tree, { initTree } from 'vts/models/core/Tree';

export const GENRE_SYS = 10; // 系统
export const GENRE_MENU = 20; // 菜单
export const GENRE_ACTION = 30; // 操作

/// 资源
export default interface Res extends Tree {
  /// 链接
  href: string;

  /// 目标
  target: string;

  /// 图标
  icon: string;

  /// 隐藏
  hidden: boolean;

  /// 权限
  permission: string;

  /// 分组
  grp: string;

  /// 资源类型
  genre: number;
}

export const newRes = {
  ...initTree,
  href: '',
  target: '',
  icon: '',
  hidden: false,
  permission: '',
  grp: '',
  genre: 0,
};
