import Linear, { initLinear } from 'vts/models/core/Linear';

export const GENRE_ROOM = 10; // 住宿
export const GENRE_BUS = 20; // 大巴

/// 报名表
export default interface Enroll extends Linear {
  /// 名称
  name: string;

  /// 类型
  genre: number;
}

export const newEnroll = {
  ...initLinear,
  name: '',
  genre: 0,
};
