import Linear, { initLinear } from 'vts/models/core/Linear';

export const STATUS_SUPPLIER = 10; // 供货商
export const STATUS_REFUND = 20; // 退货
export const STATUS_INBOUND = 30; // 入库

/// 库存商品
export default interface Goods extends Linear {
  /// 账户
  accountId: number;

  /// 分类
  genreId: number;

  /// 商品
  productId: number;

  /// 业务ID
  sourceId: number;

  /// 进价
  priceIn: number;

  /// 单价
  priceOut: number;

  /// 库存数量
  inventory: number;

  /// 状态
  status: number;
}

export const newGoods = {
  ...initLinear,
  accountId: 0,
  genreId: 0,
  productId: 0,
  sourceId: 0,
  priceIn: 0.0,
  priceOut: 0.0,
  inventory: 0.0,
  status: 10,
};
