import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  AppBar,
  Breadcrumbs,
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ArrowBackIos,
  Delete,
  Edit,
  Refresh,
  Save,
} from '@mui/icons-material';
import DelDialog from 'vts/components/dialogs/DelDialog';
import { DelHandle } from 'vts/interfaces/dialog';
import LinkRouter from 'vts/components/link/router';
import Nil from 'vts/components/table/Nil';
import Snack from 'vts/components/snacks/Snack';
import OpenSnack from 'vts/interfaces/snack';
import * as globals from 'vts/utils/globals';
import Nav from '../../Nav';
import Balance, { newBalance } from '../../mcb/models/Balance';
import initState from '../../mcb/states/balance';
import reducer from '../../mcb/reducers/balance';
import validator from '../../mcb/validators/balance';
import { del, find, ins, upd } from '../../mcb/apis/balance';

export default function BalanceScreen() {
  const [_state, dispatch] = useReducer(reducer, initState);

  const [userId, setUserId] = useState<number | null>(null);
  const [accountId, setAccountId] = useState<number | null>(null);
  const [sourceAtBegin, setSourceAtBegin] = useState('');
  const [sourceAtEnd, setSourceAtEnd] = useState('');
  const [genre, setGenre] = useState<number | null>(null);
  const [preBegin, setPreBegin] = useState<number | null>(null);
  const [preEnd, setPreEnd] = useState<number | null>(null);
  const [postBegin, setPostBegin] = useState<number | null>(null);
  const [postEnd, setPostEnd] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [isIns, setIsIns] = useState(false);

  const [snackContent, setSnackContent] = useState('保存成功');

  // 需要注意的是如果 ref 为元素，那么初始值得写个 null 才不会报错
  const snackRef = useRef<OpenSnack>(null);
  const delDialogRef = useRef<DelHandle>(null);

  const refresh = () => {
    find({
      userId: userId,
      accountId: accountId,
      sourceAtBegin: sourceAtBegin,
      sourceAtEnd: sourceAtEnd,
      genre: genre,
      preBegin: preBegin,
      preEnd: preEnd,
      postBegin: postBegin,
      postEnd: postEnd,
      page: page,
      perPage: perPage,
    }).then(function (resp) {
      dispatch({ type: 'found', payload: resp.data });
    });
  };

  useEffect(refresh, [userId, accountId, sourceAtBegin, sourceAtEnd, genre, preBegin, preEnd, postBegin, postEnd, page, perPage]);

  const openFormDialog = (model: Balance, isIns: boolean) => {
    dispatch({ type: 'setModel', payload: model });
    setIsOpenFormDialog(true);
    setIsIns(isIns);
  };

  const closeFormDialog = () => setIsOpenFormDialog(false);

  const openDelDialog = (id: number, other: string) => delDialogRef.current?.open(id, other);

  const insFormDialog = () => openFormDialog(newBalance, true);

  const submit = () => {
    validator
      .validate({
        userId: _state.userId.val,
        accountId: _state.accountId.val,
        sourceId: _state.sourceId.val,
        sourceAt: _state.sourceAt.val,
        genre: _state.genre.val,
        pre: _state.pre.val,
        post: _state.post.val,
      })
      .then(() => {
        dispatch({ type: 'clearError' });
        let save = isIns ? ins : upd;
        save({
          id: _state.id.val,
          userId: _state.userId.val,
          accountId: _state.accountId.val,
          sourceId: _state.sourceId.val,
          sourceAt: _state.sourceAt.val,
          genre: _state.genre.val,
          pre: _state.pre.val,
          post: _state.post.val,
          memo: _state.memo.val,
        }).then(function (resp) {
          if (resp.data.success) {
            closeFormDialog();
            refresh();
            setSnackContent('保存成功');
            snackRef.current?.open();
          } else {
            setSnackContent(resp.data.head);
            snackRef.current?.open();
          }
        });
      })
      .catch(({ errors, fields }) => {
        dispatch({ type: 'validFail', payload: fields });
      });
  };

  /// 删除
  const onDel = (id: number, other: string) => {
    del({ id: id, other: other }).then(function (resp) {
      delDialogRef.current?.close();
      if (resp.data.success) {
        refresh();
        setSnackContent('删除成功');
        snackRef.current?.open();
      } else {
        setSnackContent(resp.data.head);
        snackRef.current?.open();
      }
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: 'setPage', payload: newPage + 1 });
    setPage(newPage + 1);
  };

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseInt(event.target.value, 10);
    dispatch({ type: 'setPerPage', payload: value });
    setPerPage(value);
  };

  return (
    <Fragment>
      <Nav />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
        px={2}
      >
        <Breadcrumbs>
          <LinkRouter to="/">首页</LinkRouter>
          <Typography color="textPrimary">余额变动</Typography>
        </Breadcrumbs>
        <Grid>
          <Tooltip title="新增">
            <IconButton onClick={insFormDialog}>
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title="刷新">
            <IconButton onClick={refresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <FormControl
        fullWidth={true}
        sx={{ mx: 1.5, my: 0.5, flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <TextField
          variant="outlined"
          size="small"
          label="用户"
          onChange={(e) => {
            setPage(1);
            setUserId(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="账户"
          onChange={(e) => {
            setPage(1);
            setAccountId(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="开始变动日期"
          onChange={(e) => {
            setPage(1);
            setSourceAtBegin(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="截止变动日期"
          onChange={(e) => {
            setPage(1);
            setSourceAtEnd(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="变动类型"
          onChange={(e) => {
            setPage(1);
            setGenre(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="开始变动前余额"
          onChange={(e) => {
            setPage(1);
            setPreBegin(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="截止变动前余额"
          onChange={(e) => {
            setPage(1);
            setPreEnd(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="开始变动后余额"
          onChange={(e) => {
            setPage(1);
            setPostBegin(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="截止变动后余额"
          onChange={(e) => {
            setPage(1);
            setPostEnd(e.target.value ? Number(e.target.value) : null);
          }}
          sx={{ m: 0.5 }}
        />
      </FormControl>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={100}>序号</TableCell>
              <TableCell>用户</TableCell>
              <TableCell>账户</TableCell>
              <TableCell>变动业务ID</TableCell>
              <TableCell>变动日期</TableCell>
              <TableCell>变动类型</TableCell>
              <TableCell>变动前余额</TableCell>
              <TableCell>变动后余额</TableCell>
              <TableCell width={70}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_state.page.items?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  {globals.dictPvtVal('sys_user.id', row.userId.toString())}
                </TableCell>
                <TableCell>
                  {globals.dictPvtVal('mcb_account.id', row.accountId.toString())}
                </TableCell>
                <TableCell>{row.sourceId.toString()}</TableCell>
                <TableCell>{row.sourceAt}</TableCell>
                <TableCell>
                  {globals.dictPubVal('mcb_balance.genre', row.genre.toString())}
                </TableCell>
                <TableCell>{row.pre.toString()}</TableCell>
                <TableCell>{row.post.toString()}</TableCell>
                <TableCell>
                  <Tooltip title="修改">
                    <IconButton
                      size="small"
                      onClick={() => openFormDialog(row, false)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="删除">
                    <IconButton
                      size="small"
                      onClick={(event) => openDelDialog(row.id, '')}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Nil colSpan={9} rowTotal={_state.page.total} />
        </Table>
        <TablePagination
          component="div"
          count={_state.page.total}
          page={_state.page.page - 1}
          onPageChange={onPageChange}
          rowsPerPage={_state.page.perPage}
          onRowsPerPageChange={onPerPageChange}
          sx={{ mx: 2 }}
        />
      </TableContainer>
      {/* 表单编辑 */}
      <Dialog fullScreen open={isOpenFormDialog} onClose={closeFormDialog}>
        <AppBar elevation={0}>
          <Toolbar>
            <Tooltip title="返回">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <ArrowBackIos />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              {isIns ? '余额变动添加' : '余额变动修改'}
            </Typography>
            <Tooltip title="保存">
              <IconButton color="inherit" onClick={closeFormDialog}>
                <Save />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <FormControl sx={{ mx: 2, mt: 9, mb: 2 }}>
          <TextField
            fullWidth
            variant="standard"
            label="用户"
            type="number"
            value={_state.userId.val}
            error={_state.userId.err}
            helperText={_state.userId.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setUserId',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="账户"
            type="number"
            value={_state.accountId.val}
            error={_state.accountId.err}
            helperText={_state.accountId.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setAccountId',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="变动业务ID"
            type="number"
            value={_state.sourceId.val}
            error={_state.sourceId.err}
            helperText={_state.sourceId.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setSourceId',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="变动日期"
            type="string"
            value={_state.sourceAt.val}
            error={_state.sourceAt.err}
            helperText={_state.sourceAt.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setSourceAt',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="变动类型"
            type="number"
            value={_state.genre.val}
            error={_state.genre.err}
            helperText={_state.genre.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setGenre',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="变动前余额"
            type="number"
            value={_state.pre.val}
            error={_state.pre.err}
            helperText={_state.pre.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setPre',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="变动后余额"
            type="number"
            value={_state.post.val}
            error={_state.post.err}
            helperText={_state.post.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setPost',
                payload: e.target.value ? Number(e.target.value) : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="备注"
            type="string"
            value={_state.memo.val}
            error={_state.memo.err}
            helperText={_state.memo.hlp}
            onChange={(e) =>
              dispatch({
                type: 'setMemo',
                payload: e.target.value ? e.target.value : null,
              })
            }
            sx={{ mt: 1 }}
          />
          <span style={{ marginTop: 20 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeFormDialog}
            >
              返回
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={submit}
              sx={{ ml: 2 }}
            >
              保存
            </Button>
          </span>
        </FormControl>
      </Dialog>
      <Snack ref={snackRef} content={snackContent} />
      <DelDialog ref={delDialogRef} onOk={onDel} />
    </Fragment>
  );
}
