import Linear, { initLinear } from 'vts/models/core/Linear';

/// 菜单资源
export default interface MenuRes extends Linear {
  /// 菜单
  menuId: number;

  /// 资源
  resId: number;
}

export const newMenuRes = {
  ...initLinear,
  menuId: 0,
  resId: 0,
};
