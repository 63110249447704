import Linear, { initLinear } from 'vts/models/core/Linear';

/// 车型
export default interface Model extends Linear {
  /// 名称
  name: string;

  /// 排序
  ordinal: number;
}

export const newModel = {
  ...initLinear,
  name: '',
  ordinal: 0,
};
