import Exam from '../../ses/models/Exam';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 考试 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 考试时间
  examAt: FormField<string | null>;

  /// 分页
  page: Page<Exam>;
}

/// 考试 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  examAt: initField(''),
  page: initPage<Exam>(),
};

export default initState;
