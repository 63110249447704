import Customer from '../../gsb/models/Customer';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 客户 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 电话
  telephone: FormField<string | null>;

  /// 性别
  sex: FormField<number | null>;

  /// 邮件
  email: FormField<string | null>;

  /// QQ
  qq: FormField<string | null>;

  /// 分页
  page: Page<Customer>;
}

/// 客户 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  telephone: initField(''),
  sex: initField(20),
  email: initField(''),
  qq: initField(''),
  page: initPage<Customer>(),
};

export default initState;
