import Student from '../../ses/models/Student';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 学生 状态
export interface State extends Linear {
  /// 姓名
  name: FormField<string | null>;

  /// 学号
  sid: FormField<string | null>;

  /// 入学时间
  enrolAt: FormField<string | null>;

  /// 性别
  sex: FormField<number | null>;

  /// 分页
  page: Page<Student>;
}

/// 学生 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  sid: initField(''),
  enrolAt: initField(''),
  sex: initField(0),
  page: initPage<Student>(),
};

export default initState;
