import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  organId: [validators.gt(0)],
  organName: [validators.required()],
  organGenre: [validators.gt(0)],
  userId: [validators.gt(0)],
  nickname: [validators.required()],
  phone: [validators.phone()],
  email: [validators.email()],
  joinAt: [validators.required()],
  usable: [validators.required()],
  state: [validators.gt(0)],
});

export default validator;
