import Bind from '../../ses/models/Bind';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 绑定学生 状态
export interface State extends Linear {
  /// 用户
  userId: FormField<number | null>;

  /// 学生
  studentId: FormField<number | null>;

  /// 分页
  page: Page<Bind>;
}

/// 绑定学生 初始状态
const initState: State = {
  ...initLinear,
  userId: initField(0),
  studentId: initField(0),
  page: initPage<Bind>(),
};

export default initState;
