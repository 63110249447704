import Process from '../../bpm/models/Process';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 流程 状态
export interface State extends Linear {
  /// 过程
  defId: FormField<string | null>;

  /// 名称
  name: FormField<string | null>;

  /// 部门
  deptId: FormField<number | null>;

  /// 岗位
  postId: FormField<number | null>;

  /// 进度
  taskId: FormField<string | null>;

  /// 排序
  ordinal: FormField<number | null>;

  /// 分页
  page: Page<Process>;
}

/// 流程 初始状态
const initState: State = {
  ...initLinear,
  defId: initField(''),
  name: initField(''),
  deptId: initField(0),
  postId: initField(0),
  taskId: initField(''),
  ordinal: initField(0),
  page: initPage<Process>(),
};

export default initState;
