import Linear, { initLinear } from 'vts/models/core/Linear';

/// 配置
export default interface Conf extends Linear {
  /// 名称
  name: string;

  /// 编号
  sn: string;

  /// 内容
  content: string;

  /// 配置值
  value: number;

  /// 类型
  genre: number;

  /// 可用
  usable: boolean;

  /// 排序
  ordinal: number;
}

export const newConf = {
  ...initLinear,
  name: '',
  sn: '',
  content: '',
  value: 0,
  genre: 0,
  usable: false,
  ordinal: 0,
};
