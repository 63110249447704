import Order from '../../gsb/models/Order';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 订单 状态
export interface State extends Linear {
  /// 客户
  customerId: FormField<number | null>;

  /// 零售日期
  salesAt: FormField<string | null>;

  /// 进货合计
  amountIn: FormField<number | null>;

  /// 零售合计
  amountOut: FormField<number | null>;

  /// 状态
  status: FormField<number | null>;

  /// 分页
  page: Page<Order>;
}

/// 订单 初始状态
const initState: State = {
  ...initLinear,
  customerId: initField(0),
  salesAt: initField(''),
  amountIn: initField(0.0),
  amountOut: initField(0.0),
  status: initField(10),
  page: initPage<Order>(),
};

export default initState;
