import { State } from '../../sys/states/feedbacks';
import Action from '../../sys/actions/feedbacks';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        platform: {
          val: action.payload.platform,
          err: false,
          hlp: '',
        },
        genre: {
          val: action.payload.genre,
          err: false,
          hlp: '',
        },
        contact: {
          val: action.payload.contact,
          err: false,
          hlp: '',
        },
        content: {
          val: action.payload.content,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 使用平台 值
    case 'setPlatform':
      return {
        ...state,
        platform: {
          ...state.platform,
          val: action.payload,
        },
      };
    /// 设置 类型 值
    case 'setGenre':
      return {
        ...state,
        genre: {
          ...state.genre,
          val: action.payload,
        },
      };
    /// 设置 您的联系方式 值
    case 'setContact':
      return {
        ...state,
        contact: {
          ...state.contact,
          val: action.payload,
        },
      };
    /// 设置 反馈内容 值
    case 'setContent':
      return {
        ...state,
        content: {
          ...state.content,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        platform: {
          ...state.platform,
          err: hasError(action.payload.platform),
          hlp: getFirstErrMsg(action.payload.platform),
        },
        genre: {
          ...state.genre,
          err: hasError(action.payload.genre),
          hlp: getFirstErrMsg(action.payload.genre),
        },
        contact: {
          ...state.contact,
          err: hasError(action.payload.contact),
          hlp: getFirstErrMsg(action.payload.contact),
        },
        content: {
          ...state.content,
          err: hasError(action.payload.content),
          hlp: getFirstErrMsg(action.payload.content),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        platform: initField(state.platform.val),
        genre: initField(state.genre.val),
        contact: initField(state.contact.val),
        content: initField(state.content.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
