import Page from 'vts/models/core/Page';
import Resp from 'vts/models/core/Resp';
import axios, { AxiosResponse } from 'vts/utils/axios';
import Contact from '../../gpt/models/Contact';

// 查询
export async function find(params?: any) {
  return await axios.post<any, AxiosResponse<Page<Contact>>>(
    '/gpt/contact/find',
    params
  );
}

// 添加
export async function ins(params?: any) {
  return await axios.post<any, AxiosResponse<Contact>>(
    '/gpt/contact/ins',
    params
  );
}

// 删除
export async function del(params?: any) {
  return await axios.post<any, AxiosResponse<Resp>>(
    '/gpt/contact/del',
    params
  );
}

// 修改
export async function upd(params?: any) {
  return await axios.post<any, AxiosResponse<Contact>>(
    '/gpt/contact/upd',
    params
  );
}

// 查看
export async function see(params?: any) {
  return await axios.post<any, AxiosResponse<Contact>>(
    '/gpt/contact/see',
    params
  );
}

// 导入
export async function imp(params?: any) {
  const header = { headers: { 'Content-Type': 'multipart/form-data' } };
  return await axios.post<any, AxiosResponse<Resp>>(
    '/gpt/contact/imp',
    params,
    header
  );
}
