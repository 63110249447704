import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  nature: [validators.required()],
  govId: [validators.gt(0)],
  govName: [validators.required()],
  brandId: [validators.gt(0)],
  brandName: [validators.required()],
  modelId: [validators.gt(0)],
  modelName: [validators.required()],
  sn: [validators.required()],
  usesId: [validators.gt(0)],
  usesName: [validators.required()],
});

export default validator;
