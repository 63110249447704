import Linear, { initLinear } from 'vts/models/core/Linear';

/// 通讯录
export default interface Contact extends Linear {
  /// 名称
  name: string;

  /// 性别
  sex: number;

  /// 生日
  birthday: string;

  /// 电话
  telephone: string;

  /// 工作单位
  work: string;

  /// 现居住地
  residence: string;

  /// 家庭地址
  family: string;
}

export const newContact = {
  ...initLinear,
  name: '',
  sex: 0,
  birthday: '',
  telephone: '',
  work: '',
  residence: '',
  family: '',
};
