import TaskDept from '../../bpm/models/TaskDept';
import { ROOT } from 'vts/consts/id';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 任务部门 状态
export interface State extends Linear {
  /// 上一步部门任务ID
  fromId: FormField<number | null>;

  /// 上一步待办部门ID
  fromDeptId: FormField<number | null>;

  /// 父任务ID
  parentId: FormField<number | null>;

  /// 任务
  taskId: FormField<number | null>;

  /// 部门
  deptId: FormField<number | null>;

  /// 当前步意见
  opinion: FormField<string | null>;

  /// 操作
  operate: FormField<string | null>;

  /// 状态
  status: FormField<number | null>;

  /// 分页
  page: Page<TaskDept>;
}

/// 任务部门 初始状态
const initState: State = {
  ...initLinear,
  fromId: initField(ROOT),
  fromDeptId: initField(ROOT),
  parentId: initField(ROOT),
  taskId: initField(0),
  deptId: initField(0),
  opinion: initField(''),
  operate: initField(''),
  status: initField(10),
  page: initPage<TaskDept>(),
};

export default initState;
