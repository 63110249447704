import Page from 'vts/models/core/Page';
import Resp, { RespStr } from 'vts/models/core/Resp';
import axios, { AxiosResponse } from 'vts/utils/axios';
import Car from '../../gpc/models/Car';

// 查询
export async function find(params?: any) {
  return await axios.post<any, AxiosResponse<Page<Car>>>(
    '/gpc/car/find',
    params
  );
}

// 添加
export async function ins(params?: any) {
  return await axios.post<any, AxiosResponse<Car>>(
    '/gpc/car/ins',
    params
  );
}

// 删除
export async function del(params?: any) {
  return await axios.post<any, AxiosResponse<Resp>>(
    '/gpc/car/del',
    params
  );
}

// 修改
export async function upd(params?: any) {
  return await axios.post<any, AxiosResponse<Car>>(
    '/gpc/car/upd',
    params
  );
}

// 查看
export async function see(params?: any) {
  return await axios.post<any, AxiosResponse<Car>>(
    '/gpc/car/see',
    params
  );
}

// 导出
export async function exp(params?: any) {
  return await axios.post<any, AxiosResponse<RespStr>>(
    '/gpc/car/exp',
    params
  );
}

// 导入
export async function imp(params?: any) {
  const header = { headers: { 'Content-Type': 'multipart/form-data' } };
  return await axios.post<any, AxiosResponse<Resp>>(
    '/gpc/car/imp',
    params,
    header
  );
}
