import Page from 'vts/models/core/Page';
import Resp from 'vts/models/core/Resp';
import axios, { AxiosResponse } from 'vts/utils/axios';
import Grade from '../../kpi/models/Grade';

// 查询
export async function find(params?: any) {
  return await axios.post<any, AxiosResponse<Page<Grade>>>(
    '/kpi/grade/find',
    params
  );
}

// 添加
export async function ins(params?: any) {
  return await axios.post<any, AxiosResponse<Grade>>(
    '/kpi/grade/ins',
    params
  );
}

// 删除
export async function del(params?: any) {
  return await axios.post<any, AxiosResponse<Resp>>(
    '/kpi/grade/del',
    params
  );
}

// 修改
export async function upd(params?: any) {
  return await axios.post<any, AxiosResponse<Grade>>(
    '/kpi/grade/upd',
    params
  );
}

// 查看
export async function see(params?: any) {
  return await axios.post<any, AxiosResponse<Grade>>(
    '/kpi/grade/see',
    params
  );
}
