import {
  ChangeEvent,
  Fragment,
  useEffect,
  useState,
  useRef,
  useReducer,
} from 'react';
import {
  Tabs,
  Tab,
  TextField,
  Button,
  Stack,
  Divider,
  FormControl,
} from '@mui/material';
import AsyncValidator from 'async-validator';
import axios, { AxiosResponse } from 'vts/utils/axios';
import Resp from 'vts/models/core/Resp';
import Nav from './Nav';
import initState from 'vts/states/cmn/passwd';
import reducer from 'vts/reducers/cmn/passwd';
import Snack from 'vts/components/snacks/Snack';
import OpenSnack from 'vts/interfaces/snack';
import Auth from 'vts/models/core/Auth';
import initAuthState from 'vts/states/core/auth';
import { getAuth } from 'vts/utils/globals';

export default function HomeScreen() {
  const [state, dispatch] = useReducer(reducer, initState);

  // 需要注意的是如果 ref 为元素，那么初始值得写个 null 才不会报错
  const snackRef = useRef<OpenSnack>(null);

  const [value, setValue] = useState(0);

  const [auth, setAuth] = useState<Auth>(initAuthState);

  const [snackContent, setSnackContent] = useState('密码修改成功');

  useEffect(() => setAuth(getAuth()), []);

  const validator = new AsyncValidator({
    oldPasswd: [
      {
        required: true,
        message: '请填写旧密码',
      },
      {
        pattern: /^[a-zA-Z0-9_-]{6,}$/,
        message: '由长度不小于6的字母或数字或下划线组成',
      },
    ],
    newPasswd: [
      {
        required: true,
        message: '请填写新密码',
      },
      {
        pattern: /^[a-zA-Z0-9_-]{6,}$/,
        message: '由长度不小于6的字母或数字或下划线组成',
      },
    ],
    okPasswd: [
      {
        required: true,
        message: '请填写确认新密码',
      },
      {
        pattern: /^[a-zA-Z0-9_-]{6,}$/,
        message: '由长度不小于6的字母或数字或下划线组成',
      },
      {
        validator: (rule, value) => value === state.newPasswd.val,
        message: '您的输入与 新密码 不相同',
      },
    ],
  });

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const submit = () => {
    validator
      .validate({
        oldPasswd: state.oldPasswd.val,
        newPasswd: state.newPasswd.val,
        okPasswd: state.okPasswd.val,
      })
      .then(() => {
        axios
          .post<any, AxiosResponse<Resp>>('/sys/user/repasswd', {
            oldPasswd: state.oldPasswd.val,
            newPasswd: state.newPasswd.val,
          })
          .then(function (resp) {
            if (resp.data.success) {
              dispatch({ type: 'reset' });
              snackRef.current?.open();
            } else {
              setSnackContent(resp.data.head);
              snackRef.current?.open();
            }
          });
      })
      .catch(({ errors, fields }) => {
        dispatch({ type: 'validFail', payload: fields });
      });
  };

  return (
    <Fragment>
      <Nav />
      <Stack mt={8} mx={3}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="个人信息" />
          <Tab label="修改密码" />
        </Tabs>
        <Divider />

        <FormControl sx={{ display: value === 0 ? 'block' : 'none' }}>
          <TextField
            fullWidth
            variant="standard"
            label="用户名"
            value={auth.username}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="姓名"
            value={auth.name}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mt: 2 }}
          />
        </FormControl>

        <FormControl sx={{ display: value === 1 ? 'block' : 'none' }}>
          <TextField
            fullWidth
            variant="standard"
            type="password"
            label="旧密码"
            placeholder="请输入旧密码"
            value={state.oldPasswd.val}
            error={state.oldPasswd.err}
            helperText={state.oldPasswd.hlp}
            onChange={(e) =>
              dispatch({ type: 'setOldPasswd', payload: e.target.value })
            }
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            variant="standard"
            type="password"
            label="新密码"
            placeholder="请输入新密码"
            value={state.newPasswd.val}
            error={state.newPasswd.err}
            helperText={state.newPasswd.hlp}
            onChange={(e) =>
              dispatch({ type: 'setNewPasswd', payload: e.target.value })
            }
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            variant="standard"
            type="password"
            label="确认新密码"
            placeholder="请再次输入新密码"
            value={state.okPasswd.val}
            error={state.okPasswd.err}
            helperText={state.okPasswd.hlp}
            onChange={(e) =>
              dispatch({ type: 'setOkPasswd', payload: e.target.value })
            }
            sx={{ mt: 2 }}
          />
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            size="large"
            onClick={submit}
            sx={{ mt: 4 }}
          >
            提交
          </Button>
        </FormControl>
        <Snack ref={snackRef} content={snackContent} />
      </Stack>
    </Fragment>
  );
}
