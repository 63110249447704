import { State } from '../../gpt/states/contact';
import Action from '../../gpt/actions/contact';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        name: {
          val: action.payload.name,
          err: false,
          hlp: '',
        },
        parentId: {
          val: action.payload.parentId,
          err: false,
          hlp: '',
        },
        ordinal: {
          val: action.payload.ordinal,
          err: false,
          hlp: '',
        },
        genre: {
          val: action.payload.genre,
          err: false,
          hlp: '',
        },
        phone: {
          val: action.payload.phone,
          err: false,
          hlp: '',
        },
        shortTel: {
          val: action.payload.shortTel,
          err: false,
          hlp: '',
        },
        tel: {
          val: action.payload.tel,
          err: false,
          hlp: '',
        },
        otherTel: {
          val: action.payload.otherTel,
          err: false,
          hlp: '',
        },
        workId: {
          val: action.payload.workId,
          err: false,
          hlp: '',
        },
        work: {
          val: action.payload.work,
          err: false,
          hlp: '',
        },
        department: {
          val: action.payload.department,
          err: false,
          hlp: '',
        },
        position: {
          val: action.payload.position,
          err: false,
          hlp: '',
        },
        office: {
          val: action.payload.office,
          err: false,
          hlp: '',
        },
        email: {
          val: action.payload.email,
          err: false,
          hlp: '',
        },
        sex: {
          val: action.payload.sex,
          err: false,
          hlp: '',
        },
        birthday: {
          val: action.payload.birthday,
          err: false,
          hlp: '',
        },
        address: {
          val: action.payload.address,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 名称 值
    case 'setName':
      return {
        ...state,
        name: {
          ...state.name,
          val: action.payload,
        },
      };
    /// 设置 父标识 值
    case 'setParentId':
      return {
        ...state,
        parentId: {
          ...state.parentId,
          val: action.payload,
        },
      };
    /// 设置 排序 值
    case 'setOrdinal':
      return {
        ...state,
        ordinal: {
          ...state.ordinal,
          val: action.payload,
        },
      };
    /// 设置 类型 值
    case 'setGenre':
      return {
        ...state,
        genre: {
          ...state.genre,
          val: action.payload,
        },
      };
    /// 设置 手机 值
    case 'setPhone':
      return {
        ...state,
        phone: {
          ...state.phone,
          val: action.payload,
        },
      };
    /// 设置 小号 值
    case 'setShortTel':
      return {
        ...state,
        shortTel: {
          ...state.shortTel,
          val: action.payload,
        },
      };
    /// 设置 固话 值
    case 'setTel':
      return {
        ...state,
        tel: {
          ...state.tel,
          val: action.payload,
        },
      };
    /// 设置 内线专线 值
    case 'setOtherTel':
      return {
        ...state,
        otherTel: {
          ...state.otherTel,
          val: action.payload,
        },
      };
    /// 设置 单位 值
    case 'setWorkId':
      return {
        ...state,
        workId: {
          ...state.workId,
          val: action.payload,
        },
      };
    /// 设置 单位 值
    case 'setWork':
      return {
        ...state,
        work: {
          ...state.work,
          val: action.payload,
        },
      };
    /// 设置 部门 值
    case 'setDepartment':
      return {
        ...state,
        department: {
          ...state.department,
          val: action.payload,
        },
      };
    /// 设置 职务 值
    case 'setPosition':
      return {
        ...state,
        position: {
          ...state.position,
          val: action.payload,
        },
      };
    /// 设置 办公室 值
    case 'setOffice':
      return {
        ...state,
        office: {
          ...state.office,
          val: action.payload,
        },
      };
    /// 设置 邮件 值
    case 'setEmail':
      return {
        ...state,
        email: {
          ...state.email,
          val: action.payload,
        },
      };
    /// 设置 性别 值
    case 'setSex':
      return {
        ...state,
        sex: {
          ...state.sex,
          val: action.payload,
        },
      };
    /// 设置 生日 值
    case 'setBirthday':
      return {
        ...state,
        birthday: {
          ...state.birthday,
          val: action.payload,
        },
      };
    /// 设置 地址 值
    case 'setAddress':
      return {
        ...state,
        address: {
          ...state.address,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        name: {
          ...state.name,
          err: hasError(action.payload.name),
          hlp: getFirstErrMsg(action.payload.name),
        },
        parentId: {
          ...state.parentId,
          err: hasError(action.payload.parentId),
          hlp: getFirstErrMsg(action.payload.parentId),
        },
        ordinal: {
          ...state.ordinal,
          err: hasError(action.payload.ordinal),
          hlp: getFirstErrMsg(action.payload.ordinal),
        },
        genre: {
          ...state.genre,
          err: hasError(action.payload.genre),
          hlp: getFirstErrMsg(action.payload.genre),
        },
        phone: {
          ...state.phone,
          err: hasError(action.payload.phone),
          hlp: getFirstErrMsg(action.payload.phone),
        },
        shortTel: {
          ...state.shortTel,
          err: hasError(action.payload.shortTel),
          hlp: getFirstErrMsg(action.payload.shortTel),
        },
        tel: {
          ...state.tel,
          err: hasError(action.payload.tel),
          hlp: getFirstErrMsg(action.payload.tel),
        },
        otherTel: {
          ...state.otherTel,
          err: hasError(action.payload.otherTel),
          hlp: getFirstErrMsg(action.payload.otherTel),
        },
        workId: {
          ...state.workId,
          err: hasError(action.payload.workId),
          hlp: getFirstErrMsg(action.payload.workId),
        },
        work: {
          ...state.work,
          err: hasError(action.payload.work),
          hlp: getFirstErrMsg(action.payload.work),
        },
        department: {
          ...state.department,
          err: hasError(action.payload.department),
          hlp: getFirstErrMsg(action.payload.department),
        },
        position: {
          ...state.position,
          err: hasError(action.payload.position),
          hlp: getFirstErrMsg(action.payload.position),
        },
        office: {
          ...state.office,
          err: hasError(action.payload.office),
          hlp: getFirstErrMsg(action.payload.office),
        },
        email: {
          ...state.email,
          err: hasError(action.payload.email),
          hlp: getFirstErrMsg(action.payload.email),
        },
        sex: {
          ...state.sex,
          err: hasError(action.payload.sex),
          hlp: getFirstErrMsg(action.payload.sex),
        },
        birthday: {
          ...state.birthday,
          err: hasError(action.payload.birthday),
          hlp: getFirstErrMsg(action.payload.birthday),
        },
        address: {
          ...state.address,
          err: hasError(action.payload.address),
          hlp: getFirstErrMsg(action.payload.address),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        name: initField(state.name.val),
        parentId: initField(state.parentId.val),
        ordinal: initField(state.ordinal.val),
        genre: initField(state.genre.val),
        phone: initField(state.phone.val),
        shortTel: initField(state.shortTel.val),
        tel: initField(state.tel.val),
        otherTel: initField(state.otherTel.val),
        workId: initField(state.workId.val),
        work: initField(state.work.val),
        department: initField(state.department.val),
        position: initField(state.position.val),
        office: initField(state.office.val),
        email: initField(state.email.val),
        sex: initField(state.sex.val),
        birthday: initField(state.birthday.val),
        address: initField(state.address.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
