import Brand from '../../gpc/models/Brand';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 品牌 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 排序
  ordinal: FormField<number | null>;

  /// 分页
  page: Page<Brand>;
}

/// 品牌 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  ordinal: initField(0),
  page: initPage<Brand>(),
};

export default initState;
