import {
  AccountBalanceWallet,
  AccountCircle,
  AccountTree,
  AlarmOn,
  ArrowDropDownCircle,
  AttachMoney,
  AvTimer,
  Beenhere,
  ChangeHistory,
  Compare,
  Contacts,
  DeviceHub,
  DoneAll,
  Dvr,
  EventSeat,
  Extension,
  Feedback,
  GolfCourse,
  Grade,
  HourglassEmpty,
  InsertLink,
  Insights,
  Layers,
  LocalActivity,
  LocalConvenienceStore,
  LocalShipping,
  MeetingRoom,
  MenuOpen,
  OtherHouses,
  Palette,
  Payment,
  PermContactCalendar,
  Person,
  PersonPin,
  Rowing,
  RvHookup,
  School,
  Shop,
  ShoppingCart,
  Stars,
  Storage,
  Subscriptions,
  TrendingUp,
  ViewList,
} from '@mui/icons-material';
import {
  GENRE_GSB,
  GENRE_MCB,
  GENRE_SES,
  GENRE_CMP,
  GENRE_KPI,
  GENRE_GPC,
  GENRE_GPT,
} from './sys/models/Organ';
import { GENRE_SA, GENRE_ADMIN, GENRE_BASIC } from './sys/models/User';

const menus = [
  {
    organGenre: GENRE_GSB,
    userGenre: GENRE_BASIC,
    title: '商品销售记账',
    children: [
      {
        path: '/gsb/account',
        title: '账户',
        icon: AccountCircle,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/recharge',
        title: '充值',
        icon: AttachMoney,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/genre',
        title: '商品分类',
        icon: DeviceHub,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/product',
        title: '商品',
        icon: Palette,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/goods',
        title: '库存商品',
        icon: Shop,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/customer',
        title: '客户',
        icon: Contacts,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/address',
        title: '订单',
        icon: LocalConvenienceStore,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/cart',
        title: '购物车',
        icon: ShoppingCart,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/payment',
        title: '支付',
        icon: Payment,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/logistics',
        title: '物流',
        icon: LocalShipping,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/balance',
        title: '余额变动',
        icon: AccountBalanceWallet,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/profit',
        title: '利润变动',
        icon: ChangeHistory,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gsb/stock',
        title: '库存变动',
        icon: RvHookup,
        userGenre: GENRE_BASIC,
      },
    ],
  },
  {
    organGenre: GENRE_MCB,
    userGenre: GENRE_BASIC,
    title: '会员消费记账',
    children: [
      {
        path: '/mcb/account',
        title: '账户',
        icon: AccountCircle,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/mcb/record',
        title: '消费流水',
        icon: ViewList,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/mcb/balance',
        title: '余额变动',
        icon: AccountBalanceWallet,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/mcb/activity',
        title: '活动',
        icon: LocalActivity,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/mcb/partake',
        title: '参与的活动',
        icon: Rowing,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/mcb/recharge',
        title: '充值',
        icon: AttachMoney,
        userGenre: GENRE_BASIC,
      },
    ],
  },
  {
    organGenre: GENRE_SES,
    userGenre: GENRE_BASIC,
    title: '学生考试成绩',
    children: [
      {
        path: '/ses/score',
        title: '成绩',
        icon: Stars,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/ses/exam',
        title: '考试',
        icon: Layers,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/ses/student',
        title: '学生',
        icon: School,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/ses/course',
        title: '课程',
        icon: GolfCourse,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/ses/bind',
        title: '绑定学生',
        icon: InsertLink,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/ses/apply',
        title: '我的申请',
        icon: Subscriptions,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/ses/contact',
        title: '通讯录',
        icon: Contacts,
        userGenre: GENRE_BASIC,
      },
    ],
  },
  {
    organGenre: GENRE_CMP,
    userGenre: GENRE_BASIC,
    title: '同学周年集会',
    children: [
      {
        path: '/cmp/contrast',
        title: '对照表',
        icon: Compare,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/cmp/contact',
        title: '通讯录',
        icon: Contacts,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/cmp/enroll',
        title: '报名表',
        icon: Subscriptions,
        userGenre: GENRE_BASIC,
      },
    ],
  },
  {
    organGenre: GENRE_GPC,
    userGenre: GENRE_BASIC,
    title: '公务车',
    children: [
      {
        path: '/gpc/car/xzdw',
        title: '行政单位',
        icon: Insights,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gpc/car/sydw',
        title: '事业单位',
        icon: Layers,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gpc/car/qwzs',
        title: '旗委直属',
        icon: Extension,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gpc/car/zfzs',
        title: '政府直属',
        icon: OtherHouses,
        userGenre: GENRE_BASIC,
      },
    ],
  },
  {
    organGenre: GENRE_GPT,
    userGenre: GENRE_BASIC,
    title: '电话通',
    children: [
      {
        path: '/gpt/contact',
        title: '通讯录',
        icon: PermContactCalendar,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gpt/user_catalog',
        title: '前台用户',
        icon: Person,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/gpt/adm_user',
        title: '后台用户',
        icon: PersonPin,
        userGenre: GENRE_SA,
      },
      {
        path: '/gpt/first_login',
        title: '首次登录用户',
        icon: AlarmOn,
        userGenre: GENRE_BASIC,
      },
    ],
  },
  {
    organGenre: GENRE_KPI,
    userGenre: GENRE_BASIC,
    title: '公司绩效考核',
    children: [
      {
        path: '/kpi/item',
        title: '考核项目',
        icon: Dvr,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/kpi/mark',
        title: '个人考核',
        icon: Beenhere,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/kpi/grade',
        title: '部门考核',
        icon: Grade,
        userGenre: GENRE_BASIC,
      },
    ],
  },
  {
    organGenre: GENRE_KPI,
    userGenre: GENRE_BASIC,
    title: '流程',
    children: [
      {
        path: '/bpm/process',
        title: '流程',
        icon: HourglassEmpty,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/bpm/task',
        title: '任务',
        icon: DoneAll,
        userGenre: GENRE_BASIC,
      },
    ],
  },
  {
    organGenre: 0,
    userGenre: GENRE_ADMIN,
    title: '公共',
    children: [
      {
        path: '/cmn/msg',
        title: '消息',
        icon: AvTimer,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/cmn/my',
        title: '我的',
        icon: Person,
        userGenre: GENRE_BASIC,
      },
    ],
  },
  {
    organGenre: 0,
    userGenre: GENRE_ADMIN,
    title: '系统',
    children: [
      {
        path: '/sys/dict',
        title: '字典',
        icon: ArrowDropDownCircle,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/sys/area',
        title: '区域',
        icon: DeviceHub,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/sys/organ',
        title: '机构',
        icon: AccountTree,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/sys/dept',
        title: '部门',
        icon: MeetingRoom,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/sys/user',
        title: '用户',
        icon: Person,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/sys/post',
        title: '岗位',
        icon: EventSeat,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/sys/menu',
        title: '菜单',
        icon: MenuOpen,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/sys/res',
        title: '资源',
        icon: Storage,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/sys/traces',
        title: '轨迹',
        icon: TrendingUp,
        userGenre: GENRE_BASIC,
      },
      {
        path: '/sys/feedbacks',
        title: '反馈',
        icon: Feedback,
        userGenre: GENRE_BASIC,
      },
    ],
  },
];

export default menus;
