import Linear, { initLinear } from 'vts/models/core/Linear';

/// 商品
export default interface Product extends Linear {
  /// 名称
  name: string;

  /// 账户
  accountId: number;

  /// 分类
  genreId: number;

  /// 进价
  priceIn: number;

  /// 单价
  priceOut: number;
}

export const newProduct = {
  ...initLinear,
  name: '',
  accountId: 0,
  genreId: 0,
  priceIn: 0.0,
  priceOut: 0.0,
};
