import Linear, { initLinear } from 'vts/models/core/Linear';

/// 绑定学生
export default interface Bind extends Linear {
  /// 用户
  userId: number;

  /// 学生
  studentId: number;
}

export const newBind = {
  ...initLinear,
  userId: 0,
  studentId: 0,
};
