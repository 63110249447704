import Sms from '../../sys/models/Sms';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 短信 状态
export interface State extends Linear {
  /// 手机号列表
  phones: FormField<string | null>;

  /// 短信签名
  signName: FormField<string | null>;

  /// 模板编号
  templateCode: FormField<string | null>;

  /// 模板变量值
  templateParams: FormField<string | null>;

  /// 短信内容
  content: FormField<string | null>;

  /// 发送回执ID
  bizId: FormField<string | null>;

  /// 类型
  genre: FormField<number | null>;

  /// 状态
  status: FormField<number | null>;

  /// 其他
  other: FormField<string | null>;

  /// 分页
  page: Page<Sms>;
}

/// 短信 初始状态
const initState: State = {
  ...initLinear,
  phones: initField(''),
  signName: initField(''),
  templateCode: initField(''),
  templateParams: initField(''),
  content: initField(''),
  bizId: initField(''),
  genre: initField(0),
  status: initField(0),
  other: initField(''),
  page: initPage<Sms>(),
};

export default initState;
