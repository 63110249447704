import Linear, { initLinear } from 'vts/models/core/Linear';

export const STATUS_WAIT = 10; // 待审
export const STATUS_PASS = 20; // 确认
export const STATUS_BACK = 30; // 驳回

/// 消费流水
export default interface Record extends Linear {
  /// 用户
  userId: number;

  /// 账户
  accountId: number;

  /// 摘要
  summary: string;

  /// 余额
  balance: number;

  /// 余额单位
  unit: string;

  /// 时间
  recordAt: string;

  /// 状态
  status: number;
}

export const newRecord = {
  ...initLinear,
  userId: 0,
  accountId: 0,
  summary: '',
  balance: 0.0,
  unit: '',
  recordAt: '',
  status: 10,
};
