import { State } from '../../cmn/states/my';
import Action from '../../cmn/actions/my';
import { hasError, getFirstErrMsg } from 'vts/utils/validators';
import { initField } from 'vts/models/core/FormField';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    /// 查询后
    case 'found':
      return {
        ...state,
        page: action.payload,
      };
    /// 设置 当前页数 值
    case 'setPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: action.payload,
        },
      };
    /// 设置 每页行数 值
    case 'setPerPage':
      return {
        ...state,
        page: {
          ...state.page,
          page: 1,
          perPage: action.payload,
        },
      };
    /// 设置 Model 值
    case 'setModel':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload.id,
        },
        organId: {
          val: action.payload.organId,
          err: false,
          hlp: '',
        },
        organName: {
          val: action.payload.organName,
          err: false,
          hlp: '',
        },
        organGenre: {
          val: action.payload.organGenre,
          err: false,
          hlp: '',
        },
        userId: {
          val: action.payload.userId,
          err: false,
          hlp: '',
        },
        nickname: {
          val: action.payload.nickname,
          err: false,
          hlp: '',
        },
        phone: {
          val: action.payload.phone,
          err: false,
          hlp: '',
        },
        email: {
          val: action.payload.email,
          err: false,
          hlp: '',
        },
        isPub: {
          val: action.payload.isPub,
          err: false,
          hlp: '',
        },
        isSwap: {
          val: action.payload.isSwap,
          err: false,
          hlp: '',
        },
        isDefault: {
          val: action.payload.isDefault,
          err: false,
          hlp: '',
        },
        joinAt: {
          val: action.payload.joinAt,
          err: false,
          hlp: '',
        },
        usable: {
          val: action.payload.usable,
          err: false,
          hlp: '',
        },
        state: {
          val: action.payload.state,
          err: false,
          hlp: '',
        },
        memo: {
          val: action.payload.memo,
          err: false,
          hlp: '',
        },
      };
    /// 设置 ID 值
    case 'setId':
      return {
        ...state,
        id: {
          ...state.id,
          val: action.payload,
        },
      };
    /// 设置 机构 值
    case 'setOrganId':
      return {
        ...state,
        organId: {
          ...state.organId,
          val: action.payload,
        },
      };
    /// 设置 机构名称 值
    case 'setOrganName':
      return {
        ...state,
        organName: {
          ...state.organName,
          val: action.payload,
        },
      };
    /// 设置 机构类型 值
    case 'setOrganGenre':
      return {
        ...state,
        organGenre: {
          ...state.organGenre,
          val: action.payload,
        },
      };
    /// 设置 用户 值
    case 'setUserId':
      return {
        ...state,
        userId: {
          ...state.userId,
          val: action.payload,
        },
      };
    /// 设置 昵称 值
    case 'setNickname':
      return {
        ...state,
        nickname: {
          ...state.nickname,
          val: action.payload,
        },
      };
    /// 设置 手机 值
    case 'setPhone':
      return {
        ...state,
        phone: {
          ...state.phone,
          val: action.payload,
        },
      };
    /// 设置 邮件 值
    case 'setEmail':
      return {
        ...state,
        email: {
          ...state.email,
          val: action.payload,
        },
      };
    /// 设置 是否允许该机构查看手机邮箱 值
    case 'setIsPub':
      return {
        ...state,
        isPub: {
          ...state.isPub,
          val: action.payload,
        },
      };
    /// 设置 是否启用机构切换功能 值
    case 'setIsSwap':
      return {
        ...state,
        isSwap: {
          ...state.isSwap,
          val: action.payload,
        },
      };
    /// 设置 是否是默认机构 值
    case 'setIsDefault':
      return {
        ...state,
        isDefault: {
          ...state.isDefault,
          val: action.payload,
        },
      };
    /// 设置 加入时间 值
    case 'setJoinAt':
      return {
        ...state,
        joinAt: {
          ...state.joinAt,
          val: action.payload,
        },
      };
    /// 设置 可用 值
    case 'setUsable':
      return {
        ...state,
        usable: {
          ...state.usable,
          val: action.payload,
        },
      };
    /// 设置 状态 值
    case 'setState':
      return {
        ...state,
        state: {
          ...state.state,
          val: action.payload,
        },
      };
    /// 设置 备注 值
    case 'setMemo':
      return {
        ...state,
        memo: {
          ...state.memo,
          val: action.payload,
        },
      };
    /// 校验失败
    case 'validFail':
      return {
        ...state,
        organId: {
          ...state.organId,
          err: hasError(action.payload.organId),
          hlp: getFirstErrMsg(action.payload.organId),
        },
        organName: {
          ...state.organName,
          err: hasError(action.payload.organName),
          hlp: getFirstErrMsg(action.payload.organName),
        },
        organGenre: {
          ...state.organGenre,
          err: hasError(action.payload.organGenre),
          hlp: getFirstErrMsg(action.payload.organGenre),
        },
        userId: {
          ...state.userId,
          err: hasError(action.payload.userId),
          hlp: getFirstErrMsg(action.payload.userId),
        },
        nickname: {
          ...state.nickname,
          err: hasError(action.payload.nickname),
          hlp: getFirstErrMsg(action.payload.nickname),
        },
        phone: {
          ...state.phone,
          err: hasError(action.payload.phone),
          hlp: getFirstErrMsg(action.payload.phone),
        },
        email: {
          ...state.email,
          err: hasError(action.payload.email),
          hlp: getFirstErrMsg(action.payload.email),
        },
        isPub: {
          ...state.isPub,
          err: hasError(action.payload.isPub),
          hlp: getFirstErrMsg(action.payload.isPub),
        },
        isSwap: {
          ...state.isSwap,
          err: hasError(action.payload.isSwap),
          hlp: getFirstErrMsg(action.payload.isSwap),
        },
        isDefault: {
          ...state.isDefault,
          err: hasError(action.payload.isDefault),
          hlp: getFirstErrMsg(action.payload.isDefault),
        },
        joinAt: {
          ...state.joinAt,
          err: hasError(action.payload.joinAt),
          hlp: getFirstErrMsg(action.payload.joinAt),
        },
        usable: {
          ...state.usable,
          err: hasError(action.payload.usable),
          hlp: getFirstErrMsg(action.payload.usable),
        },
        state: {
          ...state.state,
          err: hasError(action.payload.state),
          hlp: getFirstErrMsg(action.payload.state),
        },
        memo: {
          ...state.memo,
          err: hasError(action.payload.memo),
          hlp: getFirstErrMsg(action.payload.memo),
        },
      };
    /// 清空错误提示
    case 'clearError':
      return {
        ...state,
        organId: initField(state.organId.val),
        organName: initField(state.organName.val),
        organGenre: initField(state.organGenre.val),
        userId: initField(state.userId.val),
        nickname: initField(state.nickname.val),
        phone: initField(state.phone.val),
        email: initField(state.email.val),
        isPub: initField(state.isPub.val),
        isSwap: initField(state.isSwap.val),
        isDefault: initField(state.isDefault.val),
        joinAt: initField(state.joinAt.val),
        usable: initField(state.usable.val),
        state: initField(state.state.val),
        memo: initField(state.memo.val),
      };
    default:
      return state;
  }
}
