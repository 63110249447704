import AsyncValidator from 'async-validator';
import * as validators from 'vts/utils/validators';

const validator = new AsyncValidator({
  customerId: [validators.gt(0)],
  salesAt: [validators.required()],
  amountIn: [validators.required()],
  amountOut: [validators.required()],
  status: [validators.gt(0)],
});

export default validator;
