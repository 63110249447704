import Linear, { initLinear } from 'vts/models/core/Linear';

/// 考试
export default interface Exam extends Linear {
  /// 名称
  name: string;

  /// 考试时间
  examAt: string;
}

export const newExam = {
  ...initLinear,
  name: '',
  examAt: '',
};
