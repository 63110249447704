import React, {
  Fragment,
  useReducer,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Breadcrumbs,
  Link,
  Divider,
  TextField,
  Grid,
  Tooltip,
  IconButton,
  FormControl,
} from '@mui/material';
import { Refresh, SwapHoriz } from '@mui/icons-material';
import axios, { AxiosResponse } from 'vts/utils/axios';
import Auth from 'vts/models/core/Auth';
import Nil from 'vts/components/table/Nil';
import Snack from 'vts/components/snacks/Snack';
import OpenSnack from 'vts/interfaces/snack';
import { dictpvt } from 'vts/apis/core/cache';
import { abbr } from 'vts/utils/strings';
import { resetAuth, setDictPvt } from 'vts/utils/globals';
import initState from './sys/states/organ';
import reducer from './sys/reducers/organ';
import { find } from './sys/apis/organ';
import Nav from './Nav';

export default function OrganScreen() {
  const navigate = useNavigate();

  const [_state, dispatch] = useReducer(reducer, initState);

  // 查询条件
  const [nickname, setNickname] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // 需要注意的是如果 ref 为元素，那么初始值得写个 null 才不会报错
  const snackRef = useRef<OpenSnack>(null);

  const refresh = useCallback(() => {
    find({
      nickname: nickname,
      page: page,
      perPage: perPage,
    }).then(function (resp) {
      dispatch({ type: 'found', payload: resp.data });
    });
  }, [nickname, page, perPage]);

  useEffect(() => refresh(), [refresh]);

  const submit = (id: number) => {
    axios
      .post<any, AxiosResponse<Auth>>('/auth/swap', {
        id: id,
      })
      .then(function (resp) {
        if (resp.data.success) {
          // 获取当前机构自己的字典缓存
          dictpvt({}).then(function (r) {
            if (r.data.success) {
              setDictPvt(r.data);
            }
          });
          // 设置全局登录信息
          resetAuth(resp.data);
          navigate('/');
        } else {
          snackRef.current?.open();
          console.log(resp.data.head);
        }
      });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: 'setPage', payload: newPage + 1 });
    setPage(newPage + 1);
  };

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseInt(event.target.value, 10);
    dispatch({ type: 'setPerPage', payload: value });
    setPerPage(value);
  };

  return (
    <Fragment>
      <Nav />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
        px={2}
      >
        <Breadcrumbs>
          <Link
            color="inherit"
            underline="none"
            href="#"
            onClick={() => navigate('/')}
          >
            首页
          </Link>
          <Typography color="textPrimary">机构切换</Typography>
        </Breadcrumbs>
        <Grid>
          <Tooltip title="刷新">
            <IconButton onClick={refresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <FormControl fullWidth={true} sx={{ mx: 2, my: 1, flexDirection: 'row' }}>
        <TextField
          variant="outlined"
          size="small"
          label="名称"
          onChange={(e) => {
            setPage(1);
            setNickname(e.target.value);
          }}
        />
      </FormControl>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>序号</TableCell>
              <TableCell>昵称</TableCell>
              <TableCell>类型</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_state.page.items?.map((row) => (
              <TableRow key={row.id}>
                <Tooltip title={row.id}>
                  <TableCell>{abbr(row.id.toString(), 3)}</TableCell>
                </Tooltip>
                <TableCell>{row.nickname}</TableCell>
                <TableCell>{row.genre}</TableCell>
                <TableCell>
                  <Tooltip title="切换">
                    <IconButton size="small" onClick={() => submit(row.id)}>
                      <SwapHoriz />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Nil colSpan={3} rowTotal={_state.page.total} />
        </Table>
        <TablePagination
          component="div"
          count={_state.page.total}
          page={_state.page.page - 1}
          onPageChange={onPageChange}
          rowsPerPage={_state.page.perPage}
          onRowsPerPageChange={onPerPageChange}
          sx={{ mx: 2 }}
        />
      </TableContainer>
      <Snack ref={snackRef} content="切换失败" />
    </Fragment>
  );
}
