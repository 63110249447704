import Post from '../../sys/models/Post';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 岗位 状态
export interface State extends Linear {
  /// 名称
  name: FormField<string | null>;

  /// 管理员
  isAdmin: FormField<boolean | null>;

  /// 类型
  genre: FormField<number | null>;

  /// 分类
  classify: FormField<number | null>;

  /// 排序
  ordinal: FormField<number | null>;

  /// 分页
  page: Page<Post>;
}

/// 岗位 初始状态
const initState: State = {
  ...initLinear,
  name: initField(''),
  isAdmin: initField(false),
  genre: initField(0),
  classify: initField(0),
  ordinal: initField(0),
  page: initPage<Post>(),
};

export default initState;
