import Tree, { initTree } from 'vts/models/core/Tree';

export const GENRE_SYS = 10; // 系统
export const GENRE_MENU = 20; // 菜单
export const GENRE_ACTION = 30; // 操作

/// 菜单
export default interface Menu extends Tree {
  /// 管理员
  isAdmin: boolean;

  /// 菜单类型
  genre: number;
}

export const newMenu = {
  ...initTree,
  isAdmin: false,
  genre: 0,
};
