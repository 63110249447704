import Recharge from '../../mcb/models/Recharge';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 充值 状态
export interface State extends Linear {
  /// 用户
  userId: FormField<number | null>;

  /// 账户
  accountId: FormField<number | null>;

  /// 账户余额
  balance: FormField<number | null>;

  /// 余额单位
  unit: FormField<string | null>;

  /// 金额
  amount: FormField<number | null>;

  /// 活动
  activityId: FormField<number | null>;

  /// 分页
  page: Page<Recharge>;
}

/// 充值 初始状态
const initState: State = {
  ...initLinear,
  userId: initField(0),
  accountId: initField(0),
  balance: initField(0),
  unit: initField(''),
  amount: initField(0),
  activityId: initField(0),
  page: initPage<Recharge>(),
};

export default initState;
