import Linear, { initLinear } from 'vts/models/core/Linear';

/// 参与的活动
export default interface Partake extends Linear {
  /// 用户
  userId: number;

  /// 活动
  activityId: number;
}

export const newPartake = {
  ...initLinear,
  userId: 0,
  activityId: 0,
};
