import React, {
  Fragment,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  Breadcrumbs,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import LinkRouter from 'vts/components/link/router';
import Nil from 'vts/components/table/Nil';
import Nav from '../../Nav';
import initState from '../../sys/states/user';
import reducer from '../../sys/reducers/user';
import { find } from '../../sys/apis/user';

export default function UserScreen() {
  const [_state, dispatch] = useReducer(reducer, initState);

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const refresh = () => {
    find({
      name: name,
      username: username,
      platform: 50,
      isLogin: true,
      page: page,
      perPage: perPage,
    }).then(function (resp) {
      dispatch({ type: 'found', payload: resp.data });
    });
  };

  useEffect(refresh, [name, username, page, perPage]);

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: 'setPage', payload: newPage + 1 });
    setPage(newPage + 1);
  };

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseInt(event.target.value, 10);
    dispatch({ type: 'setPerPage', payload: value });
    setPerPage(value);
  };

  return (
    <Fragment>
      <Nav />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
        px={2}
      >
        <Breadcrumbs>
          <LinkRouter to="/">首页</LinkRouter>
          <Typography color="textPrimary">首次登录用户</Typography>
        </Breadcrumbs>
        <Grid>
          <Tooltip title="刷新">
            <IconButton onClick={refresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <FormControl
        fullWidth={true}
        sx={{ mx: 1.5, my: 0.5, flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <TextField
          variant="outlined"
          size="small"
          label="姓名"
          onChange={(e) => {
            setPage(1);
            setName(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="手机"
          onChange={(e) => {
            setPage(1);
            setUsername(e.target.value);
          }}
          sx={{ m: 0.5 }}
        />
      </FormControl>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={100}>序号</TableCell>
              <TableCell>姓名</TableCell>
              <TableCell>手机</TableCell>
              <TableCell>首次登录时间</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_state.page.items?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.loginAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Nil colSpan={7} rowTotal={_state.page.total} />
        </Table>
        <TablePagination
          component="div"
          count={_state.page.total}
          page={_state.page.page - 1}
          onPageChange={onPageChange}
          rowsPerPage={_state.page.perPage}
          onRowsPerPageChange={onPerPageChange}
          sx={{ mx: 2 }}
        />
      </TableContainer>
    </Fragment>
  );
}
