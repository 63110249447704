import Flw from '../../cmn/models/Flw';
import Page, { initPage } from 'vts/models/core/Page';
import FormField, { initField } from 'vts/models/core/FormField';
import Linear, { initLinear } from 'vts/states/core/linear';

/// 流程 状态
export interface State extends Linear {
  /// 来源ID
  srcId: FormField<number | null>;

  /// 之前状态
  preStatus: FormField<number | null>;

  /// 之后状态
  postStatus: FormField<number | null>;

  /// 分页
  page: Page<Flw>;
}

/// 流程 初始状态
const initState: State = {
  ...initLinear,
  srcId: initField(0),
  preStatus: initField(0),
  postStatus: initField(0),
  page: initPage<Flw>(),
};

export default initState;
